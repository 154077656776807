import { COMPANY_TECH } from '../../constants/CopilotInAppActionTypes';
export const companyTech = action => {
  return {
    data: {
      intent: COMPANY_TECH,
      domain: action.domain,
      company_name: action.company_name
    },
    text: `Show me the tech stack for ${action.company_name} (${action.domain})`
  };
};