import http from 'hub-http/clients/apiClient';
export const SINGULAR_URL = 'inbounddb-objects/v1/crm-objects';
export const writeMixedCrmObjectPropertiesByObjectIds = ({
  objectTypeId,
  objectUpdates
}) => http.put(`${SINGULAR_URL}/${objectTypeId}`, {
  data: objectUpdates,
  headers: {
    'x-properties-source': 'CRM_UI'
  }
});