import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useMemo } from 'react';
import { useHydratedSearchQuery } from '../../searchQuery/hooks/useHydratedSearchQuery';
import { useCurrentPipeline } from '../../pipelines/hooks/useCurrentPipeline';
export const MAXIMUM_POSSIBLE_PORTAL_CURRENCIES = 200;
const addStageIdsFilter = ({
  group: {
    filters
  },
  pipelineStagePropertyName,
  stages
}) => ({
  filters: [...filters, {
    property: pipelineStagePropertyName,
    operator: 'IN',
    values: stages.map(({
      stageId
    }) => stageId)
  }]
});
const generateAggregationQueryWithAggregators = ({
  query: {
    query,
    filterGroups
  },
  objectTypeId,
  pipelineStagePropertyName,
  stages,
  aggregators
}) => ({
  objectTypeId,
  // This ensures that we exclude orphaned deals from our aggregations. The request will
  // stop at "size" buckets, so if we didn't do this orphaned deals from deleted stages
  // can take up a slot and prevent us from getting the data we need.
  filterGroups: filterGroups.map(group => addStageIdsFilter({
    group,
    pipelineStagePropertyName,
    stages
  })),
  query,
  aggregations: aggregators.query({
    property: pipelineStagePropertyName,
    type: 'TERMS',
    // This is the number of "buckets" that the request returns. We need as many
    // buckets as the pipeline has stages.
    size: stages.length
  })
});
export const useAggregationQuery = aggregators => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'objectTypeId' does not exist on type 'un... Remove this comment to see the full error message
  const {
    objectTypeId,
    pipelineStagePropertyName
  } = useSelectedObjectTypeDef();
  const {
    stages
  } = useCurrentPipeline();
  const {
    status,
    query
  } = useHydratedSearchQuery();
  return useMemo(() => {
    let createdQuery = null;
    if (query && aggregators) {
      createdQuery = generateAggregationQueryWithAggregators({
        // @ts-expect-error todo
        query,
        objectTypeId,
        pipelineStagePropertyName,
        stages,
        aggregators
      });
    }
    return {
      status,
      query: createdQuery
    };
  }, [status, query, objectTypeId, pipelineStagePropertyName, stages, aggregators]);
};