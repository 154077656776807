import { CART_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, INVOICE_TYPE_ID, MEETING_EVENT_TYPE_ID, ORDER_TYPE_ID, PRODUCT_TYPE_ID, SUBSCRIPTION_TYPE_ID, EMAIL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useHasAllGates } from '../../../auth/useHasAllGates';
export const useObjectNavigationCapabilities = ({
  objectTypeId
}) => {
  const hasGate = useHasAllGates();
  const objectTypesWithoutLink = [COMMERCE_PAYMENT_TYPE_ID, INVOICE_TYPE_ID, SUBSCRIPTION_TYPE_ID, PRODUCT_TYPE_ID, MEETING_EVENT_TYPE_ID, ...(!hasGate('Shopify:OrderAndCartEnhacements') ? [ORDER_TYPE_ID, CART_TYPE_ID] : [])];
  const hasLink = !objectTypesWithoutLink.includes(objectTypeId);
  const objectTypesDisablingPreviewButton = [EMAIL_TYPE_ID];
  const enablesPreviewButton = !objectTypesDisablingPreviewButton.includes(objectTypeId);
  const hasPreviewButton = enablesPreviewButton && hasLink;

  /**
   * 1) If the object has both a link, and preview panel support,
   *    then render both a link on the label cell and the preview button on hover,
   *    opens link href on click of label cell and preview panel on click of preview button.
   * 2) If the object has a link but no preview panel support,
   *    then don't render the preview button on hover,
   *    opens link href on click of label cell.
   * 3) If the object has preview panel support but no link,
   *    then open the preview panel on click of the label cell.
   */
  return {
    hasLink,
    hasPreviewButton
  };
};