import { useCallback, useEffect } from 'react';

// based on https://git.hubteam.com/HubSpot/VictoriaMetrics/blob/master/app/vmui/packages/vmui/src/hooks/useClickOutside.ts
const useClickOutside = (ref, handler, preventRef) => {
  const listener = useCallback(event => {
    var _preventRef$current;
    const el = ref.current;
    const target = event.target;
    const isPreventRef = !!(preventRef !== null && preventRef !== void 0 && (_preventRef$current = preventRef.current) !== null && _preventRef$current !== void 0 && _preventRef$current.contains(target));
    if (!el || el.contains(target) || isPreventRef) {
      return;
    }
    handler(event); // Call the handler only if the click is outside of the element passed.
  }, [ref, preventRef, handler]);
  useEffect(() => {
    window.addEventListener('mousedown', listener);
    return () => {
      window.removeEventListener('mousedown', listener);
    };
  }, [listener]);
};
export default useClickOutside;