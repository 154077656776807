import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchPortalCurrencies } from '../api/multicurrencyApi';
import { useMemo } from 'react';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
export const portalCurrenciesFieldName = withCrmObjectBoardNamespace('portalCurrencyData');
export function formatCurrencyData({
  currencyExchangeRates,
  homeCurrency,
  hasMulticurrencyActive
}) {
  const exchangeRates = currencyExchangeRates.filter(exchangeRate => exchangeRate.visible).reduce((acc, exchangeRate) => Object.assign({}, acc, {
    [exchangeRate.fromCurrencyCode]: exchangeRate.conversionMultiplier
  }), {});
  return {
    exchangeRates,
    portalCurrencyCodes: Object.keys(exchangeRates),
    homeCurrencyCode: homeCurrency.currencyCode,
    hasMulticurrencyActive
  };
}
export const fetchCurrencies = registerQuery({
  fieldName: portalCurrenciesFieldName,
  fetcher: () => fetchPortalCurrencies().then(formatCurrencyData)
});
export function useFetchPortalCurrencies() {
  const {
    data,
    loading
  } = useQuery(fetchCurrencies);
  return useMemo(() => {
    if (!data || !data[portalCurrenciesFieldName]) {
      return {
        portalCurrencyCodes: [],
        homeCurrencyCode: undefined,
        loading
      };
    }
    const {
      portalCurrencyCodes,
      homeCurrencyCode
    } = data[portalCurrenciesFieldName];
    return {
      portalCurrencyCodes,
      homeCurrencyCode,
      loading
    };
  }, [data, loading]);
}
export function useHasMulticurrencyActive(overrideQueryOptions) {
  const {
    data
  } = useQuery(fetchCurrencies, Object.assign({}, overrideQueryOptions));
  return Boolean(data && data[portalCurrenciesFieldName].hasMulticurrencyActive);
}