import { useCallback } from 'react';
import { useProps } from '../props/useProps';
import { updateObject } from './api/crmObjectsApi';
export const useUpdateCrmObject = () => {
  const {
    objectTypeId
  } = useProps();
  return useCallback(({
    objectId,
    properties
  }) => {
    return updateObject({
      objectTypeId,
      objectId,
      propertyValues: Object.entries(properties).map(([key, value]) => {
        return {
          name: key,
          value: value
        };
      })
    });
  }, [objectTypeId]);
};