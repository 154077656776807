'use es6';

/* eslint-disable hubspot-dev/no-private-classes */
export default {
  ADS_LIMIT_INCREASE: 'ads-limit-increase',
  ADVANCED_ONBOARDING: 'advanced-onboarding',
  ADVANCED_ONBOARDING_MIGRATION_SERVICES: 'advanced-onboarding-migration-services',
  AGENCY_PIPELINE_GENERATION_TRAINING_PLUS: 'agency-pipeline-generation-training-plus',
  AGENCY_SALES_SKILLS_TRAINING_PLUS: 'agency-sales-skills-training-plus',
  API_LIMIT_INCREASE: 'api-limit-increase',
  ASSET_MIGRATION_BASE: 'asset-migration-base',
  ASSET_MIGRATION_PREMIUM: 'asset-migration-premium',
  BASIC_DESIGNATED_ACCOUNT_SERVICES: 'basic-designated-account-services',
  BLOG_MIGRATION: 'blog-migration',
  BRAND_DOMAIN: 'brand-domain',
  BRAZIL_ONBOARDING: 'brazil-onboarding',
  BREEZE_INTELLIGENCE_BACKFILL_ENRICHMENT: 'breeze-intelligence-backfill-enrichment',
  BREEZE_INTELLIGENCE_BULK_ENRICHMENT: 'breeze-intelligence-bulk-enrichment',
  BREEZE_INTELLIGENCE_CREDITS_100: 'breeze-intelligence-credits-100',
  BREEZE_INTELLIGENCE_CREDITS_1000: 'breeze-intelligence-credits-1000',
  BREEZE_INTELLIGENCE_CREDITS_10000: 'breeze-intelligence-credits-10000',
  BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED: 'breeze-intelligence-credits-100-committed',
  BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED: 'breeze-intelligence-credits-1000-committed',
  BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED: 'breeze-intelligence-credits-10000-committed',
  BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_100: 'breeze-intelligence-overage-credits-for-100',
  BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_1000: 'breeze-intelligence-overage-credits-for-1000',
  BREEZE_INTELLIGENCE_OVERAGE_CREDITS_FOR_10000: 'breeze-intelligence-overage-credits-for-10000',
  BREEZE_INTELLIGENCE_TOKENS_100: 'breeze-intelligence-credits-100',
  BREEZE_INTELLIGENCE_TOKENS_1000: 'breeze-intelligence-credits-1000',
  BREEZE_INTELLIGENCE_TOKENS_10000: 'breeze-intelligence-credits-10000',
  BUSINESS_UNIT_EXTENSION: 'business-unit-extension',
  CALCULATED_PROPERTIES_LIMIT_INCREASE: 'calculated-properties-limit-increase',
  CALLING_LIMIT_INCREASE: 'calling-limit-increase',
  CMS_HUB_ENTERPRISE: 'cms-hub-enterprise',
  CMS_HUB_ENTERPRISE_ONBOARDING: 'cms-hub-enterprise-onboarding',
  CMS_HUB_FREE: 'cms-hub-free',
  CMS_HUB_PROFESSIONAL: 'cms-hub-professional',
  CMS_HUB_PROFESSIONAL_ONBOARDING: 'cms-hub-professional-onboarding',
  CMS_HUB_STARTER: 'cms-hub-starter',
  CONSUMPTION_REV_REC_PRODUCT: 'consumption-rev-rec-product',
  CORE_SEATS_ENTERPRISE: 'core-seats-enterprise',
  CORE_SEATS_INCLUDED_STARTER: 'core-seats-included-starter',
  CORE_SEATS_PROFESSIONAL: 'core-seats-professional',
  CORE_SEATS_STARTER: 'core-seats-starter',
  CREATING_EFFECTIVE_INBOUND_CONTENT_TRAINING: 'creating-effective-inbound-content-training',
  CRM_DATA_MIGRATION: 'crm-data-migration',
  CRM_OBJECT_LIMIT_INCREASE: 'crm-object-limit-increase',
  CRM_SUITE_ENTERPRISE_BUNDLE: 'crm-suite-enterprise-bundle',
  CRM_SUITE_PROFESSIONAL_BUNDLE: 'crm-suite-professional-bundle',
  CRM_SUITE_ONBOARDING_ENTERPRISE: 'crm-suite-onboarding-enterprise',
  CRM_SUITE_ONBOARDING_PREMIER: 'crm-suite-onboarding-premier',
  CRM_SUITE_ONBOARDING_PROFESSIONAL: 'crm-suite-onboarding-professional',
  CRM_SUITE_STARTER_BUNDLE: 'crm-suite-starter-bundle',
  CRM_TRAINING: 'crm-training',
  CUSTOM_CLASSROOM_TRAINING: 'custom-classroom-training',
  CUSTOM_CONSULTING: 'custom-consulting',
  CUSTOM_OBJECTS_LIMIT_INCREASE: 'custom-objects-limit-increase',
  CUSTOM_PROPERTIES_LIMIT_INCREASE: 'custom-properties-limit-increase',
  DATA_MIGRATION_CONSULTING: 'data-migration-consulting',
  DOMAINS_LIMIT_INCREASE: 'domains-limit-increase',
  EMAIL_TEMPLATE_SETUP: 'email-template-setup',
  ENTERPRISE_EDUCATION_PROGRAM: 'enterprise-education-program',
  ENTERPRISE_SUITE_ONBOARDING: 'enterprise-suite-onboarding',
  ESIGNATURE_LIMIT_INCREASE: 'esignature-limit-increase',
  EXECUTIVE_SUMMIT_HOW_HUBSPOT_BUILT_ITS_SALES_AND_MARKETING_MACHINE: 'executive-summit-how-hubspot-built-its-sales-and-marketing-machine',
  FIRST_TIME_BLOG_MIGRATION: 'first-time-blog-migration',
  FIRST_TIME_FULL_MIGRATION: 'first-time-full-migration',
  FRIENDS_AND_FAMILY: 'friends-and-family',
  FULL_MIGRATION: 'full-migration',
  GOLD_CRM_DATA_MIGRATION: 'gold-crm-data-migration',
  GRANTED_CORE_SEATS: 'granted-core-seats',
  HUBSPOT_ACADEMY_GUIDED_ONBOARDING: 'HubSpot-Academy-Guided-Onboarding',
  HUBSPOT_CRM_DATA_MIGRATION: 'hubspot-crm-data-migration',
  HUBSPOT_FOR_STARTUPS_SETUP: 'hubspot-for-startups-setup',
  HUBSPOT_FREE: 'hubspot-free',
  HUBSPOT_IMPLEMENTATION_CONSULTING: 'hubspot-implementation-consulting',
  IMPLEMENTATION_FEE_20K: '20k-implementation-fee',
  INBOUND_CONSULTING_ADVANCED: 'inbound-consulting-advanced',
  INBOUND_CONSULTING_NEW_USER_TRAINING: 'inbound-consulting-new-user-training',
  INBOUND_CONSULTING_PREMIUM: 'inbound-consulting-premium',
  INBOUND_SUCCESS_CONTENT_ADVANTAGE: 'inbound-success-content-advantage',
  INBOUND_SUCCESS_NON_PROFIT_ADVANTAGE: 'inbound-success-non-profit-advantage',
  INDUSTRY_BUSINESS_CONSULTING_ONGOING: 'industry-business-consulting-ongoing',
  INDUSTRY_TECHNICAL_CONSULTING_ONGOING: 'industry-technical-consulting-ongoing',
  INTEGRATION_SUPPORT: 'integration-support',
  LARGE_SERVICES: 'large-services',
  LEAD_MANAGEMENT_AND_AUTOMATION_TRAINING: 'lead-management-and-automation-training',
  LEGACY_MARKETING: 'legacy-marketing',
  LEGACY_SALES_HUB_PROFESSIONAL_LIMITS: 'legacy-sales-hub-professional-limits',
  LISTS_LIMIT_INCREASE: 'lists-limit-increase',
  MARKETING_HUB_ADVANCED_ONBOARDING: 'marketing-hub-advanced-onboarding',
  MARKETING_HUB_ATTRACT_LEADS_TRAINING: 'marketing-hub-attract-leads-training',
  MARKETING_HUB_BASIC: 'marketing-hub-basic',
  MARKETING_HUB_BASIC_ONBOARDING: 'marketing-hub-basic-onboarding',
  MARKETING_HUB_ENTERPRISE: 'marketing-hub-enterprise',
  MARKETING_HUB_ENTERPRISE_LEGACY_FEATURES: 'marketing-hub-enterprise-legacy-features',
  MARKETING_HUB_ENTERPRISE_ONBOARDING: 'marketing-hub-enterprise-onboarding',
  MARKETING_HUB_ENTERPRISE_ONBOARDING_LATAM: 'marketing-hub-enterprise-onboarding-latam',
  MARKETING_HUB_FREE: 'marketing-hub-free',
  MARKETING_HUB_GENERATE_LEADS_TRAINING: 'marketing-hub-generate-leads-training',
  MARKETING_HUB_HANDOFF_LEADS_TRAINING: 'marketing-hub-handoff-leads-training',
  MARKETING_HUB_NURTURE_LEADS_TRAINING: 'marketing-hub-nurture-leads-training',
  MARKETING_HUB_PROFESSIONAL: 'marketing-hub-professional',
  MARKETING_HUB_PROFESSIONAL_ONBOARDING: 'marketing-hub-professional-onboarding',
  MARKETING_HUB_PROFESSIONAL_ONBOARDING_LATAM: 'marketing-hub-professional-onboarding-latam',
  MARKETING_HUB_STARTER: 'marketing-hub-starter',
  MARKETING_HUB_STARTER_ONBOARDING: 'marketing-hub-starter-onboarding',
  MARKETING_PLUS_SUITE_BUNDLE_PROFESSIONAL: 'marketing-plus-professional-bundle',
  MARKETING_PLUS_SUITE_BUNDLE_ENTERPRISE: 'marketing-plus-enterprise-bundle',
  MARKETING_SMS: 'marketing-sms',
  MARKETING_SMS_LIMIT_INCREASE: 'marketing-sms-limit-increase',
  MARKETING_SMS_SHORT_CODE: 'marketing-sms-short-code',
  MIGRATE_THEME: 'migrate-theme',
  MIGRATE_THEME_CONTENT_MIGRATION: 'migrate-theme-content-migration',
  MIGRATION_CONSULTING_4_HOUR: 'migration-consulting-4-hour',
  MULTIPLE_PREMIERE_ONBOARDING: 'multiple-premier-onboarding',
  NON_MARKETING_CONTACTS_LIMIT_INCREASE: 'non-marketing-contacts-limit-increase',
  ONBOARDING_EXTENSION_4_HOUR: 'onboarding-extension-4-hour',
  ONBOARDING_FOUNDATIONS: 'onboarding-foundations',
  ONE_TIME_CREDIT: 'one-time-credit',
  ONSITE_ONBOARDING: 'onsite-onboarding',
  OPERATIONS_HUB_ENTERPRISE: 'operations-hub-enterprise',
  OPERATIONS_HUB_PROFESSIONAL: 'operations-hub-professional',
  OPERATIONS_HUB_STARTER: 'operations-hub-starter',
  PARTNER_20K_IMPLEMENTATION_FEE: 'partner-20k-implementation-fee',
  PARTNER_ONBOARDING: 'partner-onboarding',
  PARTNER_ONBOARDING_FOUNDATIONS: 'partner-onboarding-foundations',
  PARTNER_PIPELINE_GENERATION_BOOTCAMP_PRO_TRAINING: 'partner-pipeline-generation-bootcamp-pro-training',
  PARTNER_PRIVATE_CUSTOMER_TRAINING_BASE: 'partner-private-customer-training-base',
  PARTNER_PRIVATE_CUSTOMER_TRAINING_BASE_ONSITE: 'partner-private-customer-training-base-onsite',
  PARTNER_PRIVATE_TRAINING_PREMIUM: 'partner-private-training-premium',
  PARTNER_SALES_SKILLS_BOOTCAMP_PRO_TRAINING: 'partner-sales-skills-bootcamp-pro-training',
  PARTNER_TECHNICAL_ACCELERATOR: 'partner-technical-accelerator',
  PAST_DUE_AMOUNT: 'past-due-amount',
  PHONE_LIMIT_INCREASE: 'phone-limit-increase',
  PREMIUM_CONSULTING: 'premium-consulting',
  PREMIUM_SUPPORT: 'premium-support',
  PRIVATE_CUSTOMER_TRAINING_BASE: 'private-customer-training-base',
  PRIVATE_CUSTOMER_TRAINING_BASE_ONSITE: 'private-customer-training-base-onsite',
  PRIVATE_TRAINING_BASE: 'private-training-base',
  PRIVATE_TRAINING_PREMIUM: 'private-training-premium',
  PROFESSIONAL_SUITE_ONBOARDING: 'professional-suite-onboarding',
  QUICK_START_CONSULTATION: 'quick-start-consultation',
  REFUND: 'refund',
  REMOTE_TEAM_TRAINING: 'remote-team-training',
  REOPEN_PORTAL: 'reopen-portal',
  REPORTING_ADD_ON_TRAINING: 'reporting-add-on-training',
  REPORTING_LIMIT_INCREASE: 'reporting-limit-increase',
  SALES_AND_MARKETING_ALIGNMENT: 'sales-and-marketing-alignment',
  SALES_HUB_ADVANCED_ONBOARDING: 'sales-hub-advanced-onboarding',
  SALES_HUB_AUTOMATION_TRAINING: 'sales-hub-automation-training',
  SALES_HUB_CONFIGURE_YOUR_DEAL_PIPELINE_TRAINING: 'sales-hub-configure-your-deal-pipeline-training',
  SALES_HUB_ENTERPRISE: 'sales-hub-enterprise',
  SALES_HUB_ENTERPRISE_ONBOARDING: 'sales-hub-enterprise-onboarding',
  SALES_HUB_PRODUCTIVITY_TOOLS_TRAINING: 'sales-hub-productivity-tools-training',
  SALES_HUB_PROFESSIONAL: 'sales-hub-professional',
  SALES_HUB_PROFESSIONAL_ONBOARDING: 'sales-hub-professional-onboarding',
  SALES_HUB_REPORTING_TRAINING: 'sales-hub-reporting-training',
  SALES_HUB_STARTER: 'sales-hub-starter',
  SALES_HUB_STARTER_ONBOARDING: 'sales-hub-starter-onboarding',
  SALES_SETUP: 'sales-setup',
  SANDBOX_LIMIT_INCREASE: 'sandbox-limit-increase',
  SERVICE_AVISON_YOUNG_IMPLEMENTATION: 'service-avison-young-implementation',
  SERVICE_BLOCK_4_HOUR: 'service-block-4-hour',
  SERVICE_BLOCK_8_HOUR: 'service-block-8-hour',
  SERVICE_HUB_ENTERPRISE: 'service-hub-enterprise',
  SERVICE_HUB_ENTERPRISE_ONBOARDING: 'service-hub-enterprise-onboarding',
  SERVICE_HUB_FREE: 'service-hub-free',
  SERVICE_HUB_FUNDAMENTALS: 'service-hub-fundamentals',
  SERVICE_HUB_PROFESSIONAL: 'service-hub-professional',
  SERVICE_HUB_PROFESSIONAL_ONBOARDING: 'service-hub-professional-onboarding',
  SERVICE_HUB_STARTER: 'service-hub-starter',
  SERVICE_HUB_STARTER_ONBOARDING: 'service-hub-starter-onboarding',
  SERVICE_SMS: 'service-sms',
  SILVER_CRM_DATA_MIGRATION: 'silver-crm-data-migration',
  STARTER_KIT: 'starter-kit',
  STRATEGIC_CONSULTING_1_DAY: 'strategic-consulting-1-day',
  STRATEGIC_CONSULTING_4_HOUR: 'strategic-consulting-4-hour',
  STRATEGIC_CONSULTING_MONTHLY: 'strategic-consulting-monthly',
  STRATEGIC_CONSULTING_ONGOING: 'strategic-consulting-ongoing',
  TEAMS_LIMIT_INCREASE: 'teams-limit-increase',
  TECH_SERVICE_DEDICATED_IP_FOR_EMAIL: 'tech-service-dedicated-ip-for-email',
  TECH_SERVICE_FIRST_TIME_TEMPLATE_SETUP: 'tech-service-first-time-template-setup',
  TECH_SERVICE_TEMPLATE_SETUP: 'tech-service-template-setup',
  TECHNICAL_CONSULTING_1_DAY: 'technical-consulting-1-day',
  TECHNICAL_CONSULTING_4_HOUR: 'technical-consulting-4-hour',
  TECHNICAL_CONSULTING_ADVANCED: 'technical-consulting-advanced',
  TECHNICAL_CONSULTING_ONGOING: 'technical-consulting-ongoing',
  TECHNICAL_CONSULTING_OPERATIONS_HUB_DATASYNC: 'technical-consulting-operations-hub-datasync',
  TECHNICAL_CONSULTING_OPERATIONS_HUB_PROGRAMMABLE_AUTOMATION: 'technical-consulting-operations-hub-programmable-automation',
  TECHNICAL_CONSULTING_PARTNERS: 'technical-consulting-partners',
  TECHNICAL_CONSULTING_PREMIUM: 'technical-consulting-premium',
  TRAINING_AGENCY_ACCOUNT_MANAGEMENT_ESSENTIALS: 'training-agency-account-management-essentials',
  TRAINING_AGENCY_CRM_MANAGEMENT: 'training-agency-crm-management',
  TRAINING_AGENCY_MARKETING_HUB_FUNDAMENTALS: 'training-agency-marketing-hub-fundamentals',
  TRAINING_BUILDING_AN_INBOUND_CAMPAIGN_FUNDAMENTALS: 'training-building-an-inbound-campaign-fundamentals',
  TRAINING_OPTIMIZING_YOUR_SALES_WITH_HUBSPOT_CRM_TRAINING_COURSE: 'training-optimizing-your-sales-with-hubspot-crm-training-course',
  TRAINING_SALES_AND_MARKETING_ALIGNMENT: 'training-sales-and-marketing-alignment',
  TRAINING_SALES_HUB_FUNDAMENTALS: 'training-sales-hub-fundamentals',
  TRANSACTIONAL_EMAIL: 'transactional-email',
  TRANSCRIPTION_LIMIT_INCREASE: 'transcription-limit-increase',
  UNLIMITED_CONTACTS: 'unlimited-contacts',
  USERS_LIMIT_INCREASE: 'users-limit-increase',
  VIDEO_LIMIT_INCREASE: 'video-limit-increase',
  WEB_HOSTING_CUSTOM_SSL_PACK: 'web-hosting-custom-ssl-pack',
  WEB_HOSTING_SSL_PACK: 'web-hosting-ssl-pack',
  WHATSAPP_LIMIT_INCREASE: 'whatsapp-limit-increase',
  WORKFLOWS_LIMIT_INCREASE: 'workflows-limit-increase'
};