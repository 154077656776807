import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback } from 'react';
import Raven from 'raven-js';
import { useHandler } from '../../handlers/useHandler';
import { useLogger } from '../../props/useLogger';
export const useHandleCardClick = () => {
  const sendPreviewSidebarMessage = useSendCrmMessageTopic(TOPIC_NAMES.PREVIEW_OBJECT);
  const logger = useLogger();
  const handleCardClick = useCallback(options => {
    const cardData = options.cardData;
    const objectId = cardData.object.objectId;
    const objectTypeId = cardData.objectTypeDefinition.objectTypeId;
    if (objectId === undefined) {
      Raven.captureMessage('Found undefined objectId in card click handler');
      return;
    }
    logger.log({
      key: 'previewSideBarTriggered',
      metadata: {
        objectId: objectId.toString(),
        objectTypeId
      }
    });
    sendPreviewSidebarMessage({
      objectId: String(objectId),
      objectTypeId
    });
  }, [sendPreviewSidebarMessage, logger]);
  useHandler('onCardClick', handleCardClick);
};