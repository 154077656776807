import { useCallback, useEffect, useRef } from 'react';
import emptyFunction from 'react-utils/emptyFunction';
import debounce from 'transmute/debounce';

/**
 * useElementResized is a custom hook meant to help solve the problem of
 * "I want to know when this component resized, and when it does, do something"
 *
 * It is a react hook that takes a `callback` that will be called with the dom node of `componentRef` whenever it is resized
 *
 * @param componentRef - a React.ref object that is placed on the react element in which you care to watch
 *
 * @param callback - a function you pass in to this hook that is called with the current value of the reactRef every time ResizeObserver observes a resize
 * */
export const useElementResized = ({
  callback = emptyFunction,
  componentRef,
  debounced = false,
  delay = 100
}) => {
  const callbackRef = useRef(callback);
  const handler = useCallback(() => {
    if (componentRef.current) {
      callbackRef.current(componentRef.current);
    }
  }, [componentRef]);

  // Optionally debounce the callback, to batch or limit the rate of processing.
  const resizeHandler = debounced ? debounce(delay, handler) : handler;
  useEffect(() => {
    if (componentRef.current) {
      const myObserver = new ResizeObserver(resizeHandler);
      myObserver.observe(componentRef.current);
      return () => myObserver.disconnect();
    }
    return () => {};
  }, [componentRef, resizeHandler]);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
};