import http from 'hub-http/clients/apiClient';
import { catchBoardError } from '../../error/utils';
export const SINGULAR_URL = 'inbounddb-objects/v1/crm-objects';
export const updateObject = ({
  objectTypeId,
  objectId,
  propertyValues
}) => {
  return catchBoardError('update object', http.put(`inbounddb-objects/v1/crm-objects/${objectTypeId}`, {
    data: [{
      objectId,
      propertyValues
    }],
    headers: {
      'x-properties-source': 'CRM_UI'
    }
  }).then(response => {
    //we only update one object so just return that
    if (response && response[0]) {
      return response[0];
    }
    return null;
  }));
};