import { getProperty } from 'customer-data-objects/objects/ObjectAccessors';
import formatName from 'I18n/utils/formatName';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const getRecordTitle = (subject, objectTypeDefinition) => {
  if (objectTypeDefinition.objectTypeId === CONTACT_TYPE_ID) {
    const firstName = getProperty(subject, 'firstname');
    const lastName = getProperty(subject, 'lastname');
    const email = getProperty(subject, 'email');
    return formatName({
      firstName,
      lastName,
      email
    });
  }
  const titlePropertyValue = getProperty(subject, objectTypeDefinition.primaryDisplayLabelPropertyName);
  return titlePropertyValue || '--';
};