import { MAXIMUM_POSSIBLE_PORTAL_CURRENCIES } from '../../hooks/useAggregationQuery';
import { generateMetric } from '../../utils/generateMetric';
import { getName } from '../../utils/getName';
import { getSubaggregationName } from '../../utils/getSubaggregationName';
export const createQuery = (config, currencyOptions) => options => {
  return Object.assign({}, options, {
    name: getName(config),
    metrics: [generateMetric(config)],
    subAggregations: [{
      name: getSubaggregationName(config, currencyOptions.currencyCodeProperty),
      property: currencyOptions.currencyCodeProperty,
      type: 'TERMS',
      size: MAXIMUM_POSSIBLE_PORTAL_CURRENCIES,
      metrics: [{
        property: config.property,
        metricTypes: [config.aggregation]
      }, {
        property: config.meta.currencyProperty,
        metricTypes: [config.aggregation]
      }]
    }]
  });
};