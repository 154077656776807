import { Metrics } from './Metrics';
// platform's type is Platform | string as some clients aren't typed properly yet

export const trackSingleEnrollSuccess = platform => {
  Metrics.counter('singleEnrollSuccess', {
    platform
  }).increment();
};
export const trackBulkEnrollSuccess = platform => {
  Metrics.counter('bulkEnrollSuccess', {
    platform
  }).increment();
};
export const trackSingleEnrollError = (platform, response) => {
  var _response$responseJSO;
  Metrics.counter('singleEnrollError', {
    platform,
    status: `${response.status}`,
    enrollErrorType: ((_response$responseJSO = response.responseJSON) === null || _response$responseJSO === void 0 ? void 0 : _response$responseJSO.errorType) || ''
  }).increment();
};
export const trackBulkEnrollError = (platform, response) => {
  var _response$responseJSO2;
  Metrics.counter('bulkEnrollError', {
    platform,
    status: `${response.status}`,
    enrollErrorType: ((_response$responseJSO2 = response.responseJSON) === null || _response$responseJSO2 === void 0 ? void 0 : _response$responseJSO2.errorType) || ''
  }).increment();
};
export const trackBulkEnrollFailuresByKey = (platform, failuresByKey) => {
  const uniqueErrorTypes = new Set();
  failuresByKey.forEach(errorForContact => {
    uniqueErrorTypes.add(errorForContact.get('errorType'));
  });
  Metrics.counter('bulkEnrollSubsetError', {
    platform,
    enrollErrorType: [...uniqueErrorTypes].sort().join(',')
  }).increment();
};