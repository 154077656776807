import { OBJECT_SUMMARY } from '../../constants/CopilotInAppActionTypes';
import { normalizeObjectType } from './utils/normalizeObjectType';
import { parseObjectId } from './utils/parseObjectId';
export const objectSummary = action => {
  return {
    data: {
      intent: OBJECT_SUMMARY,
      object_id: parseObjectId(action.objectId),
      object_type: normalizeObjectType(action.objectTypeId),
      context: action.context
    },
    text: action.objectName ? `Tell me more about the ${action.objectTypeLabel} ${action.objectName}` : action.label ? `Summarize ${action.objectTypeLabel} ${action.label}` : `Tell me about the ${action.objectTypeLabel} I selected`
  };
};