import { useMemo } from 'react';
import { useFastStore } from '../../context/FastContext';
export const useRenderComponents = () => {
  const renderComponents = useFastStore(store => store.renderComponents);
  return useMemo(() => {
    const renderComponentsMap = {};
    Object.values(renderComponents.entities).forEach(renderComponentEntity => {
      if (renderComponentEntity) {
        renderComponentsMap[renderComponentEntity.key] = renderComponentEntity.renderComponent;
      }
    });
    return renderComponentsMap;
  }, [renderComponents]);
};
export const useGetRenderComponent = key => {
  const renderComponents = useRenderComponents();
  return renderComponents[key];
};