import { TITLE_SECTION_KEY } from '../sections/title/types';
import { PROPERTIES_SECTION_KEY } from '../sections/properties/types';
export const getUserSettings = (__pipelineId, __objectTypeId) => {
  return Promise.resolve({
    data: [{
      sectionKey: TITLE_SECTION_KEY,
      isEnabled: true,
      order: 0
    }, {
      sectionKey: PROPERTIES_SECTION_KEY,
      isEnabled: true,
      order: 1,
      settings: {
        value: ['amount', 'closedate']
      }
    }]
  });
};