import { CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS } from 'crm-object-table';
import { useMemo } from 'react';
import { trackBulkAssignEvent, trackBulkDeleteEvent, trackBulkEditRecordsEvent, trackEditColumns, trackIndexInteractionsEvent, trackInlineEditAction, trackLabelCellLinkClicked, trackOpenEditColumnsModal, trackOpenPreviewSidebar, trackViewedEmptyState, trackCopilotSummarizeObject } from '../../../tracking/indexPageTracking';
import CrmLogger from '../../../loggers/crmLogger';
import { TASK } from 'customer-data-objects/constants/ObjectTypes';
import { CRM } from 'sales-modal/constants/Platform';
import { trackSingleEnrollSuccess } from 'sales-modal/lib/CustomPageActions';
import { Metrics } from '../../../lib/metrics/metrics';
import Raven from 'raven-js';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
export const useCreateCrmObjectTableLogger = () => {
  const objectTypeId = useSelectedObjectTypeId();
  return useMemo(() => ({
    log(e) {
      try {
        switch (e.key) {
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.INLINE_EDIT:
            trackInlineEditAction({
              action: e.metadata.type === 'submit' ? 'save' : 'cancel',
              objectTypeId: e.metadata.objectTypeId,
              numberOfProperties: e.metadata.numberOfProperties,
              totalRecords: e.metadata.totalRecords
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.COPILOT_ACTION_BUTTON_CLICKED:
            trackCopilotSummarizeObject();
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.LABEL_CELL_CLICKED:
            trackLabelCellLinkClicked({
              objectTypeId: e.metadata.objectTypeId,
              objectId: e.metadata.objectId
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.PREVIEW_CLICKED:
            trackOpenPreviewSidebar({
              objectId: e.metadata.objectId,
              objectTypeId: e.metadata.objectTypeId
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.EDIT_COLUMNS_BUTTON_CLICKED:
            trackOpenEditColumnsModal({
              objectTypeId: e.metadata.objectTypeId,
              name: e.metadata.objectTypeId
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.EDIT_COLUMNS_MODAL_EVENT:
            trackEditColumns({
              subscreen2: 'edit-columns-modal',
              objectTypeId,
              objectTypeName: objectTypeId,
              value: e.metadata.eventData || 'NOT_PROVIDED',
              action: e.metadata.eventName
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.VIEWED_NO_QUERY_RESULTS_EMPTY_STATE:
            trackViewedEmptyState({
              objectTypeName: e.metadata.objectTypeId,
              dueToFiltering: true
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.VIEWED_EMPTY_DATABASE_EMPTY_STATE:
            trackViewedEmptyState({
              objectTypeName: e.metadata.objectTypeId,
              dueToFiltering: false
            });
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.SEARCH_BAR_CHANGED:
            // NOOP - we track this debounced here: https://git.hubteam.com/HubSpot/crm-index-ui/blob/91dfaaf7a024d65529eaafcb50ac05ac074c7f82/crm-index-ui/static/js/rewrite/table/tableV2/SearchBar.tsx#L62-L64
            break;
          case CRM_OBJECT_TABLE_LOGGER_EVENT_KEYS.CREATE_REPORT_BUTTON_CLICKED:
            CrmLogger.log(e.metadata.eventName, e.metadata);
            break;
          case 'bulkEdit':
            trackBulkEditRecordsEvent({
              subscreen2: 'bulk-edit-modal',
              totalCount: e.metadata.totalCount.toString(),
              editedProperties: Object.keys(e.metadata.editedProperties).join(',')
            });
            break;
          case 'bulkDeleteSuccess':
            trackBulkDeleteEvent({
              subscreen2: 'bulk-delete-modal'
            });
            break;
          case 'bulkEnrichRecords':
            trackBulkEditRecordsEvent({
              key: 'bulkEnrichRecords',
              //bulkEnrichRecordsButton has `selectionIds`, which is not currently present
              count: e.metadata.count.toString()
            });
            break;
          case 'bulkAssignSuccess':
            trackBulkAssignEvent();
            break;
          case 'bulkCreateTaskSuccess':
            // @ts-expect-error This is typed incorrectly, it defaults the tracker options
            CrmLogger.logIndexInteraction(TASK, {
              action: 'Use task bulk action',
              subAction: 'Create task',
              count: e.metadata.count
            });
            break;
          case 'addToObjectListSuccess':
            CrmLogger.log('bulkAddToList', {
              objectTypeId: e.metadata.objectTypeId,
              screen: e.metadata.listId ? 'list-detail' : 'companies',
              subscreen: 'index',
              subscreen2: 'bulk-list-modal'
            }, {
              namespace: 'lists'
            });
            break;
          case 'bulkEnrollInSequenceClick':
            CrmLogger.log('use-bulk-enroll-in-sequence');
            break;
          case 'bulkEnrollInSequence':
            trackSingleEnrollSuccess(CRM);
            break;
          case 'bulkEnrollInWorkflow':
            CrmLogger.log('use-bulk-enroll-in-workflow');
            break;
          case 'bulkEditCommunicationSubscriptions':
            CrmLogger.log('saveBulkCommunicationSubscription', e.metadata);
            break;
          case 'bulkEditCommunicationSubscriptionsConfirm':
            CrmLogger.log('saveBulkCommunicationSubscription', {
              type: e.metadata.type,
              removeFromAllEmail: e.metadata.removeFromAllEmail,
              count: e.metadata.count
            });
            break;
          case 'bulkDeleteInvoices':
            trackBulkDeleteEvent({
              subscreen2: 'bulk-delete-invoices-modal'
            });
            break;
          case 'bulkCreateLeads':
            CrmLogger.logIndexInteraction('LEAD', {
              action: 'Use create leads bulk action',
              subAction: 'Create leads',
              count: e.metadata.count.toString()
            }, {
              sendImmediate: false,
              namespace: CRM
            });
            break;
          case 'bulkAddToPlaylist':
            trackIndexInteractionsEvent({
              action: 'bulk adds calls to playlist(s)'
            });
            break;
          case 'bulkAssignToAgent':
            CrmLogger.logIndexInteraction('PROSPECTING_AGENT', {
              action: 'Use assign to agent bulk action',
              subAction: 'Assign to agent',
              eventProps: JSON.stringify(e.metadata)
            }, {
              sendImmediate: false,
              namespace: CRM
            });
            break;
          default:
            Metrics.counter('crm-object-table-logger-unhandled-event', {
              key: e.key
            });
        }
      } catch (error) {
        Raven.captureException(error);
      }
    }
  }), [objectTypeId]);
};