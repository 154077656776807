export const objectEngagementsSummary = action => {
  const {
    text,
    objectId,
    objectType,
    context
  } = action;
  return {
    text: text || `Summarize all activities for the ${objectType.toLowerCase()}`,
    data: {
      intent: 'object_associations',
      chronological_association_type: `${objectType}_TO_ENGAGEMENT`,
      location_context: context,
      object: {
        object_type: objectType,
        object_id: Number(objectId)
      }
    }
  };
};