import { useEffect } from 'react';
export const useCloseOnEscape = ({
  open,
  onClose
}) => {
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape' && open) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onClose]);
};