import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { useCrmLinksContext } from '../../common/context/internal/CrmLinksContext';
import { FetchStatuses, isResolved, isRejected } from '../../common/types/FetchStatus';
import { useUserInfoContext } from '../../common/context/internal/UserInfoContext';
const getIsEnabled = (isEnabled, objectTypeId, allAppSettingsState, userInfo) => {
  var _allAppSettingsState$;
  // Keep status quo with how whether or not a record page is supported
  // while introducing enableCases support
  if (userInfo === undefined) {
    return isEnabled;
  }
  const {
    gates,
    user: {
      scopes
    }
  } = userInfo;
  const enableCases = (_allAppSettingsState$ = allAppSettingsState.data) === null || _allAppSettingsState$ === void 0 || (_allAppSettingsState$ = _allAppSettingsState$.get(objectTypeId)) === null || _allAppSettingsState$ === void 0 || (_allAppSettingsState$ = _allAppSettingsState$.crmRecordsPage.metadata.enableCases) === null || _allAppSettingsState$ === void 0 ? void 0 : _allAppSettingsState$.value;
  const isSupportedRecordPage = isEnabled && (!enableCases || !enableCases.length || enableCases.some(({
    scopes: enableScopes,
    gates: enableGates
  }) => {
    const includedGates = enableGates && enableGates.includes || [];
    const excludedGates = enableGates && enableGates.excludes || [];
    const includedScopes = enableScopes && enableScopes.includes || [];
    const excludedScopes = enableScopes && enableScopes.excludes || [];
    return includedGates.every(gate => gates.includes(gate)) && !excludedGates.some(gate => gates.includes(gate)) && includedScopes.every(scope => scopes.includes(scope)) && !excludedScopes.some(scope => scopes.includes(scope));
  }));
  return isSupportedRecordPage;
};
export const useObjectTypeSupportsRecord = objectTypeId => {
  const allAppSettingsState = useCrmLinksContext();
  const userInfo = useUserInfoContext();
  if (isPortalSpecificObjectType(objectTypeId)) {
    return {
      status: FetchStatuses.RESOLVED,
      data: true
    };
  }
  if (isResolved(allAppSettingsState)) {
    const appSettings = allAppSettingsState.data.get(objectTypeId);

    // If a Framework App Settings entry does not exist for the given object type
    // or its `enabled` value is false, it does not support a record page. If `enabled`
    // is true, it does.
    return {
      status: FetchStatuses.RESOLVED,
      data: !!(appSettings !== null && appSettings !== void 0 && appSettings.crmRecordsPage) && getIsEnabled(appSettings.crmRecordsPage.metadata.enabled.value, objectTypeId, allAppSettingsState, userInfo)
    };
  }
  if (isRejected(allAppSettingsState)) {
    return {
      status: FetchStatuses.REJECTED,
      data: null,
      error: allAppSettingsState.error
    };
  }
  return {
    status: allAppSettingsState.status,
    data: null
  };
};