import { gate } from 'hub-http/gates';
// @ts-expect-error untyped selector
import withGateOverride from 'crm_data/gates/withGateOverride';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
const reportCreationFromCRMGate = 'ReportCreation:SORBCreationFromCRM';
const reportCreationFromCRMWrappedGate = gate('ReportCreation:SORBCreationFromCRM');
export const useIsUngatedForReportCreationFromCRM = () => {
  const hasAllGates = useHasAllGates();
  const isUngatedForReportCreationFromCRM = withGateOverride(reportCreationFromCRMGate, hasAllGates(reportCreationFromCRMWrappedGate));
  return Boolean(isUngatedForReportCreationFromCRM);
};