import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["results", "total"];
import { useMemo } from 'react';
import getIn from 'transmute/getIn';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useReconciledObjectIdsToStageIds } from '../../localMutations/hooks/useReconciledObjectIdsToStageIds';
import { mutableUpdateIn } from '../../objectUtils/mutableUpdateIn';
// Need a new initialState every time
const getInitialState = () => ({
  resultsInRightStage: [],
  passivelyReconciledObjects: {}
});

// Reconciliation generally refers to manually moving objects between stages
// because ES lag puts them (or will put them) in the wrong stage. These are the types:

// Active: When the user drags an object from one stage to another
// (or uses the sidebar to change the object's stage), this is "active" reconciliation.
// We know exactly when the change happened and where the object came from/is going to,
// because we triggered the change from our own code.

// Passive: When someone other than the user changes an object's stage, there is a period
// of time where crmsearch will return the object in the wrong stage. We can detect this
// by reading the object's stage property, which is always up-to-date.

// This hook is the first step in the reconciliation process. It removes all objects that are
// in the wrong stage, returning them alongside the results that are in the right stage.
// It also applies local mutations, though this is not necessary here (it's just convenient).
export const usePreprocessBoardResults = boardResults => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'pipelineStagePropertyName' does not exis... Remove this comment to see the full error message
  const {
    pipelineStagePropertyName
  } = useSelectedObjectTypeDef();
  const reconciledObjectIds = useReconciledObjectIdsToStageIds();
  return useMemo(() => (boardResults || []).reduce(({
    resultsInRightStage,
    passivelyReconciledObjects
  }, _ref) => {
    let {
        pipelineStageId,
        crmSearchPayload: {
          results,
          total
        }
      } = _ref,
      restResults = _objectWithoutPropertiesLoose(_ref.crmSearchPayload, _excluded);
    // Iterate through all the results in this stage
    const correctResults = results.reduce((rightStage, object) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const reconciledStageId = reconciledObjectIds[object.objectId];
      const isObjectReconciledToAnotherStage = reconciledStageId && reconciledStageId !== pipelineStageId;

      // If the object has already been actively reconciled to another stage we can
      // immediately bail because it means the active reconciliation system will put
      // it in the right spot.
      if (isObjectReconciledToAnotherStage) {
        return rightStage;
      }
      const objectStageId = getIn(['properties', pipelineStagePropertyName, 'value'], object);

      // If the object's stageId does not match the stage's stageId, that means this object
      // is in the wrong stage and needs to be reconciled over to its actual home.
      const isObjectInWrongStage = objectStageId !== pipelineStageId;
      if (isObjectInWrongStage) {
        mutableUpdateIn([objectStageId], (reconciled = []) => reconciled.concat(object), passivelyReconciledObjects);
      } else {
        // If the object's stageId _does_ match the current stage's id, it is in the right
        // stage and can be returned in the result set.
        rightStage.push(object);
      }
      return rightStage;
    }, []);
    resultsInRightStage.push({
      pipelineStageId,
      crmSearchPayload: Object.assign({}, restResults, {
        total: total + (correctResults.length - results.length),
        results: correctResults
      })
    });
    return {
      resultsInRightStage,
      passivelyReconciledObjects
    };
  }, getInitialState()), [boardResults, pipelineStagePropertyName, reconciledObjectIds]);
};