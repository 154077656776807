import { useMemo } from 'react';
import { useHydratedSearchQueryOptions } from '../../data/useHydratedSearchQueryOptions';
import { usePipeline } from 'crm-object-board-core/pipeline';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
export const MAXIMUM_POSSIBLE_PORTAL_CURRENCIES = 200;
const addStageIdsFilter = ({
  group: {
    filters
  },
  pipelineStagePropertyName,
  stages
}) => ({
  filters: [...filters, {
    property: pipelineStagePropertyName,
    operator: 'IN',
    values: stages.map(({
      stageId
    }) => stageId)
  }]
});
const generateAggregationQueryWithAggregators = ({
  query: {
    query,
    filterGroups
  },
  objectTypeId,
  pipelineStagePropertyName,
  stages,
  aggregators
}) => ({
  objectTypeId,
  // This ensures that we exclude orphaned deals from our aggregations. The request will
  // stop at "size" buckets, so if we didn't do this orphaned deals from deleted stages
  // can take up a slot and prevent us from getting the data we need.
  filterGroups: (filterGroups || []).map(group => addStageIdsFilter({
    group,
    pipelineStagePropertyName,
    stages
  })),
  query,
  aggregations: aggregators.query({
    property: pipelineStagePropertyName,
    type: 'TERMS',
    // This is the number of "buckets" that the request returns. We need as many
    // buckets as the pipeline has stages.
    size: stages.length
  })
});
export const useAggregationQuery = aggregators => {
  const typeDef = useObjectTypeDefinition();
  const pipeline = usePipeline();
  const {
    data: query,
    loading
  } = useHydratedSearchQueryOptions();
  return useMemo(() => {
    let createdQuery = null;
    if (!loading && query && aggregators && pipeline && typeDef) {
      createdQuery = generateAggregationQueryWithAggregators({
        query,
        objectTypeId: typeDef.objectTypeId,
        pipelineStagePropertyName: typeDef.pipelineStagePropertyName,
        stages: pipeline.stages,
        aggregators
      });
    }
    return {
      loading: loading || typeDef === null || !aggregators,
      data: createdQuery
    };
  }, [loading, query, typeDef, pipeline, aggregators]);
};