import { CRM_INDEX_PAGE_APP_SETTING } from '../constants/AppSettings';
export function getFieldValue(metadata, fieldName) {
  const field = metadata === null || metadata === void 0 ? void 0 : metadata[fieldName];
  return field ? field.value : null;
}
export function getAppSettingForTypeDef({
  batchResults,
  typeDef,
  appSettingName = CRM_INDEX_PAGE_APP_SETTING
}) {
  if (!(typeDef !== null && typeDef !== void 0 && typeDef.fullyQualifiedName)) {
    return null;
  }
  const allAppSettings = batchResults === null || batchResults === void 0 ? void 0 : batchResults[typeDef.fullyQualifiedName];
  const appSetting = allAppSettings === null || allAppSettings === void 0 ? void 0 : allAppSettings[appSettingName];
  return (appSetting === null || appSetting === void 0 ? void 0 : appSetting.metadata) || null;
}