module.exports = {
  "/": {},
  "/companies": {},
  "/companies/list": {},
  "/contacts": {},
  "/contacts/list": {},
  "/deals": {},
  "/deals/list": {},
  "/deals/board": {},
  "/tickets": {},
  "/tickets/list": {},
  "/tickets/board": {},
  "/objects/:objectTypeId/restore": {},
  "/companies/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/companies/list/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/contacts/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/contacts/list/view": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/contacts/list/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/deals/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/deals/list/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/tickets/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/tickets/list/view/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId/list": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId/views/:viewId": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId/views/:viewId/list": {
    "name": "company",
    "success": {
      "table": [
        "DATA_TABLE_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "GRID_EMPTY_STATE"
      ]
    },
    "error": [
      "TABLE_ERROR",
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ],
      "unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/deals/board/view/:viewId": {
    "name": "dealBoard",
    "success": {
      "board": [
        "BOARD_COLUMN_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "BOARD_EMPTY_STATE"
      ],
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ]
    },
    "error": [
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "-unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/tickets/board/view/:viewId": {
    "name": "ticketBoard",
    "success": {
      "board": [
        "BOARD_COLUMN_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "BOARD_EMPTY_STATE"
      ],
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ]
    },
    "error": [
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "-unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId/views/:viewId/board": {
    "name": "objectBoard",
    "success": {
      "board": [
        "BOARD_COLUMN_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "BOARD_EMPTY_STATE"
      ],
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ]
    },
    "error": [
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "-unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  },
  "/objects/:objectTypeId/board": {
    "name": "objectBoardDefaultView",
    "success": {
      "board": [
        "BOARD_COLUMN_LOADED"
      ],
      "missingView": [
        "MISSING_VIEW"
      ],
      "empty": [
        "BOARD_EMPTY_STATE"
      ],
      "CRM_SEARCH_ERROR_HANDLED": [
        "CRM_SEARCH_ERROR_HANDLED"
      ]
    },
    "error": [
      "FULL_PAGE_ERROR",
      "CRM_SEARCH_ERROR_UNHANDLED"
    ],
    "partialSuccess": {
      "-unavailableObjectType": [
        "UNAVAILABLE_OBJECT_TYPE"
      ]
    }
  }
};