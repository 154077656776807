const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { useSearchQuery } from '../data/generateSearchQuery/useSearchQuery';
import { CrmObjectBoardObjectFragment } from '../data/useCrmSearch';
import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { rewriteObjectPropertiesAsMap } from '../data/utils';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
import { useApplyMutations } from './useApplyMutations';
import { useProps } from '../props/useProps';
export const CRM_OBJECTS_QUERY = withCrmObjectBoardNamespace('CRM_OBJECTS');
export const GET_CRM_OBJECTS = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"CRM_OBJECT_BOARD_CRM_OBJECTS\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"properties\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObjects\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"ids\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectBoardObjectFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CRM_OBJECT_BOARD_CRM_OBJECTS"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectIds"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Long"
              }
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "properties"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObjects"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "ids"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectIds"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CrmObjectBoardObjectFragment"
            }
          }]
        }
      }]
    }
  }].concat(CrmObjectBoardObjectFragment.definitions))
});
const EMPTY_RESULT = {};
export const useAddedItems = () => {
  const {
    newObjectIds
  } = useProps();
  const searchQuery = useSearchQuery();
  const objectTypeDefinition = useObjectTypeDefinition();
  const applyMutations = useApplyMutations();
  const {
    data,
    loading,
    error
  } = useQuery(GET_CRM_OBJECTS, {
    variables: {
      properties: searchQuery.properties,
      objectTypeId: searchQuery.objectTypeId,
      objectIds: newObjectIds && newObjectIds.length ? newObjectIds.map(id => parseInt(id, 10)) : []
    },
    skip: !newObjectIds || newObjectIds.length === 0
  });
  return useMemo(() => {
    //never worry about errors here
    if (!loading && error) {
      return EMPTY_RESULT;
    }
    if (!loading && data && data.crmObjects && objectTypeDefinition) {
      const result = {};
      data.crmObjects.forEach(obj => {
        var _mapped$data$properti;
        //mapp result properties
        const rawMap = rewriteObjectPropertiesAsMap(obj);
        //apply any localy mutations we have
        //rare scenario, someone has added an item then drag and dropped it
        const mapped = applyMutations({
          id: rawMap.objectId.toString(),
          data: rawMap
        });
        //if deleted just skip
        if (!mapped) {
          return;
        }
        //ensure property is added to the correct stage map
        const pipelineStagePropertyName = objectTypeDefinition.pipelineStagePropertyName;
        const stageId = (_mapped$data$properti = mapped.data.properties[pipelineStagePropertyName]) === null || _mapped$data$properti === void 0 ? void 0 : _mapped$data$properti.value;
        if (stageId) {
          //if first one add set array
          if (!result[stageId]) {
            result[stageId] = [];
          }
          //add it to the right stage
          result[stageId].push(mapped);
        }
      });
      return result;
    }
    return EMPTY_RESULT;
  }, [loading, data, error, objectTypeDefinition, applyMutations]);
};