import { markExists } from './markExists';
import { getPerformanceApi } from './performanceApi';
export const measureBetween = measure => {
  const perfApi = getPerformanceApi();
  if (!perfApi) {
    return undefined;
  }
  const {
    name,
    start,
    end
  } = measure;
  if (markExists(end)) {
    const {
      duration
    } = perfApi.measure(name, start.name, end.name);
    return duration;
  }
  return undefined;
};