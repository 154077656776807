import { getObjectTypeForLogging } from './utils';
import { Metrics } from 'crm-object-board-core/performance';
export const sendToLogger = (measurement, duration, objectTypeId) => {
  if (!measurement.shouldRecord) {
    return;
  }
  const metricObjectTypeId = getObjectTypeForLogging(objectTypeId);
  Metrics.timer(measurement.name, {
    metricObjectTypeId
  }).update(duration);
};