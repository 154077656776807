// This file contains very specific subset of Association values and features for NestedAssociationTable feature.

/**
 * Minimal set of fields required to identify an association and fetch its full version from graphQL.
 */

/**
 * Minimal set of fields for association-based search. The difference between this and `AssociationDefinition` is that this type is trimmed down and requires `inversedAssociationTypeId` to be defined.
 * */

/**
 * Manages different representations of association definitions.
 */
export class AssociationDefinitionManager {
  constructor(data) {
    this.data = data;
  }
  toLite() {
    const {
      fromObjectTypeId,
      toObjectTypeId
    } = this.data;
    return {
      prefixedId: this.getPrefixedId(),
      fromObjectTypeId,
      toObjectTypeId
    };
  }

  /**
   * Returns the prefixed id of the association. Internally we use object and association type id as `0-123` where `0` is the category of the association.
   * @throws Error if the association category is not HUBSPOT_DEFINED, USER_DEFINED or INTEGRATOR_DEFINED.
   */
  getPrefixedId(id) {
    const {
      associationCategory
    } = this.data;
    const _id = id !== null && id !== void 0 ? id : this.data.associationTypeId;
    switch (associationCategory) {
      case 'HUBSPOT_DEFINED':
        return `0-${_id}`;
      case 'USER_DEFINED':
        return `1-${_id}`;
      case 'INTEGRATOR_DEFINED':
        return `2-${_id}`;
      default:
        throw new Error('Attempted to produced a prefixed id for association, but the category is unknown.');
    }
  }

  /**
   * Returns the prefixed id of the inverse association. Internally we use object and association type id as `0-123` where `0` is the category of the association.
   * @throws Error if the association category is not HUBSPOT_DEFINED, USER_DEFINED or INTEGRATOR_DEFINED.
   */
  get inversePrefixedId() {
    return this.getPrefixedId(this.data.inverseAssociationTypeId);
  }

  /**
   * Attempts to create a new instance of CAssociationDefinition from a full definition. If the conversion fails (e.g. inverse association type id is not defined), null is returned.
   *
   * @param def The full definition of an association (typically from API response)
   * @returns An instance of CAssociationDefinition if the inverse association type id is defined, otherwise null.
   */
  static tryFromFull(def) {
    if (!def || !def.inverseAssociationTypeId) {
      return null;
    }
    return new AssociationDefinitionManager(def);
  }
  isToObjectDefault() {
    return this.data.toObjectTypeId.startsWith('0');
  }
}