import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPanelAction, closePanelAction } from '../actions/overlayActions';
import { getPanelType } from '../selectors/overlaySelectors';
import { EDIT_CARDS_PANEL, FILTER_PANEL, OBJECT_BUILDER_PANEL, LEGACY_OBJECT_BUILDER_PANEL, PREVIEW_PANEL, CONDITIONAL_PROPERTIES_PANEL, PRODUCT_CREATE_PANEL } from '../constants/panelTypes';
import { trackOpenMoreFiltersPanel, trackOpenPreviewSidebar } from '../../tracking/indexPageTracking';
import { usePanelData } from './usePanelData';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';

// FIXME: migrate to use type from customer-data-objects

export const usePanelActions = () => {
  const dispatch = useDispatch();
  const panelType = useSelector(getPanelType);
  const panelData = usePanelData();
  const selectedObjectTypeId = useSelectedObjectTypeId();
  const openPanel = useCallback((type, data) => dispatch(openPanelAction(type, data)), [dispatch]);
  const closePanel = useCallback(() => dispatch(closePanelAction()), [dispatch]);
  const openFilterPanel = useCallback(() => {
    if (panelType !== FILTER_PANEL) {
      trackOpenMoreFiltersPanel();
    }
    openPanel(FILTER_PANEL);
  }, [openPanel, panelType]);
  const openObjectBuilderPanel = useCallback(() => openPanel(OBJECT_BUILDER_PANEL), [openPanel]);
  const openLegacyObjectBuilderPanel = useCallback(legacyObjectBuilderWrapperProps => openPanel(LEGACY_OBJECT_BUILDER_PANEL, legacyObjectBuilderWrapperProps), [openPanel]);
  const togglePreviewPanel = useCallback((objectId, objectTypeId = selectedObjectTypeId) => {
    if (panelType === PREVIEW_PANEL && (!objectId || objectId === panelData.objectId)) {
      closePanel();
    } else {
      trackOpenPreviewSidebar({
        objectId,
        objectTypeId
      });
      openPanel(PREVIEW_PANEL, {
        objectId,
        objectTypeId
      });
    }
  }, [closePanel, openPanel, panelData.objectId, panelType, selectedObjectTypeId]);
  const openEditCardsPanel = useCallback(tabId => openPanel(EDIT_CARDS_PANEL, {
    tabId
  }), [openPanel]);
  const openConditionalPropertiesPanel = useCallback(({
    objectTypeId,
    objectId,
    stageId,
    onConfirm,
    onFinishUpdateCallback,
    onCancel,
    pipelineId
  }) => openPanel(CONDITIONAL_PROPERTIES_PANEL, {
    objectTypeId,
    objectId,
    stageId,
    onConfirm,
    onFinishUpdateCallback,
    pipelineId,
    onCancel
  }), [openPanel]);
  const openProductCreatePanel = useCallback((readOnly, onClose) => openPanel(PRODUCT_CREATE_PANEL, {
    readOnly,
    onClose
  }), [openPanel]);
  return {
    openFilterPanel,
    openObjectBuilderPanel,
    openLegacyObjectBuilderPanel,
    togglePreviewPanel,
    openEditCardsPanel,
    openProductCreatePanel,
    openConditionalPropertiesPanel,
    closePanel
  };
};