import styled from 'styled-components';
import { GREAT_WHITE, KOALA } from 'HubStyleTokens/colors';

/**
 * A simple skeleton ui for text which is currently loading; shows a gray bar.
 * It's approximately the height of average text in the current font size (0.8em),
 * so it looks sort of like text when you squint.
 *
 * Example:
 *   <SkeletonText />
 *
 * See https://git.hubteam.com/HubSpot/conversations-skeleton-state
 */
export const SkeletonText = styled.div.withConfig({
  displayName: "SkeletonText",
  componentId: "sc-47ai78-0"
})(["background-color:", ";border-radius:999px;display:inline-block;height:0.8em;width:6em;"], props => props.use === 'form-on-dark' ? GREAT_WHITE : KOALA);