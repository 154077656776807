import I18n from 'I18n';
export const getColumnByName = ({
  columns,
  columnKey,
  columnValue
}) => {
  return columns.find(column => column[columnKey] === columnValue);
};
export const isColumnConsideredComplete = stage => {
  var _stage$metadata;
  return (stage === null || stage === void 0 || (_stage$metadata = stage.metadata) === null || _stage$metadata === void 0 ? void 0 : _stage$metadata.isClosed) === 'true';
};
export const getIsLastActivityDateInactive = (lastActivityDate, value, unit) => {
  const minInactiveDateObject = I18n.moment().subtract(value, unit);
  return I18n.moment(lastActivityDate).isSameOrBefore(minInactiveDateObject);
};