import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { APP_SETTINGS_TO_FETCH } from './constants/AppSettings';
const fieldName = 'crmObjectTableAppSettings';
const getAppSettingQuery = registerQuery({
  fieldName,
  fetcher: () => getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
    appSettingNames: APP_SETTINGS_TO_FETCH
  }))
});
export const useFetchFrameworkAppSettings = () => {
  const {
    loading,
    error,
    data
  } = useQuery(getAppSettingQuery);
  return useMemo(() => ({
    data: data && data[fieldName],
    loading,
    error
  }), [data, error, loading]);
};