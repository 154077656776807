import { useMemo } from 'react';
import fromJS from 'transmute/fromJS';
import { getFlowUuids } from '../../../crm_ui/utils/boardUtils';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useAutomationFlows } from '../../../automation/hooks/useAutomationFlows';
import { useFetchAutomationFlows } from '../../../automation/hooks/useFetchAutomationFlows';
import { SUCCEEDED } from '../../constants/RequestStatus';
import { useCurrentPipeline } from '../../pipelines/hooks/useCurrentPipeline';
import { denormalizeTypeId } from '../../utils/denormalizeTypeId';
export const useAutomationFlowsForCurrentPipeline = () => {
  const objectType = denormalizeTypeId(useSelectedObjectTypeId());
  const currentPipeline = useCurrentPipeline();
  const flowIds = useMemo(() => {
    if (!currentPipeline) {
      return [];
    }
    return getFlowUuids(objectType, currentPipeline.pipelineId, currentPipeline.stages.map(({
      stageId
    }) => stageId)) || [];
  }, [objectType, currentPipeline]);
  const flowsFetchStatus = useFetchAutomationFlows(flowIds);
  const flowsJS = useAutomationFlows(flowIds);
  return useMemo(() => fromJS(flowsFetchStatus === SUCCEEDED ? flowsJS : {}), [flowsFetchStatus, flowsJS]);
};