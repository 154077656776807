import { useEffect, useRef } from 'react';

/**
 * This hook is useful when you want to display stale data while loading new data.
 * For example, if you have a table of data, and the user goes to the next page,
 * this hook will allow you to not have the table unmount and remount while the new page is loading
 * and instead show a loading spinner over the old results
 */
export const useStaleDataWhileLoading = ({
  data,
  loading
}) => {
  const oldData = useRef(data);
  useEffect(() => {
    if (!loading) {
      oldData.current = data;
    }
  }, [data, loading]);
  return loading && !data ? oldData.current : data;
};