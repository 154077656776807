import { useMemo, useState, useEffect } from 'react';
export const useAdjustAggregations = ({
  aggregations,
  aggregators,
  data,
  reconciledData
}) => {
  const [prevAggregations, setPrevAggregations] = useState({});
  const adjustedAggregations = useMemo(() => {
    if (!aggregations || !data || !reconciledData || !aggregators) {
      return null;
    }
    const typedAggregations = aggregations;
    return aggregators.reconcile(typedAggregations, data, reconciledData);
  }, [aggregations, data, reconciledData, aggregators]);
  useEffect(() => {
    if (adjustedAggregations) {
      setPrevAggregations(adjustedAggregations);
    }
  }, [adjustedAggregations]);
  return adjustedAggregations || prevAggregations;
};