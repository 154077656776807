import { catchBoardError } from '../../error/utils';
import http from 'hub-http/clients/apiClient';
const BASE_URL = 'pipelines/v2/pipelines';
const toClient = (settings = []) => {
  const result = {};
  settings.forEach(setting => {
    result[setting.stageId] = {
      collapse: setting.collapse === 'true'
    };
  });
  return result;
};
export const getStageSettings = ({
  pipelineId,
  stageIds
}) => {
  //let this error, logged via link, handle it in hook
  return http.post(`${BASE_URL}/${pipelineId}/batchGetUserStageSettings/`, {
    data: {
      settings: ['collapse'],
      stageIds
    }
  }).then(toClient);
};
export const putStageSettings = ({
  pipelineId,
  stageId,
  settings
}) => {
  //handle error here, dont let it get to apollo error
  return catchBoardError('update user stage settings', http.put(`${BASE_URL}/${pipelineId}/userStageSettings/${stageId}/`, {
    data: settings
  })).then(() => ({
    stageId,
    settings
  })).catch(() => {
    return {
      stageId,
      settings
    };
  });
};