import { useCallback } from 'react';
import { useProps } from '../props/useProps';
import { useGetLocalMutationsForQuery, useGetLocalMutationsForSingleObject, applyMutationsToObject, generateLocalMutationsQueryHash } from 'crm-object-search-query-utilities/localMutations';
import { useSearchQuery } from '../data/generateSearchQuery/useSearchQuery';
export const useApplyMutations = () => {
  const {
    objectTypeId
  } = useProps();
  const searchQuery = useSearchQuery();
  const getQueryMutations = useGetLocalMutationsForQuery();
  const getObjectMutations = useGetLocalMutationsForSingleObject({
    objectTypeId
  });
  return useCallback(obj => {
    const queryMutations = getQueryMutations({
      queryHash: generateLocalMutationsQueryHash({
        queryObject: searchQuery
      })
    });
    const objectMutations = getObjectMutations({
      objectId: obj.id
    });
    const data = applyMutationsToObject({
      object: obj.data,
      queryMutations,
      objectMutations
    });
    if (data === undefined) {
      return undefined;
    }
    return Object.assign({}, obj, {
      data
    });
  }, [getObjectMutations, getQueryMutations, searchQuery]);
};