import { AssociationDefinitionManager } from './associationModel';
import { getNestedAssociationTableDefaultProperty } from './defaultProperties';
import { produce } from 'immer';
const EMPTY_ARRAY = [];
export function showTable(model, activeRowId, associationDefinition) {
  return produce(model, draft => {
    const id = associationDefinition.getPrefixedId();
    const state = model.registry[id];
    draft.active = {
      rowId: activeRowId,
      associationPrefixId: id
    };
    draft.registry[id] = Object.assign({}, state !== null && state !== void 0 ? state : {}, {
      definition: associationDefinition
    });
  });
}
export function hideTable(model) {
  return produce(model, draft => {
    draft.active = undefined;
  });
}
export function toggleTable(model, activeRowId, associationDefinition) {
  var _model$active, _model$active2;
  return ((_model$active = model.active) === null || _model$active === void 0 ? void 0 : _model$active.rowId) === activeRowId && ((_model$active2 = model.active) === null || _model$active2 === void 0 ? void 0 : _model$active2.associationPrefixId) === associationDefinition.getPrefixedId() ? hideTable(model) : showTable(model, activeRowId, associationDefinition);
}
export function updateColumns(model, associationDefinition, columns) {
  return produce(model, draft => {
    const id = associationDefinition.getPrefixedId();
    const state = model.registry[id];
    draft.registry[id] = Object.assign({}, state !== null && state !== void 0 ? state : {
      definition: associationDefinition
    }, {
      columns
    });
  });
}
export function updateFilters(model, associationDefinition, filters) {
  return produce(model, draft => {
    const id = associationDefinition.getPrefixedId();
    const state = model.registry[id];
    draft.registry[id] = Object.assign({}, state !== null && state !== void 0 ? state : {
      definition: associationDefinition
    }, {
      filters
    });
  });
}
export function updateQuery(model, associationDefinition, query) {
  return produce(model, draft => {
    const id = associationDefinition.getPrefixedId();
    const state = model.registry[id];
    draft.registry[id] = Object.assign({}, state !== null && state !== void 0 ? state : {
      definition: associationDefinition
    }, {
      query
    });
  });
}
export function updateSorts(model, associationDefinition, sorts) {
  return produce(model, draft => {
    const id = associationDefinition.getPrefixedId();
    const state = model.registry[id];
    draft.registry[id] = Object.assign({}, state !== null && state !== void 0 ? state : {
      definition: associationDefinition
    }, {
      sorts
    });
  });
}
export function getColumnsWithDefault(model) {
  var _model$columns;
  return (_model$columns = model.columns) !== null && _model$columns !== void 0 ? _model$columns : [...getNestedAssociationTableDefaultProperty(model.definition.data.toObjectTypeId).columns, model.definition.isToObjectDefault() ? '' : model.definition.data.toObjectTypeDefinition.primaryDisplayLabelPropertyName].filter(Boolean);
}
export function getFilterPropertiesWithDefault(model) {
  var _ref, _model$filterProperti;
  return (_ref = (_model$filterProperti = model.filterProperties) !== null && _model$filterProperti !== void 0 ? _model$filterProperti : getNestedAssociationTableDefaultProperty(model.definition.data.toObjectTypeId).filterProperties) !== null && _ref !== void 0 ? _ref : EMPTY_ARRAY;
}
export function getSortsWithDefault(model) {
  var _ref2, _model$sorts;
  return (_ref2 = (_model$sorts = model.sorts) !== null && _model$sorts !== void 0 ? _model$sorts : getNestedAssociationTableDefaultProperty(model.definition.data.toObjectTypeId).sorts) !== null && _ref2 !== void 0 ? _ref2 : EMPTY_ARRAY;
}
export function getFiltersWithDefault(model) {
  var _ref3, _model$filters;
  return (_ref3 = (_model$filters = model.filters) !== null && _model$filters !== void 0 ? _model$filters : getNestedAssociationTableDefaultProperty(model.definition.data.toObjectTypeId).filters) !== null && _ref3 !== void 0 ? _ref3 : [];
}
export function createFromConfigValue(configValue = {
  registry: {}
}, associationDefinitions) {
  const hydratedRegistry = Object.entries(configValue.registry).reduce((acc, next) => {
    const key = next[0];
    const value = next[1];
    const maybeDefinition = AssociationDefinitionManager.tryFromFull(associationDefinitions === null || associationDefinitions === void 0 ? void 0 : associationDefinitions[key]);
    if (maybeDefinition) {
      acc[key] = Object.assign({}, value, {
        definition: maybeDefinition
      });
    }
    return acc;
  }, {});
  return Object.assign({}, configValue, {
    registry: hydratedRegistry
  });
}