import { useCallback, useRef, useState } from 'react';
export const useContextMenuState = () => {
  const [contextMenuState, setContextMenuState] = useState({
    columnName: null,
    columnNamespace: null,
    row: null
  });
  const targetRef = useRef(null);
  const closeContextMenu = useCallback(() => {
    targetRef.current = null;
    setContextMenuState({
      columnName: null,
      columnNamespace: null,
      row: null
    });
  }, [setContextMenuState]);
  const handleContextMenu = useCallback(({
    columnName,
    columnNamespace,
    row,
    targetElement
  }) => {
    setContextMenuState({
      columnName,
      columnNamespace,
      row: row !== null && row !== void 0 ? row : null
    });
    targetRef.current = targetElement;
  }, [setContextMenuState]);
  return {
    closeContextMenu,
    contextMenuState,
    targetRef,
    handleContextMenu
  };
};