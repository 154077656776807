import { useMemo } from 'react';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { useIndexPageAppSettingFieldValue } from '../../frameworkAppSettings/hooks/useIndexPageAppSettingFieldValue';
export default function useHasBulkActions({
  objectTypeId
}) {
  const {
    data: hasBulkActions,
    error,
    loading
  } = useIndexPageAppSettingFieldValue('hasBulkActions', objectTypeId);
  return useMemo(() => ({
    data: Boolean(isPortalSpecificObjectType(objectTypeId) || hasBulkActions),
    error,
    loading: Boolean(loading)
  }), [error, hasBulkActions, loading, objectTypeId]);
}