export const EDIT_COLUMNS_CONFIG_TYPES = {
  DISABLED: 'EDIT_COLUMNS_CONFIG_DISABLED',
  WITH_FROZEN_COLUMNS: 'EDIT_COLUMNS_CONFIG_WITH_FROZEN_COLUMNS',
  WITHOUT_FROZEN_COLUMNS: 'EDIT_COLUMNS_CONFIG_WITHOUT_FROZEN_COLUMNS'
};
export const editColumnsDefaultConfig = {
  type: EDIT_COLUMNS_CONFIG_TYPES.DISABLED
};
export function isEditColumnsWithFrozenColumnsConfig(config) {
  return config.type === EDIT_COLUMNS_CONFIG_TYPES.WITH_FROZEN_COLUMNS;
}
export function isEditColumnsDisabledConfig(config) {
  return config.type === EDIT_COLUMNS_CONFIG_TYPES.DISABLED;
}