import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, ORDER_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const generateApolloCacheId = (objectTypeId, objectId) => {
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      {
        return `Contact:${objectId}`;
      }
    case COMPANY_TYPE_ID:
      {
        return `Company:${objectId}`;
      }
    case DEAL_TYPE_ID:
      {
        return `Deal:${objectId}`;
      }
    case TICKET_TYPE_ID:
      {
        return `Ticket:${objectId}`;
      }
    case ORDER_TYPE_ID:
      {
        return `Order:${objectId}`;
      }
    default:
      {
        return `BasicCrmObject:${objectId}`;
      }
  }
};