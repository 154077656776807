import { useCallback } from 'react';
import { useAddObjectMutation } from 'crm-object-search-query-utilities/localMutations';
export const useAddLocalObjectMutation = () => {
  const addObjectMutation = useAddObjectMutation();
  return useCallback(({
    objectTypeId,
    pendingEdits
  }) => {
    return Object.entries(pendingEdits).forEach(([objectId, edits]) => {
      if (edits) {
        addObjectMutation({
          mutation: edits,
          objectId,
          objectTypeId
        });
      }
    });
  }, [addObjectMutation]);
};