import { useEffect, useState } from 'react';
import { fetchAccounts, fetchConnections } from '../api/syncBridgeAPI';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import { fetchSyncHealthRecords } from '../api/syncHealthAPI';
import { UserAttributeName } from '../constants/UserAttributes';
import { getHubUserAttribute } from '../utils/hubUserAttributes';
export function useHasAccountingIntegrationSyncError({
  appId,
  objectType
}) {
  const [accountingIntegrationConnectionSyncFailureInfo, setAccountingIntegrationConnectionSyncFailureInfo] = useState({
    accountId: null,
    connectionId: null,
    hasSyncFailureSinceLastHide: false,
    loading: true
  });
  useEffect(() => {
    const getAccountingIntegrationConnectionSyncFailureInfo = async () => {
      let accountId = null;
      let connectionId = null;
      let hasSyncFailureSinceLastHide = false;
      const accounts = await fetchAccounts(appId.toString());
      // Only get connections if an account exists for the app
      if (accounts.length > 0) {
        accountId = accounts[0].id;
        const connections = await fetchConnections(appId.toString(), accountId.toString());
        const connectionIds = connections.filter(connection => connection.status === 'STARTED' && connection.scope.objectTypes.hubSpot.objectTypeId === objectType);
        connectionId = connectionIds.length > 0 ? connectionIds[0].id : null;

        // Only check if there are failing to sync objects when sync for that object is turned on
        if (connectionId) {
          const syncHealthFailingToSyncObjects = await fetchSyncHealthRecords([connectionId], 'FAILING_TO_SYNC', 'LATEST_ACTIVITY', 'DESC', 1);
          const bannerLastDismissedDate = await getHubUserAttribute(UserAttributeName.HasDismissedInvoiceSyncErrorBanner, '0');

          // Only show banner if the latest sync error date is after the last dismissed date
          hasSyncFailureSinceLastHide = syncHealthFailingToSyncObjects.length > 0 && syncHealthFailingToSyncObjects[0].latestActivity > Number(bannerLastDismissedDate);
        }
      }
      setAccountingIntegrationConnectionSyncFailureInfo({
        accountId,
        connectionId,
        hasSyncFailureSinceLastHide,
        loading: false
      });
    };
    getAccountingIntegrationConnectionSyncFailureInfo().catch(rethrowError);
  }, [appId, objectType]);
  return accountingIntegrationConnectionSyncFailureInfo;
}