import { useEffect, useMemo, useState } from 'react';
import I18n from 'I18n';
import { generateAssociationLabel } from 'crm-index-associations-lib/utils/generateAssociationLabel';
import { isSupportedAssociation } from 'crm-index-associations-lib/utils/isSupportedAssociation';
import { AssociationCategoryTypesToIds } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useFetchEditColumnsModalData } from './useFetchEditColumnsModalData';
export const makeAssociationId = associationDefinition => `${AssociationCategoryTypesToIds[associationDefinition.associationCategory]}-${associationDefinition.associationTypeId}`;
const hasScope = (userInfoData, scope) => {
  return Boolean(userInfoData && userInfoData.userInfo.user.scopes.includes(scope));
};
const hasGate = (userInfoData, gate) => {
  return Boolean(userInfoData && userInfoData.userInfo.gates && userInfoData.userInfo.gates.includes(gate));
};
function isTagPropertyHidden(property, userInfoData) {
  return property.objectTypeId === DEAL_TYPE_ID && property.name === 'hs_tag_ids' && !hasScope(userInfoData, 'crm-pipelines-deal-tag-read-pre-seat') && !hasScope(userInfoData, 'crm-pipelines-deal-tag-read') || property.objectTypeId === TICKET_TYPE_ID && property.name === 'hs_tag_ids' && !hasScope(userInfoData, 'crm-pipelines-ticket-tag-read-pre-seat') && !hasScope(userInfoData, 'crm-pipelines-ticket-tag-read');
}
const isPropertyValidColumn = (property, userInfoData) => {
  return !property.hidden && !isTagPropertyHidden(property, userInfoData);
};
const sortArrayByDisplayOrder = (a, b) => {
  return a.displayOrder - b.displayOrder;
};
const ALL = 'all';
const ASSOCIATIONS = 'associations';
const convertPropertyDefinitionToOption = property => {
  return {
    text: property.label,
    value: property.name
  };
};
const convertAssociationDefinitionToOption = association => {
  return {
    text: generateAssociationLabel(association),
    value: `associations.${makeAssociationId(association)}`
  };
};
const getAssociationDefinitions = data => {
  return data.optionsData.allAssociationTypesFromObjectType.filter(associationDefinition => isSupportedAssociation(associationDefinition, {
    hasGate: gate => hasGate(data.userInfo, gate)
  })).map(convertAssociationDefinitionToOption);
};
export const useAddColumnContextMenuOptions = ({
  objectTypeId,
  columnPropertyNames
}) => {
  const {
    data,
    loading,
    error
  } = useFetchEditColumnsModalData({
    objectTypeId
  });
  const [searchString, setSearchString] = useState('');
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(ALL);
  const columnsSet = useMemo(() => new Set(columnPropertyNames), [columnPropertyNames]);
  const allOptions = useMemo(() => {
    return data ? data.optionsData.allPropertyGroups.flatMap(group => group.allPropertyDefinitions).filter(propertyDefinition => isPropertyValidColumn(propertyDefinition, data.userInfo)).map(convertPropertyDefinitionToOption).concat(getAssociationDefinitions(data)) : [];
  }, [data]);
  const propertyOptions = useMemo(() => {
    if (!data) return [];
    let options = allOptions;
    if (searchString) {
      options = allOptions.filter(({
        text
      }) => text.toLowerCase().includes(searchString.toLowerCase()));
    } else if (selectedPropertyGroup === ASSOCIATIONS) {
      options = getAssociationDefinitions(data);
    } else if (selectedPropertyGroup !== ALL) {
      var _data$optionsData$all, _data$optionsData$all2;
      options = ((_data$optionsData$all = (_data$optionsData$all2 = data.optionsData.allPropertyGroups.find(group => group.name === selectedPropertyGroup)) === null || _data$optionsData$all2 === void 0 ? void 0 : _data$optionsData$all2.allPropertyDefinitions) !== null && _data$optionsData$all !== void 0 ? _data$optionsData$all : []).filter(propertyDefinition => isPropertyValidColumn(propertyDefinition, data.userInfo)).map(convertPropertyDefinitionToOption);
    }
    return options.filter(option => !columnsSet.has(option.value));
  }, [allOptions, data, columnsSet, searchString, selectedPropertyGroup]);
  useEffect(() => {
    if (searchString) setSelectedPropertyGroup(ALL);
  }, [searchString]);
  const propertyGroupOptions = useMemo(() => {
    var _data$optionsData$all3;
    const allOption = {
      value: ALL,
      text: I18n.text('crmIndexTableEditColumnsModal.addColumnContextMenu.allOption')
    };
    const associationsOption = {
      value: ASSOCIATIONS,
      text: 'Associations'
    };
    const sortedPropertyGroups = [...((_data$optionsData$all3 = data === null || data === void 0 ? void 0 : data.optionsData.allPropertyGroups) !== null && _data$optionsData$all3 !== void 0 ? _data$optionsData$all3 : [])].sort(sortArrayByDisplayOrder);
    return [allOption, associationsOption, ...sortedPropertyGroups.map(group => {
      return {
        value: group.name,
        text: group.label
      };
    })];
  }, [data]);
  return {
    propertyOptions,
    propertyGroupOptions,
    loading,
    error,
    setSearchString,
    selectedPropertyGroup,
    setSelectedPropertyGroup
  };
};