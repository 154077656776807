import { useEffect } from 'react';
import { mark } from '../lib/mark';
import { getPerformanceApi } from '../lib/performanceApi';
export const useMarkOnMount = (marker, {
  once = true
} = {}) => {
  useEffect(() => {
    const perfApi = getPerformanceApi();
    if (!perfApi) {
      return;
    }
    if (once) {
      const lastMeasurement = perfApi.getEntriesByName(marker.name, 'mark');
      if (lastMeasurement && lastMeasurement.length > 0) {
        return;
      }
    }
    mark(marker);
  }, [marker, once]);
};