import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import set from 'transmute/set';
import { useApolloCacheActions } from '../../../apollo/hooks/useApolloCacheActions';
import { getReconciledPipelineableObjectIdsByStageId } from '../selectors/localCrmObjectMutationsSelectors';
export const useAllReconciledObjects = () => {
  const reconciledStageObjects = useSelector(getReconciledPipelineableObjectIdsByStageId);
  const {
    readObjects
  } = useApolloCacheActions();
  return useMemo(() => Object.entries(reconciledStageObjects).reduce((acc, [stageId, objectIds]) => set(stageId, readObjects(objectIds), acc), {}), [readObjects, reconciledStageObjects]);
};