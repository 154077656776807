import { BOARD_CARD_LOADED_MARK, BOARD_FOOTER_LOADED_MARK, BOARD_LOADED_MARK, BOARD_MOUNT_MARK, CRM_SEARCH_COMPLETE_MARK, CRM_SEARCH_START_MARK } from './markers';
const BOARD_LOADED = 'BOARD_LOADED';
const BOARD_CARD_LOADED = 'BOARD_CARD_LOADED';
const BOARD_FOOTER_LOADED = 'BOARD_FOOTER_LOADED';
const CRM_SEARCH_COMPLETE = 'CRM_SEARCH_COMPLETE';
export const BOARD_MOUNTED_MEASURE = {
  start: BOARD_MOUNT_MARK,
  end: BOARD_LOADED_MARK,
  name: BOARD_LOADED,
  shouldRecord: true
};
export const BOARD_CARD_MOUNTED_MEASURE = {
  start: BOARD_MOUNT_MARK,
  end: BOARD_CARD_LOADED_MARK,
  name: BOARD_CARD_LOADED,
  shouldRecord: true
};
export const BOARD_FOOTER_MOUNTED_MEASURE = {
  start: BOARD_MOUNT_MARK,
  end: BOARD_FOOTER_LOADED_MARK,
  name: BOARD_FOOTER_LOADED,
  shouldRecord: true
};
export const CRM_SEARCH_COMPLETE_MEASURE = {
  start: CRM_SEARCH_START_MARK,
  end: CRM_SEARCH_COMPLETE_MARK,
  name: CRM_SEARCH_COMPLETE,
  shouldRecord: true
};