import { usePipeline } from 'crm-object-board-core/pipeline';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { getColumnByName, isColumnConsideredComplete } from '../utils';
import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
export const useIsRecordInCompleteColumn = ({
  crmObject
}) => {
  const pipeline = usePipeline();
  const typeDef = useObjectTypeDefinition();
  const {
    pipelinePropertyName,
    pipelineStagePropertyName
  } = typeDef || Object.create(null);
  if (!pipeline || !pipelinePropertyName || !pipelineStagePropertyName) {
    return false;
  }
  const columns = pipeline.stages;
  const stageId = getProperty(crmObject, pipelineStagePropertyName);
  if (!stageId) {
    return false;
  }
  const column = getColumnByName({
    columns,
    columnKey: 'stageId',
    columnValue: stageId
  });
  return !!column && isColumnConsideredComplete(column);
};