import { configureStore as createStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { inlineEditingReducer } from '../../inlineEdit/inlineEditingSlice';
export const configureStore = (preloadedState = {}) => createStore({
  middleware: [thunk],
  reducer: {
    inlineEditing: inlineEditingReducer
  },
  devTools: {
    name: 'crm-object-table'
  },
  preloadedState
});