import { useState, useEffect } from 'react';
import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../../constants/RequestStatus';
import { hydrateSearchQuery } from '../api/hydrateSearchQuery';
import { useSearchQuery } from './useSearchQuery';
export const useHydratedSearchQuery = () => {
  const query = useSearchQuery();
  const [state, setHydratedQueryState] = useState({
    status: UNINITIALIZED,
    query: null
  });
  useEffect(() => {
    setHydratedQueryState({
      status: PENDING,
      query: null
    });
    hydrateSearchQuery(query).then(hydratedQuery => setHydratedQueryState({
      status: SUCCEEDED,
      query: hydratedQuery
    })).catch(() => setHydratedQueryState({
      status: FAILED,
      query: null
    }));
  }, [query]);
  return state;
};