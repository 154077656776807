import { useState, useEffect } from 'react';
import mark from '../mark';
const useMarkOnValuePresent = (value, performanceMark) => {
  const [isMarked, setIsMarked] = useState(false);
  useEffect(() => {
    if (!isMarked && value !== undefined) {
      mark(performanceMark);
      setIsMarked(true);
    }
  }, [value, isMarked, performanceMark]);
};
export default useMarkOnValuePresent;