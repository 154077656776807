import Loadable from 'UIComponents/decorators/Loadable';
import { ShinySkeleton } from './ShinySkeleton';
function isAsyncCardSectionRegistryEntry(registryEntry) {
  return !!registryEntry.componentLoader;
}
export function buildCardSectionRegistry(registryMap) {
  function getSectionKeys() {
    return registryMap ? Object.keys(registryMap) : [];
  }
  function getSectionType(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      console.error(`getSectionType: Card section ${sectionKey} not found in registry`);
      return undefined;
    }
    return registryEntry.sectionType;
  }
  function getSectionComponent(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      console.error(`getSectionComponent: Card section ${sectionKey} not found in registry`);
      return undefined;
    }
    if (isAsyncCardSectionRegistryEntry(registryEntry)) {
      return Loadable({
        loader: registryEntry.componentLoader,
        // TODO Maddie - Replace with proper loading component
        LoadingComponent: ShinySkeleton
      });
    }
    return registryEntry.Component;
  }
  function getLazyLoadedTranslations(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      console.error(`getLazyLoadedTranslations: Card section ${sectionKey} not found in registry`);
      return undefined;
    }
    return registryEntry.lazyLoadedTranslations;
  }
  function getRequiredProperties(sectionKey, settings) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      console.error(`getRequiredProperties: Card section ${sectionKey} not found in registry`);
      return undefined;
    }
    return registryEntry.requiredProperties ? registryEntry.requiredProperties(settings) : [];
  }
  return {
    getSectionComponent,
    getLazyLoadedTranslations,
    getSectionKeys,
    getSectionType,
    getRequiredProperties
  };
}