import { useMemo } from 'react';
import { useFetchFrameworkAppSettings } from '../useFrameworkAppSettings';
import { getAppSettingForTypeDef, getFieldValue } from '../utils/getFieldValue';
import { useObjectTypeDefinitionContext } from '../../crmObjects/ObjectTypeDefinitionsContext';
export const useIndexPageAppSettingFieldValue = (fieldName, objectTypeId) => {
  const {
    data,
    loading,
    error
  } = useFetchFrameworkAppSettings();
  const {
    data: typeDef,
    loading: typeDefLoading,
    error: typeDefError
  } = useObjectTypeDefinitionContext(objectTypeId);
  const loadingOrError = useMemo(() => ({
    data: null,
    error: error || typeDefError,
    loading: loading || typeDefLoading
  }), [error, typeDefError, loading, typeDefLoading]);
  const metadata = getAppSettingForTypeDef({
    batchResults: data,
    typeDef
  });
  const response = useMemo(() => ({
    data: getFieldValue(metadata, fieldName),
    error: null,
    loading: null
  }), [metadata, fieldName]);
  if (loading || typeDefLoading || error || typeDefError || !data || !typeDef) {
    return loadingOrError;
  }
  return response;
};