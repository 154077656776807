import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["results", "total"];
import { useMemo } from 'react';
import { unique } from '../../../utils/unique';
import { useAllReconciledObjects } from '../../localMutations/hooks/useAllReconciledObjects';
import { useApplyLocalMutationsCallback } from '../../localMutations/hooks/useApplyLocalMutationsCallback';
import { usePreprocessBoardResults } from './usePreprocessBoardResults';
// Reconciliation generally refers to manually moving objects between stages
// because ES lag puts them (or will put them) in the wrong stage. These are the types:

// Active: When the user drags an object from one stage to another
// (or uses the sidebar to change the object's stage), this is "active" reconciliation.
// We know exactly when the change happened and where the object came from/is going to,
// because we triggered the change from our own code.

// Passive: When someone other than the user changes an object's stage, there is a period
// of time where crmsearch will return the object in the wrong stage. We can detect this
// by reading the object's stage property, which is always up-to-date.

// This hook is the second step in the reconciliation process, where we merge all
// reconciled objects (both active and passive) into the results.
export const useReconcileBoardResults = boardResults => {
  const activelyReconciledObjects = useAllReconciledObjects();
  const {
    resultsInRightStage,
    passivelyReconciledObjects
  } = usePreprocessBoardResults(boardResults);
  const applyLocalMutations = useApplyLocalMutationsCallback();

  // Unfortunately we must do this in two passes, otherwise we might end up missing objects if a later
  // stage has objects that are reconciled into an earlier stage.
  return useMemo(() => resultsInRightStage.map(_ref => {
    let {
        pipelineStageId,
        crmSearchPayload: {
          results,
          total
        }
      } = _ref,
      restResults = _objectWithoutPropertiesLoose(_ref.crmSearchPayload, _excluded);
    const passivelyReconciledObjectsForStage = passivelyReconciledObjects[pipelineStageId] || [];
    const activelyReconciledObjectsForStage =
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    activelyReconciledObjects[pipelineStageId] || [];

    // Merge active + passive reconciliation with the results array. Active is user-generated reconciliation
    // (such as dragging a card) while passive fixes crmsearch lag putting objects in the wrong stage.
    const reconciledResults = [...activelyReconciledObjectsForStage, ...passivelyReconciledObjectsForStage, ...results];
    const uniqueReconciledResults = unique(reconciledResults, ({
      objectId
    }) => objectId);
    const resultsWithLocalMutations = applyLocalMutations({
      results: uniqueReconciledResults,
      addCreates: false
    });
    return {
      pipelineStageId,
      crmSearchPayload: Object.assign({}, restResults, {
        total: total + (resultsWithLocalMutations.length - results.length),
        results: resultsWithLocalMutations
      })
    };
  }), [activelyReconciledObjects, applyLocalMutations, passivelyReconciledObjects, resultsInRightStage]);
};