import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["hasPerPipeline"];
import { useMemo } from 'react';
import { useQuery, registerQuery } from 'data-fetching-client';
import { getBoardSettingsByObject, getBoardSettingsByPipeline } from 'crm-pipeline-board-settings-lib/api/boardSettingsApi';
import { useBoardSettings } from 'crm-object-board-core/frameworkAppSettings';
import { useGetHasScope } from 'crm-object-board-core/user';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
import { useProps } from '../props/useProps';
export const PIPELINE_SETTINGS_NAME = withCrmObjectBoardNamespace('pipelineSettings');
export const PIPELINE_SETTINGS_QUERY = registerQuery({
  fieldName: PIPELINE_SETTINGS_NAME,
  args: ['objectTypeId', 'pipelineId'],
  fetcher: _ref => {
    let {
        hasPerPipeline
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return hasPerPipeline ? getBoardSettingsByPipeline(rest) : getBoardSettingsByObject(rest);
  }
});
export const usePipelineBoardSettings = () => {
  var _boardSettings$enable;
  const {
    objectTypeId,
    pipelineId
  } = useProps();
  const {
    hasScope,
    loading: loadingScopes
  } = useGetHasScope();
  const hasPerPipelineScope = hasScope('crm-board-card-customization-per-pipeline');
  const boardSettings = useBoardSettings();
  const enabledForObject = boardSettings === null || boardSettings === void 0 || (_boardSettings$enable = boardSettings.enabled) === null || _boardSettings$enable === void 0 ? void 0 : _boardSettings$enable.value;
  const hasBoardCustomizationAccess = Boolean(enabledForObject);
  const {
    data,
    loading,
    error
  } = useQuery(PIPELINE_SETTINGS_QUERY, {
    skip: !hasBoardCustomizationAccess || !objectTypeId || loadingScopes,
    variables: {
      pipelineId,
      objectTypeId,
      hasPerPipeline: hasPerPipelineScope
    }
  });
  return useMemo(() => {
    if (!loading && !error && data) {
      return data[PIPELINE_SETTINGS_NAME];
    }
    return null;
  }, [data, loading, error]);
};