import { useMemo } from 'react';
import { useHasPreferredDisplayCurrency } from '../../board/hooks/useHasPreferredDisplayCurrency';
import { usePreferredDisplayCurrency } from '../../multiCurrency/hooks/usePreferredDisplayCurrency';
import { useConvertCompanyCurrencyToDisplayCurrency } from '../../multiCurrency/hooks/usePreferredDisplayCurrencyConverters';
import { createAmountAggregator } from '../aggregators/amount/amountAggregator';
import { useProperties } from '../../properties/hooks/useProperties';
import { isValidAmountAggregationConfig } from 'crm-pipeline-board-settings-lib/aggregations/amount/amountConfig';

/**
 * Takes an array of Array<{[stageId]:Results}>
 * converts it to Array<{[stageId]:Results[]}>
 */
const toStageAggregationResultCollection = results => {
  return results.reduce((aggregations, result) => {
    Object.keys(result).forEach(stage => {
      aggregations[stage] = aggregations[stage] || {};
      aggregations[stage][result[stage].aggregatorId] = result[stage];
    });
    return aggregations;
  }, {});
};
export const useAggregators = configs => {
  const hasPreferredCurrency = useHasPreferredDisplayCurrency();
  const preferredCurrencyCode = usePreferredDisplayCurrency();
  const preferredCurrencyConverter = useConvertCompanyCurrencyToDisplayCurrency();
  const properties = useProperties();
  return useMemo(() => {
    const isAggregator = agg => !!agg;

    //no configs
    //either no settings loaded
    //or we are behind the gate
    if (!configs) {
      return undefined;
    }
    const aggregators = configs.map(config => {
      //@ts-expect-error ImmutableMap vs Map
      if (config && isValidAmountAggregationConfig(properties, config)) {
        const currencyProperty = properties.get(config.meta.currencyProperty);
        const currencyCodeProperty = currencyProperty && currencyProperty.currencyPropertyName;
        return createAmountAggregator(config, {
          hasPreferredCurrency,
          preferredCurrencyCode,
          preferredCurrencyConverter,
          //@ts-expect-error currencyCodeProperty can be undefined, but createAmountAggregator says its cant
          currencyCodeProperty
        });
      }
      return undefined;
    }).filter(isAggregator);
    if (aggregators.length === 0) {
      return undefined;
    }
    return {
      query: options => {
        return aggregators.map(agg => agg.query(options));
      },
      handleResult: queryResult => {
        const results = aggregators.map(agg => agg.handleResult(queryResult));
        return toStageAggregationResultCollection(results);
      },
      reconcile: (aggregations, data, reconciledData) => {
        //first must break apart the collection of results
        //we can use the aggregators index to pick the result we need for a given stage
        //convert this to a result object for consumption by the aggregator
        const stages = Object.keys(aggregations);
        const results = aggregators.map(agg => {
          const baseResult = {};
          stages.forEach(stage => {
            baseResult[stage] = aggregations[stage][agg.id];
          });
          return agg.reconcile(baseResult, data, reconciledData);
        });
        return toStageAggregationResultCollection(results);
      },
      getText: props => {
        if (!props) {
          return [];
        }
        return aggregators.map(agg => {
          return agg.getText(props.aggregation ? props.aggregation[agg.id] : undefined, props);
        });
      },
      getTooltipText: props => {
        if (!props) {
          return [];
        }
        return aggregators.filter(x => x.getTooltipText).map(agg => {
          return agg.getTooltipText(props.aggregation ? props.aggregation[agg.id] : undefined, props);
        });
      }
    };
  }, [hasPreferredCurrency, preferredCurrencyCode, preferredCurrencyConverter, properties, configs]);
};