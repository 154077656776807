import { useHasFeatureAccess, useObjectTypeFeatureByAppSetting } from 'framework-app-settings-features-lib';
import { useBoardProps } from '../boardProps/useBoardProps';
export const useHasPipelineApprovalAccess = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const {
    data: approvalFeature
  } = useObjectTypeFeatureByAppSetting(objectTypeId, 'crm_object_pipelines', 'approval');
  return useHasFeatureAccess(approvalFeature || null);
};