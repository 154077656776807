import Raven from 'raven-js';
import { useEffect, useRef } from 'react';
const trackPerformanceOfQueryResult = ({
  isLoading,
  startTimeRef,
  onTrack
}) => {
  //Base case - first render and we havent started timing
  if (isLoading && startTimeRef.current === null) {
    // Start timing when loading begins
    startTimeRef.current = performance.now();
    return;
  }
  if (startTimeRef.current === null) {
    //we already stopped timing, and we are not loading so there is nothing to track
    return;
  }
  if (!isLoading) {
    //Final case - loading has ended and we need to stop timing and track the duration
    const endTime = performance.now();
    const duration = endTime - startTimeRef.current;
    onTrack(duration);

    // Clean up startTimeRef
    startTimeRef.current = null;
  }
};

/**
 * This hook can be used to track how long an apollo/DFC/custom-hook style query takes to load
 *Example:
 * ```tsx
 * const MyComponent = () => {
 *   const { data, loading, error } = useQuery(MY_QUERY);
 *
 *   useTrackPerformanceOfQueryResult({
 *     queryResult: { data, loading, error },
 *     onTrack: (duration) => {
 *       Metrics.track('my-query', { duration });
 *       console.log('Query took', duration, 'ms to load');
 *     }
 *   });
 * ```
 *
 * Note: we discussed an alternative approach for this hook in the PR that created it that follows the pattern below.
 * If using `useTrackPerformanceOfQueryResult` becomes unweildy, we can consider refactoring to this pattern.
 * ```tsx
 * const { data, loading, error } = useMeasureQuery(FakeQuery, { variables: { queryArgument }, onMeasure });
 * ```
 */
export const useTrackPerformanceOfQueryResult = ({
  queryResult,
  onTrack
}) => {
  const startTimeRef = useRef(null);
  const isLoading = queryResult.loading;
  useEffect(() => {
    try {
      trackPerformanceOfQueryResult({
        isLoading,
        startTimeRef,
        onTrack
      });
    } catch (e) {
      //if there is an error monitoring performance, it should not bring down the component
      if (e instanceof Error) {
        Raven.captureException(e);
      }
    }
  }, [onTrack, isLoading]);
};