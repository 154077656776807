import { useProps } from './useProps';
import { useMemo } from 'react';
export const useLogger = () => {
  const props = useProps();
  return useMemo(() => {
    if (props.logger) {
      return props.logger;
    }
    return {
      log: console.debug //lets just log as verbose so it is easier to filter out of console log
    };
  }, [props.logger]);
};