import { useMemo } from 'react';
import get from 'transmute/get';
import { useApplyLocalMutationsCallback } from './useApplyLocalMutationsCallback';
/**
 * This hook consumes a 'data' object from CrmSearch and performs three major operations on it:
 *
 * 1. It filters out any objects that have been deleted.
 * 2. It prepends any objects that have been created, regardless of whether or not they match the current query.
 * 3. It updates property values of any objects that have been mutated (via the sidebar, bulk edit modal, etc).
 *
 * On the board, we do not want to add created objects (instead they should be reconciled into the correct stage)
 * so you can disable that behavior with the "addCreates" option.
 *
 * @param {Object} data - The CrmSearch response.
 * @param {boolean} options.addCreates - Default true. Set to false to omit locally cached creates from the results.
 *
 * @returns {Object} - The same format as the data parameter.
 */
export const useApplyLocalMutations = (data, {
  addCreates = true
} = {}) => {
  const applyLocalMutations = useApplyLocalMutationsCallback();
  return useMemo(() => {
    // If we were given data to start with, we know it is safe to return our
    // enhanced data.
    if (data) {
      return Object.assign({}, data, {
        results: applyLocalMutations({
          results: get('results', data),
          addCreates
        })
      });
    }

    // Otherwise our enhancements did nothing, so we should just return undefined
    return undefined;
  }, [addCreates, applyLocalMutations, data]);
};