import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const initialState = {
  selection: {},
  querySelected: false
};
const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setCardsSelected: (state, action) => {
      action.payload.forEach(({
        cardId,
        selected
      }) => {
        state.selection[cardId] = selected;
      });
      state.querySelected = false;
    },
    selectQuery: state => {
      state.querySelected = true;
    },
    clearSelection: state => {
      state.selection = {};
      state.querySelected = false;
    }
  }
});
export const selectionReducer = selectionSlice.reducer;
export const {
  setCardsSelected,
  selectQuery,
  clearSelection
} = selectionSlice.actions;
export const getSelectionState = ({
  selection
}) => selection;
export const getIsQuerySelected = createSelector([getSelectionState], ({
  querySelected
}) => querySelected);
export const getSelectedCardIds = createSelector([getSelectionState], ({
  selection
}) => new Set(Object.entries(selection).filter(([__cardId, selected]) => selected).map(([cardId]) => cardId)));
export const getSelectionCount = createSelector([getSelectedCardIds], selectedCardIds => selectedCardIds.size);
export const getSomeSelected = createSelector([getSelectedCardIds], selectedCardIds => selectedCardIds.size > 0);
export const makeGetIsCardSelected = cardId => createSelector([getSelectedCardIds], selectedIds => selectedIds.has(cardId));