import { fetchByUuids } from '../api/automationAPI';
import { FETCH_AUTOMATION_FLOW_STARTED, FETCH_AUTOMATION_FLOW_SUCCEEDED, FETCH_AUTOMATION_FLOW_FAILED } from './automationActionTypes';
export const fetchAutomationFlowsAction = flowIds => dispatch => {
  dispatch({
    type: FETCH_AUTOMATION_FLOW_STARTED,
    payload: {
      flowIds
    }
  });
  return fetchByUuids(flowIds).then(response => {
    dispatch({
      type: FETCH_AUTOMATION_FLOW_SUCCEEDED,
      payload: {
        flowIds,
        response
      }
    });
    return response;
  }).catch(error => {
    dispatch({
      type: FETCH_AUTOMATION_FLOW_FAILED,
      payload: {
        flowIds
      }
    });
    throw error;
  });
};