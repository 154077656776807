import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getIsPortalSpecific } from '../methods/getIsPortalSpecific';
import { useIndexPageAppSettingFieldValue } from '../../frameworkAppSettings/hooks/useIndexPageAppSettingFieldValue';

/**
 * Returns true/false depending on if the index page is configured to allow exporting of the selected object type.
 * This is affected via the CrmIndexPage app setting "hasExport".
 *
 * If the selected object type is portal specific (a custom object), this will always return true.
 */
export const useCanExportType = () => {
  const typeDef = useSelectedObjectTypeDef();
  const hasExport = Boolean(useIndexPageAppSettingFieldValue('hasExport'));

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  return getIsPortalSpecific(typeDef) || hasExport;
};