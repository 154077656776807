import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
import { PIPELINE_BOARD_SETTINGS } from '../settings';
import { PRIMARY_AGGREGATION_SETTING, SECONDARY_AGGREGATION_SETTING } from '../settings/aggregation';
import { SUMMARY_PLACEMENT_FOOTER, SUMMARY_PLACEMENT_SETTING } from '../settings/summaryPlacement';
import { SHOW_WORKFLOW_ICON_SETTING } from '../settings/showWorkflowIcon';
const BASE_URL = 'pipelines/v2/pipelines/board-settings';
const toClient = response => {
  const settings = {
    aggregations: [],
    summaryPosition: SUMMARY_PLACEMENT_FOOTER,
    showWorkflowIcon: true
  };
  response.forEach(res => {
    switch (res.key) {
      case SUMMARY_PLACEMENT_SETTING:
        {
          if (res.value) {
            settings.summaryPosition = res.value.toUpperCase();
          }
          break;
        }
      case SHOW_WORKFLOW_ICON_SETTING:
        {
          if (res.value) {
            settings.showWorkflowIcon = JSON.parse(res.value);
          }
          break;
        }
      case PRIMARY_AGGREGATION_SETTING:
        {
          if (res.value) {
            const parsedValue = JSON.parse(res.value);
            settings.aggregations[0] = parsedValue;
          }
          break;
        }
      case SECONDARY_AGGREGATION_SETTING:
        {
          if (res.value) {
            const parsedValue = JSON.parse(res.value);
            settings.aggregations[1] = parsedValue;
          }
          break;
        }
      default:
        break;
    }
  });
  return settings;
};
const toServer = data => {
  const result = [];
  result.push({
    key: SHOW_WORKFLOW_ICON_SETTING,
    value: data.showWorkflowIcon.toString()
  });
  result.push({
    key: SUMMARY_PLACEMENT_SETTING,
    value: data.summaryPosition.toString()
  });
  if (data.aggregations && data.aggregations.length) {
    result.push({
      key: PRIMARY_AGGREGATION_SETTING,
      value: JSON.stringify(data.aggregations[0])
    });
    if (data.aggregations[1]) {
      result.push({
        key: SECONDARY_AGGREGATION_SETTING,
        value: JSON.stringify(data.aggregations[1])
      });
    } else {
      //if no second aggregation set to undefined
      result.push({
        key: SECONDARY_AGGREGATION_SETTING,
        value: ''
      });
    }
  } else {
    //no aggregations at all so save both to undefined
    result.push({
      key: PRIMARY_AGGREGATION_SETTING,
      value: ''
    });
    result.push({
      key: SECONDARY_AGGREGATION_SETTING,
      value: ''
    });
  }
  return result;
};
export const getBoardSettingsByPipeline = ({
  objectTypeId,
  pipelineId
}) => {
  return http.post(`${BASE_URL}/${objectTypeId}/${pipelineId}/batchGetSettingsByPipelineId/`, {
    data: [...PIPELINE_BOARD_SETTINGS]
  }).then(toClient).catch(err => {
    //swallow error here
    //we should still show the UI even
    //without customizations
    Raven.captureException(err);
    throw err;
  });
};
export const getBoardSettingsByObject = ({
  objectTypeId
}) => {
  return http.post(`${BASE_URL}/${objectTypeId}/batchGetSettingsByObjectType/`, {
    data: [...PIPELINE_BOARD_SETTINGS]
  }).then(toClient).catch(err => {
    //swallow error here
    //we should still show the UI even
    //without customizations
    Raven.captureException(err);
    throw err;
  });
};
export const putBoardSettingsByPipeline = ({
  objectTypeId,
  pipelineId,
  data
}) => {
  return http.post(`${BASE_URL}/${objectTypeId}/${pipelineId}/batchUpsertSettingsByObjectTypeAndPipelineId/`, {
    data: toServer(data)
  }).then(() => {
    return null;
  }).catch(err => {
    //swallow error here
    //we should still show the UI even
    //without customizations
    Raven.captureException(err);
    throw err;
  });
};
export const putBoardSettingsByObject = ({
  objectTypeId,
  data
}) => {
  return http.post(`${BASE_URL}/${objectTypeId}/batchUpsertSettingsByObjectType/`, {
    data: toServer(data)
  }).then(() => {
    return null;
  }).catch(err => {
    //swallow error here
    //we should still show the UI even
    //without customizations
    Raven.captureException(err);
    throw err;
  });
};