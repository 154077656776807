import { useRef } from 'react';

/**
 * useStableFunction is a hook that returns a stable version of a function.
 * This is useful in a scenario like a search bar where the function passed to the input's onChange prop is debounced,
 * but the onChange handler might not be a stable reference.
 */
export const useStableFunction = fn => {
  const currentFnRef = useRef(fn);
  const stableFnRef = useRef();
  currentFnRef.current = fn;
  if (!stableFnRef.current) {
    stableFnRef.current = (...args) => currentFnRef.current.call(undefined, ...args);
  }
  return stableFnRef.current;
};