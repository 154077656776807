import { useMemo } from 'react';
import { HEADER_MENU_ITEMS, MENU_ITEM_MAP, BODY_MENU_ITEMS } from './constants';
import { useObjectTypeDefinitionContext } from '../../../crmObjects/ObjectTypeDefinitionsContext';
const filterMenuItems = (menuItems, props) => {
  return menuItems.filter(menuItem => menuItem.predicate(props));
};
export const useFilteredMenuItems = ({
  filterProps
}) => {
  const {
    data: objectTypeDef,
    loading
  } = useObjectTypeDefinitionContext(filterProps.columnNamespace);
  return useMemo(() => {
    if (loading) return [];
    if (filterProps.row === null) {
      return filterMenuItems(HEADER_MENU_ITEMS.map(key => MENU_ITEM_MAP[key]), Object.assign({}, filterProps, {
        objectTypeDef
      }));
    }
    return filterMenuItems(BODY_MENU_ITEMS.map(key => MENU_ITEM_MAP[key]), Object.assign({}, filterProps, {
      objectTypeDef
    }));
  }, [filterProps, objectTypeDef, loading]);
};