import { useHasPreferredDisplayCurrency } from '../../../multiCurrency/hooks/useHasPreferredDisplayCurrency';
import { usePreferredDisplayCurrency } from '../../../multiCurrency/hooks/usePreferredDisplayCurrency';
import { useConvertCompanyCurrencyToDisplayCurrency } from '../../../multiCurrency/hooks/usePreferredDisplayCurrencyConverters';
import { useGetProperty } from 'crm-object-board-core/properties';
import { createAmountAggregator } from './amountAggregator';
import { useEffect, useMemo } from 'react';
export const AmountAggregationCreator = ({
  created,
  config,
  index
}) => {
  const getProperty = useGetProperty();
  const hasPreferredCurrency = useHasPreferredDisplayCurrency();
  const preferredCurrencyCode = usePreferredDisplayCurrency();
  const preferredCurrencyConverter = useConvertCompanyCurrencyToDisplayCurrency();
  const currencyProperty = getProperty(config.meta.currencyProperty);
  const currencyCodeProperty = currencyProperty.currencyPropertyName;
  const aggregator = useMemo(() => {
    return createAmountAggregator(config, {
      hasPreferredCurrency,
      preferredCurrencyCode,
      preferredCurrencyConverter,
      currencyCodeProperty
    });
  }, [config, hasPreferredCurrency, preferredCurrencyCode, preferredCurrencyConverter, currencyCodeProperty]);
  useEffect(() => created(index, aggregator), [aggregator, created, index]);
  return null;
};