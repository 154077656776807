/**
 * This is what you should use if you want to control the page size
 *
 * INFO: Upon a change to the query object (filters or sorts or page size) the page number will be reset to the first page
 * If in the future, if/when/possibly we support consumers controlling the page number themsevles, we'll need to think through how to handle this
 *
 * INFO: We have dicussed the possibility of consumers passing in pageNumber and onPageNumberChange. It opens up
 * a possibility for an app to say "I want the 5th page" when there is only 3 pages of results. For now this is not implemented.
 */

export const PAGINATION_CONFIG_TYPES = {
  PAGED_UNCONTROLLED: 'PAGINATION_PAGED_UNCONTROLLED',
  PAGED_CONTROLLED: 'PAGINATION_PAGED_CONTROLLED'
};
export const paginationDefaultConfig = {
  type: PAGINATION_CONFIG_TYPES.PAGED_UNCONTROLLED
};
export function isPaginationPagedControlledConfig(config) {
  return config.type === PAGINATION_CONFIG_TYPES.PAGED_CONTROLLED;
}