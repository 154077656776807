import { registerQuery, useQuery } from 'data-fetching-client';
import { HubsSettingClient } from 'frontend-preferences-client';
import { useMemo } from 'react';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
export const hubsSettingClient = HubsSettingClient.forCaller('crm-object-board');
export const SINGLE_PORTAL_SETTING_QUERY = withCrmObjectBoardNamespace('SINGLE_PORTAL_SETTING_QUERY');
export const SinglePortalSettingQuery = registerQuery({
  fieldName: SINGLE_PORTAL_SETTING_QUERY,
  args: ['settingsKey', 'defaultValue'],
  fetcher: args => hubsSettingClient.fetch(args.settingsKey, args.defaultValue)
});

/**
 * This hook will call frontend-preferences-client behind data-fetching-client.
 *
 * You are required to provide a defaultValue in cases of error or no value found
 *
 * You will never seen an error state, only data or loading
 */
export const useSafePortalSetting = ({
  settingsKey,
  defaultValue
}) => {
  const result = useQuery(SinglePortalSettingQuery, {
    variables: {
      settingsKey,
      defaultValue
    }
  });
  const memoizedValue = useMemo(() => {
    const {
      data,
      loading
    } = result;
    return {
      data: data ? data[SINGLE_PORTAL_SETTING_QUERY] : undefined,
      loading
    };
  }, [result]);
  return memoizedValue;
};