import http from 'hub-http/clients/apiClient';
import { useEffect, useState } from 'react';
import { FetchStatuses } from '../../common/types/FetchStatus';
const MARKETING_EVENT_TYPE_ID = '0-54';
const MARKETING_EVENT_ATTENDANCE_TYPE_ID = '0-147';
export const shouldLinkToAssociation = objectTypeId => {
  return MARKETING_EVENT_ATTENDANCE_TYPE_ID === objectTypeId;
};
export const useAssociatedObjectsRecord = (objectTypeId, objectId) => {
  const [associationRecord, setAssociationRecord] = useState({
    status: FetchStatuses.LOADING,
    data: null
  });
  useEffect(() => {
    const fetchData = () => {
      if (!shouldLinkToAssociation(objectTypeId)) {
        setAssociationRecord({
          status: FetchStatuses.REJECTED,
          data: null,
          error: Error(`objectTypeId=${objectId} is not supported for useAssociatedObjectsRecord hook.`)
        });
        return;
      }
      http.get(`associations/v1/associations/${MARKETING_EVENT_ATTENDANCE_TYPE_ID}/${objectId}/associations-to/${MARKETING_EVENT_TYPE_ID}`).then(associationResponse => {
        const results = associationResponse.results;
        const association = results[results.length - 1];
        setAssociationRecord({
          status: FetchStatuses.RESOLVED,
          data: {
            objectId: association.objectId,
            objectTypeId: MARKETING_EVENT_TYPE_ID
          }
        });
      }).catch(error => {
        setAssociationRecord({
          status: FetchStatuses.REJECTED,
          data: null,
          error
        });
      });
      setAssociationRecord({
        status: FetchStatuses.LOADING,
        data: null
      });
    };
    fetchData();
  }, [objectId, objectTypeId]);
  return associationRecord;
};