import get from 'transmute/get';
import getIn from 'transmute/getIn';
import setIn from 'transmute/setIn';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID } from '../constants/ObjectTypeIds';
/**
 * A util to get the id key that is used to denote the unique id for an object.
 *
 * @param objectTypeId - The objectTypeId to get the id key for
 * @param options
 * useLegacyIdKey
 *   This options meant to be used in cases where the BE does not coerce
 *   the id for an object to the standard objectId. Contacts, companies, and
 *   deals were made before the HubSpot framework was created so they have
 *   special id properties, other objects, including tickets all use the
 *   standard "objectId" id property.
 *
 *   Most modern endpoints will do this automatically (i.e. CrmSearch) but some
 *   legacy endpoints will still return the data in the old format using the
 *   old id keys.
 */
export function getIdKey(objectTypeId, {
  useLegacyIdKey = false
} = {}) {
  if (!useLegacyIdKey) {
    return 'objectId';
  }
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      return 'vid';
    case COMPANY_TYPE_ID:
      return 'companyId';
    case DEAL_TYPE_ID:
      return 'dealId';
    default:
      return 'objectId';
  }
}

/**
 * A util to get the id key that is used to denote the unique id for an object.
 *
 * @param objectTypeId - The objectTypeId of the object
 * @param crmObject - The object to get the key from
 * @param options
 * useLegacyIdKey
 *   This options meant to be used in cases where the BE does not coerce
 *   the id for an object to the standard objectId. Contacts, companies, and
 *   deals were made before the HubSpot framework was created so they have
 *   special id properties, other objects, including tickets all use the
 *   standard "objectId" id property.
 *
 *   Most modern endpoints will do this automatically (i.e. CrmSearch) but some
 *   legacy endpoints will still return the data in the old format using the
 *   old id keys.
 */
export function getId(objectTypeId, crmObject, {
  useLegacyIdKey = false
} = {}) {
  return get(getIdKey(objectTypeId, {
    useLegacyIdKey
  }), crmObject);
}

/**
 * This util gets the value of the PropertyValue (naming is hard) on an object.
 * PropertyValue holds a bunch of metadata about a property's value, this util
 * drops all that metadata and just returns the the primitive value of the
 * property on the object (see the PropertyValue definition for more info).
 *
 * @param crmObject - The object to pull the property from
 * @param propertyName - The name of the property to get the value for
 */
export function getProperty(crmObject, propertyName) {
  if (propertyName === undefined) return undefined;
  return getIn(['properties', propertyName, 'value'], crmObject);
}

/**
 * This util gets the value of the PropertyValue (naming is hard) on an object.
 * PropertyValue holds a bunch of metadata about a property's value and so it
 * is not a primitive value, it contains things like the versions of a property
 * value and the time it was last changed in addition to the primitive value.
 * To get the primitive value directly use the getProperty util.
 *
 * @param crmObject - The object to pull the property from
 * @param propertyName - The name of the property to get the value for
 */
export function getPropertyValue(crmObject, propertyName) {
  if (propertyName === undefined) return undefined;
  return getIn(['properties', propertyName], crmObject);
}

/**
 * This util sets the value of the PropertyValue (naming is hard) on an object.
 *
 * @param crmObject - The object to set the property on
 * @param propertyName - The name of the property to set the value for
 * @param value - The value to set for the property
 */
export function setProperty(crmObject, propertyName, value) {
  if (!crmObject || !propertyName) {
    return crmObject;
  }
  let newObject = setIn(['properties', propertyName, 'value'], value, crmObject);
  // Setting name here for previously unset properties as name is used
  // when API calling code turns this array into a name -> value map.
  newObject = setIn(['properties', propertyName, 'name'], propertyName, newObject);
  return newObject;
}