import memoizeLast from 'transmute/memoizeLast';
import get from 'transmute/get';
import set from 'transmute/set';
import { hydrateFilterGroups } from './hydrateFilterGroupsAPI';
export const hydrateSearchQuery = memoizeLast(query => {
  const filterGroups = get('filterGroups', query);
  if (!Array.isArray(filterGroups) || filterGroups.length <= 0) {
    return Promise.resolve(query);
  }
  return hydrateFilterGroups(filterGroups).then(hydratedGroups => set('filterGroups', hydratedGroups, query));
});