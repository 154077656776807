import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { SUCCEEDED } from '../../constants/RequestStatus';
import { BACKGROUND_REFRESH_INTERVAL_TIME } from '../../crmSearch/constants/cacheTime';
import { fetchReport } from '../api/stageAggregationAPI';
import { useAggregationQuery } from './useAggregationQuery';
const fetchReportQuery = registerQuery({
  fieldName: 'report',
  args: ['query'],
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ query }: $tsMigrateAny) => Promise<JSON>'... Remove this comment to see the full error message
  fetcher: fetchReport
});
export const useAggregationsForPipeline = aggregators => {
  const {
    status,
    query
  } = useAggregationQuery(aggregators);
  const {
    data,
    loading,
    error
  } = useQuery(fetchReportQuery, {
    skip: status !== SUCCEEDED || !aggregators,
    pollInterval: BACKGROUND_REFRESH_INTERVAL_TIME,
    variables: {
      query
    }
  });
  return useMemo(() => {
    if (loading || error || !data) {
      return {
        loading,
        error,
        aggregations: null
      };
    }

    // The data here comes from the crmsearch /report endpoint.
    // Read more: https://product.hubteam.com/docs/crm-search/usage.html#reporting-aggregating
    let aggregations;
    if (aggregators) {
      const report = data.report;
      aggregations = aggregators.handleResult(report.aggregations);
    }
    return {
      loading,
      error,
      aggregations
    };
  }, [data, error, aggregators, loading]);
};