import I18n from 'I18n';
//@ts-expect-error untyped
import CjkCharacterRegExp from 'I18n/constants/regex/CJK';
//@ts-expect-error untyped
import KatakanaCharacterRegExp from 'I18n/constants/regex/Katakana';
import { useCallback, useState } from 'react';
const SEARCH_LENGTH_LIMIT = 200;
const SEARCH_WORD_LIMIT = 10;
const MIN_SEARCH_LENGTH = 3;
const isTooLong = (query = '') => {
  if (!query || typeof query !== 'string') {
    return false;
  }
  const splitQuery = query.split(' ');
  const hasTooManyCharacters = query.length > SEARCH_LENGTH_LIMIT;
  const hasTooManyWords = splitQuery.length > SEARCH_WORD_LIMIT;
  if (hasTooManyCharacters) {
    return {
      type: 'characters',
      limit: SEARCH_LENGTH_LIMIT
    };
  }
  if (hasTooManyWords) {
    return {
      type: 'words',
      limit: SEARCH_WORD_LIMIT
    };
  }
  return false;
};
const isTooShort = str => {
  const rawLengthLongEnough = str.length >= MIN_SEARCH_LENGTH;
  const containsCjkCharacter = CjkCharacterRegExp.test(str);
  const containsKatakanaCharacter = KatakanaCharacterRegExp.test(str);
  return !(rawLengthLongEnough || containsCjkCharacter || containsKatakanaCharacter);
};
export const getValidationMessage = value => {
  if (!value) {
    return null;
  }
  if (isTooShort(value)) {
    return I18n.text('crmObjectTable.searchBar.minimumQueryStringLength', {
      count: MIN_SEARCH_LENGTH - value.length
    });
  }
  const {
    limit,
    type
  } = isTooLong(value) || {};
  if (type && limit) {
    return I18n.text('crmObjectTable.searchBar.maximumQueryStringLength', {
      limit,
      type
    });
  }
  return null;
};
export const useValidatedSearchString = ({
  value,
  onChange
}) => {
  const [unvalidatedValue, setUnvalidatedValue] = useState(value);
  const onChangeValidated = useCallback(newValue => {
    setUnvalidatedValue(newValue);
    const isNewValueInvalid = getValidationMessage(newValue);
    if (!isNewValueInvalid) {
      onChange(newValue);
    }
    return;
  }, [onChange]);
  return [unvalidatedValue, onChangeValidated];
};