import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
import userInfo, { userInfoSync } from 'hub-http/userInfo';
export const useFetchUserInfo = () => {
  const [state, setState] = useState(() => {
    // initialize with userInfoSync in case userInfo has already been loaded.
    // this avoids an initial render without gates/scopes, if possible.
    // userInfoSync throws an Error if userInfo has not finished loading yet;
    // in that case fall back to waiting for async userInfo
    try {
      return {
        data: userInfoSync(),
        loading: false,
        error: undefined
      };
    } catch (__error) {
      devLogger.warn({
        message: `crm-object-table-useFetchUserInfo: Failure reading userinfo sync`,
        key: 'crm-object-table-useFetchUserInfo'
      });
    }
    return {
      data: undefined,
      loading: true,
      error: undefined
    };
  });
  useEffect(() => {
    if (state.data || state.error) {
      return;
    }
    try {
      // ignoreRedirect is important! Without it, any consumers of that don't
      // support or shim userInfo can end up redirecting (the default behavior).
      // This has caused past critsits:
      // https://git.hubteam.com/HubSpot/Critsit/issues/3557
      // https://git.hubteam.com/HubSpot/Critsit/issues/1040
      userInfo({
        ignoreRedirect: true
      }).then(response => {
        setState({
          data: response,
          loading: false,
          error: undefined
        });
      }).catch(err => {
        setState({
          data: undefined,
          loading: false,
          error: err
        });
        devLogger.warn({
          message: `crm-object-table-useFetchUserInfo: API failure fetching userinfo`,
          key: 'crm-object-table-useFetchUserInfo'
        });
      });
    } catch (err) {
      setState({
        data: undefined,
        loading: false,
        error: err
      });
      devLogger.warn({
        message: `crm-object-table-useFetchUserInfo: Failure fetching userinfo`,
        key: 'crm-object-table-useFetchUserInfo'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
};