import { useEditBoardSettingsPanelContext } from 'crm-pipeline-board-settings-lib/EditBoardSettingsPanel';
import Raven from 'raven-js';
import { getColumnByName, getIsLastActivityDateInactive, isColumnConsideredComplete } from '../utils';
import { useProps } from '../../props/useProps';
import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { useBoardSettings } from 'crm-object-board-core/frameworkAppSettings';
import { usePipeline } from 'crm-object-board-core/pipeline';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
export const useIsRecordInactive = ({
  crmObject
}) => {
  const selectedCardPreferences = useEditBoardSettingsPanelContext();
  const {
    inactiveCardPreferences: inactiveCardsStateSlice
  } = selectedCardPreferences.settings;
  const {
    objectTypeId
  } = useProps();
  const pipeline = usePipeline();
  const typeDef = useObjectTypeDefinition();
  const boardSettings = useBoardSettings();
  if (!typeDef || !boardSettings) {
    return false;
  }
  const {
    pipelinePropertyName,
    pipelineStagePropertyName
  } = typeDef;
  if (!pipelinePropertyName || !pipelineStagePropertyName) {
    return false;
  }
  const inactiveLimitIsTurnedOn = !inactiveCardsStateSlice.isTurnedOff;
  const inactiveLimitValue = inactiveCardsStateSlice.value;
  const inactiveLimitUnit = inactiveCardsStateSlice.unit;
  const inactivePropertyKey = boardSettings && boardSettings.cardInactivity && boardSettings.cardInactivity.value.property;
  const pipelineId = getProperty(crmObject, pipelinePropertyName);
  const stageId = getProperty(crmObject, pipelineStagePropertyName);
  if (!objectTypeId || !inactiveLimitIsTurnedOn || !inactivePropertyKey || !pipeline || !stageId || !pipelineId || !inactiveLimitValue || !inactiveLimitUnit) {
    return false;
  }
  const columns = pipeline.stages;
  const currentColumn = getColumnByName({
    columns,
    columnKey: 'stageId',
    columnValue: stageId
  });
  if (!currentColumn) {
    Raven.captureMessage(`[useInactiveCardData]: unable to find column for card`, {
      extra: {
        objectTypeId,
        subjectId: crmObject.objectId,
        pipelineId,
        stageId
      }
    });
    return false;
  }
  const isInCompletedStage = isColumnConsideredComplete(currentColumn);
  if (isInCompletedStage) {
    return false;
  }
  const lastUpdatedDate = getProperty(crmObject, inactivePropertyKey);
  const createDate = getProperty(crmObject, 'createdate');

  // see when the user last interacted with the card - if no interaction ever, use the day it was made
  const lastActivityDate = lastUpdatedDate || createDate;
  const lastActivityDateEpoch = lastActivityDate ? +lastActivityDate : undefined;
  return getIsLastActivityDateInactive(lastActivityDateEpoch, inactiveLimitValue, inactiveLimitUnit);
};