import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { useMemo } from 'react';
import { useTagPropertyName } from 'crm-object-board-core/tags';
import { useHasTags } from 'crm-object-board-core/user';
import { useGetProperty } from 'crm-object-board-core/properties';
export const useTagPropertyWithValue = ({
  crmObject
}) => {
  const getPropertyDef = useGetProperty();
  const hasTags = useHasTags();
  const tagPropertyName = useTagPropertyName();
  return useMemo(() => {
    if (!hasTags || !tagPropertyName) {
      return undefined;
    }
    const tagProperty = getPropertyDef(tagPropertyName);
    const tagIds = getProperty(crmObject, tagPropertyName);
    if (!tagProperty || !tagIds) {
      return undefined;
    }
    return {
      definition: tagProperty,
      value: tagIds
    };
  }, [getPropertyDef, tagPropertyName, hasTags, crmObject]);
};