import { getTourIdFromQueryParams } from './urlUtils';
import { getLinkedTourId } from './linkedTourUtils';
export const isTourCurrentlyActive = (tourId = '') => {
  const activeTourSelector = `body.onboarding-tour--active${tourId ? ` [data-tour-id="${tourId}"]` : ''}`;
  return document.querySelector(activeTourSelector) !== null;
};
export const shouldRenderTour = tourId => {
  const tourIdFromQuery = getTourIdFromQueryParams();
  const linkedTourId = getLinkedTourId();

  // enable only for specific tour ID(s)
  if (tourId) {
    if (typeof tourId === 'string') {
      return tourIdFromQuery === tourId || linkedTourId === tourId;
    } else {
      return !!(tourIdFromQuery && tourId.includes(tourIdFromQuery)) || !!(linkedTourId && tourId.includes(linkedTourId));
    }
  }
  return !!(tourIdFromQuery || linkedTourId);
};