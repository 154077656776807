import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { useEffect, useMemo } from 'react';
import { useApplyMutations } from './useApplyMutations';
import { useSetColumnsUpdated } from './useSetColumnsUpdated';
import { useAddedItems } from './useAddedItems';
export const useReconciliation = data => {
  const typeDef = useObjectTypeDefinition();
  const applyMutations = useApplyMutations();
  const locallyAddedItems = useAddedItems();
  const setColumnsUpdated = useSetColumnsUpdated();
  const reconciledResult = useMemo(() => {
    if (!data || !typeDef) {
      return {
        reconciledData: data,
        updatedColumns: new Set()
      };
    }

    //Maps of columnIds and the array contains items that
    //need to be moved there. We don't combine these right now
    //as actively approved is put ahead of passively currently
    //dont want to deviate too far from original
    const activelyUpdateColumnMap = new Map();
    const passivelyUpdateColumnMap = new Map();
    //generate result set with anything that needs to be moved removed
    const reconciledDataRemoved = data.map(result => {
      //filter out items that move column
      const reconciledItemsRemoved = result.data.reduce((reconciled, obj) => {
        const objColumnId = obj.data.properties[typeDef.pipelineStagePropertyName].value;
        const appliedResult = applyMutations(obj);

        //if obj is deleted just return we dont need to move it
        //just filter it out
        if (appliedResult === undefined) {
          return reconciled;
        }
        const appliedColumnId = appliedResult.data.properties[typeDef.pipelineStagePropertyName].value;

        //after mutation application columns don't match
        //this is active update then.
        if (appliedColumnId && appliedColumnId !== objColumnId) {
          updateColumnMap(appliedColumnId, activelyUpdateColumnMap, appliedResult);
          return reconciled; //done with processing
        }

        //property is saying a different column
        if (!!objColumnId && objColumnId !== result.columnId) {
          updateColumnMap(objColumnId, passivelyUpdateColumnMap, appliedResult);
          return reconciled; //should not be in this column
        }

        //apply any local mutations
        reconciled.push(appliedResult);
        //keep the obj
        return reconciled;
      }, []);
      return Object.assign({}, result, {
        data: reconciledItemsRemoved
      });
    });

    //update columns that have new values
    //used in footer to show interdetminate logo
    //if aggregations have failed
    const updatedColumns = new Set([...activelyUpdateColumnMap.keys(), ...passivelyUpdateColumnMap.keys()]);
    // setColumnsUpdated(updatedColumns);

    //add the reconciled data back in for the laugh
    //i mean add them back into the right columns
    //update the total
    const reconciledData = reconciledDataRemoved.map(result => {
      const originalData = data.find(x => x.columnId === result.columnId);
      const columnId = result.columnId;
      const newlyAdded = locallyAddedItems[columnId] || [];
      const activeItems = activelyUpdateColumnMap.get(columnId) || [];
      const passiveItems = passivelyUpdateColumnMap.get(columnId) || [];
      const newData = [...newlyAdded, ...activeItems, ...passiveItems, ...result.data];
      const total = result.total + (newData.length - (originalData ? originalData.data.length : 0));
      return Object.assign({}, result, {
        data: newData,
        total
      });
    });
    return {
      reconciledData,
      updatedColumns
    };
  }, [data, typeDef, applyMutations, locallyAddedItems]);
  //update columns
  useEffect(() => {
    setColumnsUpdated(reconciledResult.updatedColumns);
  }, [setColumnsUpdated, reconciledResult]);
  return reconciledResult.reconciledData;
};

/**
 * Updates map to contain Obj in correct Column
 */
function updateColumnMap(columnId, columnMap, obj) {
  const currentForColumn = columnMap.get(columnId) || [];
  currentForColumn.push(obj);
  columnMap.set(columnId, currentForColumn);
}