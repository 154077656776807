import { AddColumnMenu } from '../components/AddColumnMenu';
import { getRequiredFirstPropertyName } from './getRequiredFirstPropertyName';
export const MENU_ITEM_KEYS = {
  ADD_COLUMN: 'ADD_COLUMN',
  REMOVE_COLUMN: 'REMOVE_COLUMN'
};
export const MENU_ITEM_MAP = {
  ADD_COLUMN: {
    key: MENU_ITEM_KEYS.ADD_COLUMN,
    predicate: ({
      row,
      config
    }) => row === null && config.onSetColumns != null,
    message: 'crmObjectTable.contextMenu.menuItems.addColumn',
    getOnClick: ({
      onSetComponent,
      config: {
        trackContextMenuActions
      }
    }) => () => {
      trackContextMenuActions === null || trackContextMenuActions === void 0 || trackContextMenuActions('ADD_COLUMN');
      onSetComponent(AddColumnMenu);
    },
    icon: 'add',
    showRightArrow: true
  },
  REMOVE_COLUMN: {
    key: MENU_ITEM_KEYS.REMOVE_COLUMN,
    predicate: ({
      row,
      columnName,
      config,
      objectTypeDef
    }) => row === null && config.onSetColumns != null && objectTypeDef != null && getRequiredFirstPropertyName(objectTypeDef) !== columnName,
    message: 'crmObjectTable.contextMenu.menuItems.removeColumn',
    getOnClick: ({
      config,
      columnName,
      columnPropertyNames,
      closeContextMenu
    }) => () => {
      var _config$trackContextM, _config$onSetColumns;
      (_config$trackContextM = config.trackContextMenuActions) === null || _config$trackContextM === void 0 || _config$trackContextM.call(config, 'REMOVE_COLUMN');
      (_config$onSetColumns = config.onSetColumns) === null || _config$onSetColumns === void 0 || _config$onSetColumns.call(config, columnPropertyNames.filter(name => name !== columnName));
      closeContextMenu();
    },
    icon: 'delete',
    showRightArrow: false
  }
};
export const HEADER_MENU_ITEMS = [MENU_ITEM_KEYS.ADD_COLUMN, MENU_ITEM_KEYS.REMOVE_COLUMN];

// this will be populated in the future
export const BODY_MENU_ITEMS = [];