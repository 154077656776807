'use es6';

import { AssociationsBatchRequestClient } from './AssociationsBatchRequestClient';
import { CrmObjectPreviewBatchRequestClient } from './CrmObjectPreviewBatchRequestClient';
import allSettled from 'hs-promise-utils/allSettled';
export const AssociationsPreviewClient = {
  fetch: ({
    objectId,
    associationDefinition: {
      associationCategory,
      associationTypeId,
      toObjectTypeId
    }
  }) => AssociationsBatchRequestClient.fetch({
    associationCategory,
    associationTypeId,
    objectId
  }).then(associatedObjectIds => allSettled(associatedObjectIds.results.map(associatedObjectId => CrmObjectPreviewBatchRequestClient.fetch({
    objectTypeId: toObjectTypeId,
    objectId: associatedObjectId
  })))).then(results => results.filter(({
    status
  }) => status === 'fulfilled').map(({
    value
  }) => value))
};