const BOARD_MOUNT = 'BOARD_MOUNT';
const BOARD_LOADED = 'BOARD_LOADED';
const BOARD_CARD_LOADED = 'BOARD_CARD_LOADED';
const BOARD_FOOTER_LOADED = 'BOARD_FOOTER_LOADED';
const CRM_SEARCH_START = 'CRM_SEARCH_START';
const CRM_SEARCH_COMPLETE = 'CRM_SEARCH_COMPLETE';
const FULL_COMPONENT_CRASH = 'FULL_COMPONENT_CRASH';
export const BOARD_MOUNT_MARK = {
  name: BOARD_MOUNT
};
export const BOARD_LOADED_MARK = {
  name: BOARD_LOADED
};
export const BOARD_CARD_LOADED_MARK = {
  name: BOARD_CARD_LOADED
};
export const BOARD_FOOTER_LOADED_MARK = {
  name: BOARD_FOOTER_LOADED
};
export const CRM_SEARCH_START_MARK = {
  name: CRM_SEARCH_START
};
export const CRM_SEARCH_COMPLETE_MARK = {
  name: CRM_SEARCH_COMPLETE
};
export const FULL_COMPONENT_CRASH_MARK = {
  name: FULL_COMPONENT_CRASH
};