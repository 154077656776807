import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import memoizeOne from 'react-utils/memoizeOne';
import { useMemo } from 'react';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { registerQuery, useQuery } from 'data-fetching-client';
import { CONTACT_NAME_PROPERTY } from '../properties/CONTACT_NAME_PROPERTY';
const OBJECT_TYPE_DEFINITIONS_QUERY_NAME = 'crmObjectTableObjectTypeDefinitions';
const parseObjectTypeDef = memoizeOne(allObjectTypes => allObjectTypes ? allObjectTypes.reduce((objectTypes, typeDef) => {
  const objectTypeId = typeDef.objectTypeId;
  const isContact = objectTypeId === CONTACT_TYPE_ID;
  const primaryDisplayLabelPropertyName = isContact ? CONTACT_NAME_PROPERTY : typeDef.primaryDisplayLabelPropertyName;
  return objectTypes.set(objectTypeId, Object.assign({}, typeDef, {
    primaryDisplayLabelPropertyName,
    restorable: typeDef.restorable
  }));
}, new Map()) : new Map());
export const ObjectTypeDefinitionsQuery = registerQuery({
  fieldName: OBJECT_TYPE_DEFINITIONS_QUERY_NAME,
  fetcher: () => getFrameworkDataSchemasClient().then(client => client.frameworkTypes.getObjects()).then(objectTypes => {
    return parseObjectTypeDef(objectTypes);
  })
});
export const useObjectTypeDefinitions = () => {
  const {
    data,
    loading,
    error
  } = useQuery(ObjectTypeDefinitionsQuery, {});
  const objectTypes = data && data[OBJECT_TYPE_DEFINITIONS_QUERY_NAME];
  return useMemo(() => {
    return {
      data: objectTypes,
      loading,
      error
    };
  }, [objectTypes, loading, error]);
};