import { DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
export const getFlowUuid = (objectType, pipelineId, stageId) => {
  if (!pipelineId || !stageId) {
    return null;
  }
  if (objectType === DEAL) {
    return `deal-${pipelineId}-${stageId}`;
  }
  if (objectType === TICKET) {
    return `ticket-${pipelineId}-${stageId}`;
  }
  return null;
};
const isNonEmpty = flowUuids => {
  return !flowUuids.includes(null);
};
export const getFlowUuids = (objectType, pipelineId, stageIds) => {
  const flowUuids = stageIds.map(stageId => getFlowUuid(objectType, pipelineId, stageId));
  return isNonEmpty(flowUuids) ? flowUuids : null;
};