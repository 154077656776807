import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, NOTE_TYPE_ID, TASK_TYPE_ID, EMAIL_TYPE_ID, CALL_TYPE_ID, LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const DEFAULT_COLUMN_PROPERTIES = {
  [CONTACT_TYPE_ID]: {
    columns: ['firstname', 'email', 'phone', 'hubspot_owner_id', 'lastmodifieddate', 'lead_status', 'hs_marketable_status', 'createdate', 'hs_last_sales_activity_timestamp'],
    filterProperties: ['hubspot_owner_id', 'closedate', 'createdate', 'hs_lead_status']
  },
  [COMPANY_TYPE_ID]: {
    columns: ['name', 'domain', 'hubspot_owner_id', 'createdate', 'phone', 'city', 'industry', 'hs_last_sales_activity_timestamp'],
    filterProperties: ['hubspot_owner_id', 'createdate', 'hs_lead_status', 'notes_last_updated']
  },
  [DEAL_TYPE_ID]: {
    columns: ['dealname', 'pipeline', 'dealstage', 'closedate', 'amount', 'hubspot_owner_id', 'notes_last_updated'],
    filterProperties: ['dealstage', 'hubspot_owner_id', 'closedate', 'createdate']
  },
  [TICKET_TYPE_ID]: {
    columns: ['subject', 'hs_pipeline', 'hs_pipeline_stage', 'createdate', 'priority', 'hubspot_owner_id', 'content', 'lastreplydate'],
    filterProperties: ['hubspot_owner_id', 'createdate', 'hs_lastactivitydate', 'hs_ticket_priority']
  },
  [NOTE_TYPE_ID]: {
    columns: ['hs_body_preview', 'createdate', 'hubspot_owner_id'],
    filterProperties: ['hubspot_owner_id', 'createdate']
  },
  [TASK_TYPE_ID]: {
    columns: ['hs_task_subject', 'hs_task_body', 'hs_timestamp'],
    filterProperties: ['hubspot_owner_id', 'hs_task_type', 'hs_timestamp', 'hs_queue_membership_ids']
  },
  [EMAIL_TYPE_ID]: {
    columns: ['hs_email_subject', 'hs_email_html', 'hs_email_sender_email', 'hs_email_to_email', 'hs_created_by_user_id', 'hs_email_direction', 'hs_createdate'],
    filterProperties: ['hs_email_subject']
  },
  [CALL_TYPE_ID]: {
    columns: ['hs_call_title', 'hs_body_preview', 'hs_timestamp', 'hs_owner_id', 'hs_call_status', 'hs_call_disposition'],
    filterProperties: ['hs_call_has_transcript', 'hubspot_owner_id', 'hs_timestamp', 'hs_call_duration']
  },
  [LEAD_TYPE_ID]: {
    columns: ['hs_lead_name', 'hubspot_owner_id', 'hs_lead_type', 'hs_associated_company_name', 'hs_pipeline_stage', 'hs_lead_source', 'hs_tag_ids'],
    filterProperties: ['hs_pipeline_stage', 'hs_lead_type', 'hs_lead_source', 'hs_pipeline']
  }
};
export const DEFAULT_COBJECT_PROPERTIES = {
  columns: ['hs_object_id'],
  filterProperties: ['hubspot_owner_id', 'closedate', 'createdate'],
  sorts: [],
  filters: []
};
export function getNestedAssociationTableDefaultProperty(toObjectTypeId) {
  var _DEFAULT_COLUMN_PROPE;
  return (_DEFAULT_COLUMN_PROPE = DEFAULT_COLUMN_PROPERTIES[toObjectTypeId]) !== null && _DEFAULT_COLUMN_PROPE !== void 0 ? _DEFAULT_COLUMN_PROPE : DEFAULT_COBJECT_PROPERTIES;
}