import { useHasMulticurrencyActive } from './useFetchPortalCurrencies';
import { useGetHasScope } from 'crm-object-board-core/user';
const PreferredDisplayCurrencyScope = 'crm-pipelines-preferred-currency-access';
export const useHasPreferredDisplayCurrencyScope = () => {
  const {
    hasScope,
    loading
  } = useGetHasScope();
  if (loading) {
    return false;
  }
  return hasScope(PreferredDisplayCurrencyScope);
};
export const useHasPreferredDisplayCurrency = () => {
  const hasMulticurrencyActive = useHasMulticurrencyActive();
  const hasScope = useHasPreferredDisplayCurrencyScope();
  return hasMulticurrencyActive && hasScope;
};