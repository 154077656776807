import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import formatName from 'I18n/utils/formatName';
import I18n from 'I18n';
/**
 * Helper class with a collection of methods to assist with association rendering
 */
export class AssociationHelper {
  constructor(definition) {
    this.definition = definition;
  }

  /**
   * Attempts to extract the primary display label value from an object. Handles custom case for Contact type
   *
   * @param object The object to extract the primary display label value from
   * @returns The primary display label value of the object, or undefined if it cannot be found
   */
  tryGetPrimaryDisplayLabelValue(object) {
    var _object$properties4;
    if (this.definition.toObjectTypeId === CONTACT_TYPE_ID) {
      var _object$properties, _object$properties2, _object$properties3;
      return formatName({
        firstName: (_object$properties = object.properties) === null || _object$properties === void 0 || (_object$properties = _object$properties.firstname) === null || _object$properties === void 0 ? void 0 : _object$properties.value,
        lastName: (_object$properties2 = object.properties) === null || _object$properties2 === void 0 || (_object$properties2 = _object$properties2.lastname) === null || _object$properties2 === void 0 ? void 0 : _object$properties2.value,
        email: (_object$properties3 = object.properties) === null || _object$properties3 === void 0 || (_object$properties3 = _object$properties3.email) === null || _object$properties3 === void 0 ? void 0 : _object$properties3.value
      });
    }
    const property = (_object$properties4 = object.properties) === null || _object$properties4 === void 0 ? void 0 : _object$properties4[this.definition.toObjectTypeDefinition.primaryDisplayLabelPropertyName];
    return property === null || property === void 0 ? void 0 : property.value;
  }

  /**
   * Attempts to extract the timestamp from an object and format it with timezone information
   * Format by default is 'lll z'
   *
   * @param object The object to extract the timestamp from
   * @returns The formatted timestamp with timezone information, or undefined if it cannot be found
   */
  tryGetObjectTimestamp(object, format = 'lll z') {
    var _object$state;
    return (object === null || object === void 0 || (_object$state = object.state) === null || _object$state === void 0 ? void 0 : _object$state.timestamp) && I18n.moment.userTz(object.state.timestamp).format(format);
  }
}