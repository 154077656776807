import { useCallback } from 'react';
import { setViewColumnWidths } from '../slice/columnWidthsSlice';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { useDispatch } from 'react-redux';
export const useColumnWidthsActions = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();
  const dispatch = useDispatch();
  const setColumnWidth = useCallback((columnName, width) => dispatch(setViewColumnWidths({
    objectTypeId,
    viewId,
    columnName,
    width
  })), [dispatch, objectTypeId, viewId]);
  return {
    setColumnWidth
  };
};