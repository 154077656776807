import { useFetchPortalCurrencies } from './useFetchPortalCurrencies';
import { useIsPortalCurrency } from './useIsPortalCurrency';
import { useEditBoardSettingsPanelContext } from 'crm-pipeline-board-settings-lib/EditBoardSettingsPanel';
import { useMemo } from 'react';
import { useHasPreferredDisplayCurrency } from './useHasPreferredDisplayCurrency';
import { isCurrencyCode } from '../utils/isCurrencyCode';
import { useFetchPreferredCurrency } from './useFetchPreferredCurrency';
export const usePreferredDisplayCurrency = () => {
  const {
    settings: {
      preferredDisplayCurrencyPreferences: {
        preferredDisplayCurrency
      }
    }
  } = useEditBoardSettingsPanelContext();
  const {
    preferredCurrency
  } = useFetchPreferredCurrency();
  const {
    homeCurrencyCode
  } = useFetchPortalCurrencies();
  const {
    isPortalCurrency
  } = useIsPortalCurrency();
  const canAccessPreferredCurrency = useHasPreferredDisplayCurrency();
  return useMemo(() => {
    if (!canAccessPreferredCurrency) {
      return homeCurrencyCode;
    }
    if (isCurrencyCode(preferredDisplayCurrency)) {
      return preferredDisplayCurrency;
    }
    if (isCurrencyCode(preferredCurrency) && isPortalCurrency(preferredCurrency)) {
      return preferredCurrency;
    }
    return homeCurrencyCode;
  }, [canAccessPreferredCurrency, homeCurrencyCode, isPortalCurrency, preferredCurrency, preferredDisplayCurrency]);
};