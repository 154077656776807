import { useMemo } from 'react';
import { usePipeline } from 'crm-object-board-core/pipeline';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
export const useColumnType = subject => {
  const typeDef = useObjectTypeDefinition();
  const pipeline = usePipeline();
  const pipelineStagePropertyName = useMemo(() => {
    return typeDef ? typeDef.pipelineStagePropertyName : null;
  }, [typeDef]);
  const currentColumn = useMemo(() => {
    if (pipelineStagePropertyName === null) {
      return undefined;
    }
    const stageId = subject.properties[pipelineStagePropertyName] ? subject.properties[pipelineStagePropertyName].value : undefined;
    if (stageId === undefined) {
      return undefined;
    }
    const columns = pipeline ? pipeline.stages : [];
    return columns.find(column => column.stageId === stageId);
  }, [pipeline, subject, pipelineStagePropertyName]);
  return currentColumn && currentColumn.isApprovalStage ? 'approval' : undefined;
};