import { usePropertiesContext } from './PropertiesContext';
export const useProperty = ({
  columnName,
  objectTypeId
}) => {
  var _result$properties;
  const result = usePropertiesContext();
  return Object.assign({}, result, {
    property: (result === null || result === void 0 || (_result$properties = result.properties) === null || _result$properties === void 0 || (_result$properties = _result$properties[objectTypeId]) === null || _result$properties === void 0 ? void 0 : _result$properties[columnName]) || undefined
  });
};