export const ASSOCIATED_COMPANY_ID_PROPERTY = 'associatedcompanyid';
export const CONTACT_TO_PRIMARY_COMPANY_ASSOCIATION_ID = '0-1';
export const PRIMARY_COMPANY_TO_CONTACT_ASSOCIATION_ID = '0-2';
export const GENERAL_CONTACT_TO_DEAL_ASSOCIATION_ID = '0-4';
export const GENERAL_CONTACT_TO_TICKET_ASSOCIATION_ID = '0-15';
export const PRIMARY_COMPANY_TO_DEAL_ASSOCIATION_ID = '0-6';
export const PRIMARY_COMPANY_TO_TICKET_ASSOCIATION_ID = '0-25';
export const GENERAL_DEAL_TO_CONTACT_ASSOCIATION_ID = '0-3';
export const DEAL_TO_PRIMARY_COMPANY_ASSOCIATION_ID = '0-5';
export const GENERAL_DEAL_TO_TICKET_ASSOCIATION_ID = '0-27';
export const GENERAL_DEAL_TO_ENGAGEMENT_ASSOCIATION_ID = '0-11';
export const GENERAL_TICKET_TO_ENGAGEMENT_ASSOCIATION_ID = '0-17';
export const GENERAL_TICKET_TO_CONTACT_ASSOCIATION_ID = '0-16';
export const TICKET_TO_PRIMARY_COMPANY_ASSOCIATION_ID = '0-26';
export const GENERAL_TICKET_TO_DEAL_ASSOCIATION_ID = '0-28';
export const CONTACT_TO_SUBSCRIPTION_ASSOCIATION_ID = '0-296';
export const COMPANY_TO_SUBSCRIPTION_ASSOCIATION_ID = '0-298';
export const DEAL_TO_SUBSCRIPTION_ASSOCIATION_ID = '0-300';
export const COMMERCE_PAYMENT_TO_CONTACT_ASSOCIATION_ID = '0-387';
export const CONTACT_TO_COMMERCE_PAYMENT_ASSOCIATION_ID = '0-388';
export const COMMERCE_PAYMENT_TO_COMPANY_ASSOCIATION_ID = '0-389';
export const COMPANY_TO_COMMERCE_PAYMENT_ASSOCIATION_ID = '0-390';
export const COMMERCE_PAYMENT_TO_DEAL_ASSOCIATION_ID = '0-391';
export const DEAL_TO_COMMERCE_PAYMENT_ASSOCIATON_ID = '0-392';
export const COMMERCE_PAYMENT_TO_SUBSCRIPTION_ASSOCIATION_ID = '0-393';
export const COMMERCE_PAYMENT_TO_LINE_ITEM_ASSOCIATION_ID = '0-395';
export const COMMERCE_PAYMENT_TO_QUOTE_ASSOCIATION_ID = '0-397';
export const INVOICE_TO_CONTACT_ASSOCIATION_ID = '0-177';
export const INVOICE_TO_COMPANY_ASSOCIATION_ID = '0-179';
export const INVOICE_TO_DEAL_ASSOCIATION_ID = '0-175';
export const DEAL_TO_LINE_ITEM_ASSOCIATION_ID = '0-19';
export const ORDER_TO_CONTACT_ASSOCIATION_ID = '0-507';
export const ORDER_TO_COMPANY_ASSOCIATION_ID = '0-509';
export const ORDER_TO_DEAL_ASSOCIATION_ID = '0-512';
export const ORDER_TO_LINE_ITEM_ASSOCIATION_ID = '0-513';
export const ORDER_TO_SUBSCRIPTION_ASSOCIATION_ID = '0-516';
export const ORDER_TO_INVOICE_ASSOCIATION_ID = '0-518';
export const ORDER_TO_DISCOUNT_ASSOCIATION_ID = '0-519';
export const ORDER_TO_COMMERCE_PAYMENT_ASSOCIATON_ID = '0-523';
export const ORDER_TO_TICKET_ASSOCIATON_ID = '0-525';
export const ORDER_TO_CART_ASSOCIATION_ID = '0-593';
export const CART_TO_CONTACT_ASSOCIATION_ID = '0-586';
export const CART_TO_DISCOUNT_ASSOCIATION_ID = '0-588';
export const CART_TO_LINE_ITEM_ASSOCIATION_ID = '0-590';
export const CART_TO_ORDER_ASSOCIATION_ID = '0-592';
export const CART_TO_TICKET_ASSOCIATION_ID = '0-594';

// Associations that are gated behind the flexible-associations gate
export const GENERAL_CONTACT_TO_COMPANY_ASSOCIATION_ID = '0-279';
export const GENERAL_COMPANY_TO_CONTACT_ASSOCIATION_ID = '0-280';
export const GENERAL_COMPANY_TO_DEAL_ASSOCIATION_ID = '0-342';
export const GENERAL_COMPANY_TO_TICKET_ASSOCIATION_ID = '0-340';
export const GENERAL_DEAL_TO_COMPANY_ASSOCIATION_ID = '0-341';
export const GENERAL_TICKET_TO_COMPANY_ASSOCIATION_ID = '0-339';

// Associations that are gated behind the call-associations gate
export const GENERAL_CALL_TO_CONTACT_ASSOCIATION_ID = '0-194';
export const GENERAL_CALL_TO_COMPANY_ASSOCIATION_ID = '0-182';
export const GENERAL_CALL_TO_DEAL_ASSOCIATION_ID = '0-206';
export const GENERAL_CALL_TO_TICKET_ASSOCIATION_ID = '0-220';