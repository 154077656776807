import { getCurrentObjectTypeId } from '../../init/selectors/routerStateSelectors';
import { createFrozenSelector } from '../../utils/createFrozenSelector';
import get from 'transmute/get';
import set from 'transmute/set';
import update from 'transmute/update';
const getLocalMutationsSlice = state => state.localCrmObjectMutations;
export const getLocallyCreatedObjectIds = createFrozenSelector([getLocalMutationsSlice], slice => slice.createdObjectIds);
export const getLocallyDeletedObjectIds = createFrozenSelector([getLocalMutationsSlice], slice => slice.deletedObjectIds);
export const getUpdatedObjectIdsAndDeltas = createFrozenSelector([getLocalMutationsSlice], slice => slice.updatedObjectIdsAndDeltas);
export const getFilterQueryMutations = createFrozenSelector([getLocalMutationsSlice], slice => slice.filterQueryMutations);
export const getReconciledPipelineableObjects = createFrozenSelector([getLocalMutationsSlice], slice => slice.reconciledPipelineableObjects);
export const getLocallyCreatedObjectIdsForCurrentType = createFrozenSelector([getLocallyCreatedObjectIds, getCurrentObjectTypeId], (idMap, objectTypeId) => get(objectTypeId, idMap) || []);
export const getLocallyDeletedObjectIdsForCurrentType = createFrozenSelector([getLocallyDeletedObjectIds, getCurrentObjectTypeId], (idMap, objectTypeId) => get(objectTypeId, idMap) || []);
export const getLocallyUpdatedObjectsForCurrentType = createFrozenSelector([getUpdatedObjectIdsAndDeltas, getCurrentObjectTypeId], (updatedObjects, objectTypeId) => get(objectTypeId, updatedObjects) || {});
export const getLocallyUpdatedFilterQueryMutationsForCurrentType = createFrozenSelector([getFilterQueryMutations, getCurrentObjectTypeId], (filterQueryMutations, objectTypeId) => get(objectTypeId, filterQueryMutations) || {});
export const getReconciledPipelineableObjectDataForCurrentType = createFrozenSelector([getReconciledPipelineableObjects, getCurrentObjectTypeId], (objects, objectTypeId) => get(objectTypeId, objects) || {});
export const getReconciledPipelineableObjectIdsToStageIds = createFrozenSelector([getReconciledPipelineableObjectDataForCurrentType], objects => Object.entries(objects).reduce(
// @ts-expect-error ts-migrate(2339) FIXME: Property 'toStageId' does not exist on type 'unkno... Remove this comment to see the full error message
(acc, [objectId, {
  toStageId
}]) => set(objectId, toStageId, acc), {}));
export const getReconciledPipelineableObjectIdsByStageId = createFrozenSelector([getReconciledPipelineableObjectDataForCurrentType], reconciledObjectData => {
  const mappedByStage = Object.entries(reconciledObjectData).reduce(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'toStageId' does not exist on type 'unkno... Remove this comment to see the full error message
  (stageToObjectDataMap, [objectId, {
    toStageId,
    timestamp
  }]) => update(toStageId,
  // @ts-expect-error ts-migrate(2488) FIXME: Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
  (objects = []) => [...objects, {
    timestamp,
    objectId
  }], stageToObjectDataMap), {});
  return Object.entries(mappedByStage).reduce((stageIdsToSortedObjectIds, [stageId, objectDataArray]) => set(stageId, objectDataArray.sort(
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'timestampA' implicitly has an 'an... Remove this comment to see the full error message
  ({
    timestamp: timestampA
  }, {
    timestamp: timestampB
  }) => timestampB - timestampA).map(({
    objectId
  }) => objectId), stageIdsToSortedObjectIds), {});
});