import { CREATED_CRM_OBJECT, DELETE_CRM_OBJECTS_SUCCEEDED, UPDATE_CRM_OBJECTS_SUCCEEDED, BATCH_DELETE_CRM_OBJECTS_SUCCEEDED, BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED } from '../../crmObjects/actions/crmObjectsActionTypes';
import { SYNC_ROUTER_VALUES } from '../../init/actions/initActionTypes';
import { FILTERS_CHANGED, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SORT_CHANGED } from '../../../views/actions/viewsActionTypes';
import { produce } from 'immer';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import { mutableUpdateIn } from '../../objectUtils/mutableUpdateIn';
import { SET_RECONCILED_OBJECTS } from '../actions/localMutationsActionTypes';
import { objectEntries } from '../../objectUtils/objectEntries';
import { SYNC_SEARCH_TERM } from '../../search/actions/searchActionTypes';
import { pipelineChanged } from '../../pipelines/slice/currentPipelineIdSlice';
import { updateMixedCrmObjectsByIdsAction } from '../../crmObjects/actions/crmObjectsActions';
function findPropertyUpdateValue(objectUpdates, objectId, propertyName) {
  var _requestedUpdate$prop;
  const requestedUpdate = objectUpdates.find(update => update.objectId === objectId);
  const requestedValue = requestedUpdate === null || requestedUpdate === void 0 || (_requestedUpdate$prop = requestedUpdate.propertyValues.find(propertyValue => propertyValue.name === propertyName)) === null || _requestedUpdate$prop === void 0 ? void 0 : _requestedUpdate$prop.value;
  return requestedValue;
}
export const initialState = {
  createdObjectIds: {},
  deletedObjectIds: {},
  updatedObjectIdsAndDeltas: {},
  filterQueryMutations: {},
  // Map of objectTypeId > objectId > reconciled stageId
  reconciledPipelineableObjects: {}
};
export const localCrmObjectMutationsReducer = produce((draft, action) => {
  switch (action.type) {
    case CREATED_CRM_OBJECT:
      {
        const {
          objectTypeId,
          objectId
        } = action.payload;
        mutableUpdateIn(['createdObjectIds', objectTypeId], (existingIds = []) => [...new window.Set([...existingIds, objectId])], draft);
        return draft;
      }
    case BATCH_DELETE_CRM_OBJECTS_SUCCEEDED:
    case DELETE_CRM_OBJECTS_SUCCEEDED:
      {
        const {
          objectTypeId,
          objectIds
        } = action.payload;
        mutableUpdateIn(['deletedObjectIds', objectTypeId], (existingIds = []) => [...new window.Set([...existingIds, ...objectIds])], draft);
        if (action.type === BATCH_DELETE_CRM_OBJECTS_SUCCEEDED) {
          mutableSetIn(['filterQueryMutations', objectTypeId, 'entireFilterQueryIsDeletedMutation'], true,
          //represents "did the user bulk delete the entire query"
          draft);
        }
        return draft;
      }
    case updateMixedCrmObjectsByIdsAction.fulfilled.toString():
      {
        const {
          objectTypeId,
          objectUpdates
        } = action.meta.arg;
        const objectUpdatesResponse = action.payload;
        if (objectUpdatesResponse) {
          objectUpdatesResponse.forEach(objectUpdate => {
            const properties = objectUpdate.properties;
            const objectId = String(objectUpdate.objectId);
            Object.entries(properties).forEach(([propertyName, _property]) => {
              // Assign type, because client-types return this as `unknown`
              const property = _property;
              let value = property.value;

              // As of July 24, 2024:
              // Encrypted property updates do not return the value directly, so we need
              // to look up the requested update and use that instead. If the requested value
              // didn't update correctly, it shouldn't show up in this response.
              if (property.isEncrypted) {
                const updateValue = findPropertyUpdateValue(objectUpdates, objectId, propertyName);
                if (typeof updateValue === 'string') {
                  value = updateValue;
                }
              }
              mutableSetIn(['updatedObjectIdsAndDeltas', objectTypeId, objectUpdate.objectId, propertyName], value, draft);
            });
          });
        } else {
          // We get the last modified property for free as it's one of the properties the BE returns on a property update.
          objectUpdates.forEach(({
            objectId,
            propertyValues
          }) => propertyValues.forEach(({
            name,
            value
          }) => mutableSetIn(['updatedObjectIdsAndDeltas', objectTypeId, objectId, name], value, draft)));
        }
        return draft;
      }
    case BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED:
    case UPDATE_CRM_OBJECTS_SUCCEEDED:
      {
        const {
          objectTypeId,
          objectIds,
          propertyValues,
          updatedObjectsResponse
        } = action.payload;
        if (updatedObjectsResponse) {
          updatedObjectsResponse.forEach(updatedCrmObject => {
            const properties = updatedCrmObject.properties;
            Object.entries(properties).forEach(([propertyName, property]) => {
              mutableSetIn(['updatedObjectIdsAndDeltas', objectTypeId, updatedCrmObject.objectId, propertyName], property.value, draft);
            });
          });
        } else {
          objectIds.forEach(objectId => {
            propertyValues.forEach(({
              name,
              value
            }) => {
              mutableSetIn(['updatedObjectIdsAndDeltas', objectTypeId, objectId, name], value, draft);
            });
          });
        }
        if (action.type === BATCH_UPDATE_CRM_OBJECTS_SUCCEEDED) {
          propertyValues.forEach(({
            name,
            value
          }) => {
            mutableSetIn(['filterQueryMutations', objectTypeId, 'propertyUpdates', name], value, draft);
          });
        }
        return draft;
      }
    case SET_RECONCILED_OBJECTS:
      {
        const {
          objectTypeId,
          objectIdsToStageIds,
          pipelineStagePropertyName
        } = action.payload;

        // We use this to track in which order the objects were reconciled. This allows us to always
        // put the most recently reconciled object at the top of the list. This could be replaced by
        // a simple increasing id, but a timestamp seems to fit the problem better.
        const now = Date.now();
        objectEntries(objectIdsToStageIds).forEach(([objectId, toStageId]) => {
          mutableSetIn(['reconciledPipelineableObjects', objectTypeId, objectId], {
            toStageId,
            timestamp: now
          }, draft);
          mutableSetIn(['updatedObjectIdsAndDeltas', objectTypeId, objectId, pipelineStagePropertyName], toStageId, draft);
        });
        return draft;
      }
    case pipelineChanged.toString():
    case SORT_CHANGED:
    case FILTERS_CHANGED:
    case SYNC_SEARCH_TERM:
    case OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID:
    case SYNC_ROUTER_VALUES:
      {
        draft.createdObjectIds = {};
        draft.deletedObjectIds = {};
        draft.updatedObjectIdsAndDeltas = {};
        draft.reconciledPipelineableObjects = {};
        draft.filterQueryMutations = {};
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);