import { registerMutation, useMutation } from 'data-fetching-client';
import { useCallback } from 'react';
import { putStageSettings } from '../api/stageSettingsApi';
import { STAGE_SETTINGS_FIELD_NAME, STAGE_SETTINGS_QUERY } from './useLoadStageSettings';
import { usePipeline } from 'crm-object-board-core/pipeline';
const MUTATION = registerMutation({
  fieldName: STAGE_SETTINGS_FIELD_NAME,
  fetcher: putStageSettings
});
export const useUpdateStageSettings = () => {
  const pipeline = usePipeline();
  const [updateStageSettingsMutation] = useMutation(MUTATION, {
    optimisticResponse(vars) {
      return {
        [STAGE_SETTINGS_FIELD_NAME]: {
          settings: vars.settings,
          stageId: vars.stageId
        }
      };
    },
    update(cache, {
      data
    }) {
      if (!data || !data[STAGE_SETTINGS_FIELD_NAME]) {
        return;
      }
      const updated = data[STAGE_SETTINGS_FIELD_NAME];
      const query = {
        query: STAGE_SETTINGS_QUERY,
        variables: {
          pipelineId: pipeline.pipelineId,
          stageIds: pipeline.stages.map(x => x.stageId)
        }
      };
      const existing = cache.readQuery(query);
      cache.writeQuery(Object.assign({}, query, {
        data: {
          [STAGE_SETTINGS_FIELD_NAME]: Object.assign({}, existing ? existing[STAGE_SETTINGS_FIELD_NAME] : {}, {
            [updated.stageId]: updated.settings
          })
        }
      }));
    }
  });
  const updateStageSettings = useCallback((stageId, settings) => {
    if (pipeline) {
      updateStageSettingsMutation({
        variables: {
          pipelineId: pipeline.pipelineId,
          stageId,
          settings
        }
      }).catch(() => {
        //swallowing as it is already logged in `catchBoardError`
      });
    }
  }, [updateStageSettingsMutation, pipeline]);
  return {
    updateStageSettings
  };
};