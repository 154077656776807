import { useEditBoardSettingsPanelContext } from 'crm-pipeline-board-settings-lib/EditBoardSettingsPanel';
import { useHasCurrencyFormatting } from '../../board/hooks/useHasCurrencyFormatting';
export const ABBREVIATED_CURRENCY_PROPS = {
  simplifyCurrency: true,
  abbreviate: true,
  abbreviateThreshold: 1000000,
  maxFractionDigits: 1,
  precision: 0
};
export const useCurrencyFormatting = () => {
  const hasCurrencyFormatting = useHasCurrencyFormatting();
  const {
    settings: cardPreferences
  } = useEditBoardSettingsPanelContext();
  const isCurrencyAbbreviated = hasCurrencyFormatting && cardPreferences.currencyFormatPreferences.isAbbreviated;
  return isCurrencyAbbreviated ? ABBREVIATED_CURRENCY_PROPS : undefined;
};