/*
 * Adding new tracking? If your tracking is a new event or an import of
 * events.yaml, please use usageTracker.ts. We'd like to migrate off of
 * customer-data-tracking and onto our local tracker.
 */
import CrmLogger from '../loggers/crmLogger';
import * as FiltersUsageActionTypes from 'customer-data-filters/filterQueryFormat/UsageActionTypes';
export const track = (eventName, properties) => CrmLogger.log(eventName, properties);
export const trackImmediate = (eventName, properties) => CrmLogger.logImmediate(eventName, properties);
export const LOG_INDEX_PAGE_VIEW_TYPES = {
  BOARD_VIEW: 'board view',
  LIST_VIEW: 'list view'
};
export const trackIndexPageView = ({
  viewType,
  typeDef,
  numberOfPinnedViews,
  pinnedViewsLimit
}) => track('indexPageview', {
  viewType,
  objectTypeId: typeDef.objectTypeId,
  objectTypeName: typeDef.name,
  screen: typeDef.objectTypeId,
  subscreen: viewType,
  numberOfPinnedViews: String(numberOfPinnedViews),
  pinnedViewsLimit: String(pinnedViewsLimit)
});

//this differs from indexPageView as this event is emitted multiple times per session as the variables to this event change
//whereas trackIndexPageView is only emitted once at page load and never again
export const trackIndexPageViewDynamic = ({
  objectTypeId,
  viewId,
  pageType
}) => track('indexInteractions', {
  action: 'dynamic page view',
  objectTypeId,
  subAction: String(viewId),
  type: pageType
});
export const trackOpenPreviewSidebar = ({
  objectTypeId,
  objectId
}) => track('indexInteractions', {
  action: 'open record preview sidebar',
  objectTypeId,
  objectId
});
export const trackOpenAIDealSummarySidebar = ({
  objectId
}) => track('indexInteractions', {
  action: 'open deal registration summary sidebar',
  objectId
});
export const trackOpenAIDealSummarizer = () => track('indexInteractions', {
  action: 'open ai deal summarizer'
});
export const trackCopilotSummarizeObject = () => track('indexInteractions', {
  action: 'summarize object in copilot'
});
export const trackRedirectToForecast = () => track('indexInteractions', {
  action: 'navigate-to-forecast'
});
export const trackToggleDataWell = ({
  isShown,
  objectTypeId,
  viewId,
  viewType
}) => track('indexInteractions', {
  action: isShown ? 'show-data-well' : 'hide-data-well',
  objectTypeId,
  subAction: String(viewId),
  type: viewType
});
export const trackDataWellVisible = ({
  objectTypeId,
  viewType
}) => track('indexInteractions', {
  action: 'data-well-visible',
  objectTypeId,
  viewType
});
export const trackLabelCellLinkClicked = ({
  objectTypeId,
  objectId
}) => track('indexInteractions', {
  action: 'label-cell-link-clicked',
  objectTypeId,
  objectId
});
export const trackOpenMoreFiltersPanel = () => track('filterInteractions', {
  action: 'open advanced filters'
});
export const trackCreateNewView = () => track('filterInteractions', {
  action: 'click create new view from add view dropdown'
});
export const trackOpenEditColumnsModal = typeDef => track('indexInteractions', {
  action: 'open edit columns modal',
  objectTypeId: typeDef.objectTypeId,
  objectTypeName: typeDef.name
});
export const trackEditSavedView = ({
  isViewModified
}) => track('editSavedView', {
  action: 'saved view',
  modified: String(isViewModified)
});
export const trackEditSavedViewRedesign = ({
  isViewModified
}) => track('editSavedView', {
  action: 'save button redesign click',
  modified: String(isViewModified)
});
export const trackSaveViewPopoverClick = () => {
  track('editSavedView', {
    action: 'clicked open save view dropdown'
  });
};
export const trackResetViewRedesign = () => {
  track('editSavedView', {
    action: 'reset button redesign click'
  });
};
export const trackOpenSaveAsNewModalRedesign = () => {
  track('editSavedView', {
    action: 'clone button redesign click'
  });
};
const getActionForCreateSavedView = ({
  isClone,
  isSaveViewDropdown
}) => {
  if (isClone) {
    return 'saved filter as new custom view';
  } else if (isSaveViewDropdown) {
    return 'created view from dropdown menu under Save view';
  }
  return 'created view from dropdown menu under Add view';
};
export const trackCreateView = ({
  isClone,
  isSaveViewDropdown
}) => track('createSavedView', {
  action: getActionForCreateSavedView({
    isClone,
    isSaveViewDropdown
  }),
  fromClone: String(isClone)
});
export const trackCloseViewTab = numberOfPinnedViews => track('indexInteractions', {
  action: 'close view tab',
  // Number open before closing
  numberOfPinnedViews: String(numberOfPinnedViews)
});
export const trackReorderViewTabs = numberOfPinnedViews => track('indexInteractions', {
  action: 'reorder view tab',
  numberOfPinnedViews: String(numberOfPinnedViews)
});

// For logging statements where the event name is 'indexInteractions'
// When this function is called from other files, they only need to pass in the properties.
export const trackIndexInteractionsEvent = indexInteractionsProperties => {
  track('indexInteractions', indexInteractionsProperties);
};
export const trackBulkAssignEvent = () => track('bulkAssign', undefined);
export const trackBulkEditRecordsEvent = bulkEditRecordsProperties => track('bulkEditRecords', bulkEditRecordsProperties);
export const trackBulkCommunicationSaveEvent = bulkCommuncationProperties => track('saveBulkCommunicationSubscription', bulkCommuncationProperties);
export const trackBulkDeleteEvent = bulkDeleteProperties => track('bulkDelete', bulkDeleteProperties);
export const trackBulkEnrollEvent = bulkEnrollProperties => track('use-bulk-enroll-in-sequence', bulkEnrollProperties);
export const trackCreateRecordEvent = createRecordProperties => track('createRecord', createRecordProperties);
export const trackRecordInteractionsEvent = recordInteractionsProperties => track('recordInteractions', recordInteractionsProperties);
export const trackFilterInteractionsEvent = filterInteractionsProperties => track('filterInteractions', filterInteractionsProperties);
export const trackFilterAction = (filterUserAction, {
  component,
  objectTypeId
}) => {
  const {
    action,
    condition,
    filterFamily,
    subAction,
    property,
    type
  } = filterUserAction;
  const defaultProperties = {
    component,
    objectTypeId,
    action,
    condition: condition ? `${condition}` : 'unknown',
    filterFamily: filterFamily || '',
    subAction,
    property: property || '',
    type: type || ''
  };
  if (action === FiltersUsageActionTypes.CHANGED_ASSOCIATION) {
    trackFilterInteractionsEvent(Object.assign({}, defaultProperties, {
      objectTypeId: objectTypeId && objectTypeId.startsWith('2-') ? '2-' : objectTypeId
    }));
  } else {
    trackFilterInteractionsEvent(Object.assign({}, defaultProperties, {
      action: action || FiltersUsageActionTypes.USED_SEGMENTATION_FILTERS
    }));
  }
};
export const trackLocalSearchEvent = localSearchProperties => track('useLocalSearch', localSearchProperties);
export const trackOpenSavedView = openSavedViewProperties => track('openSavedView', openSavedViewProperties);
export const trackEditColumns = editColumnsProperties => track('editColumns', editColumnsProperties);
export const trackMovedBoardCardEvent = ({
  objectType,
  objectTypeId,
  stageType,
  stageName,
  stageColumnType = ''
}) => {
  track('movedBoardColumnItem', {
    type: objectType,
    objectTypeId,
    stageType,
    stageName,
    stageColumnType
  });
};

/*
 * numberOfProperties - the number of properties edited accross all objects
 * totalRecords - the number of objects edited at once
 */
export const trackInlineEditAction = ({
  action,
  objectTypeId,
  numberOfProperties,
  totalRecords
}) => {
  track('inlineEditing', {
    action,
    objectTypeId,
    numberOfProperties: String(numberOfProperties),
    totalRecords: String(totalRecords)
  });
};
export const trackBoardPrefsClick = ({
  source
}) => {
  track('indexInteractions', {
    action: `open board preferences from ${source}`
  });
};
export const trackPreferredCurrencySave = () => {
  track('indexInteractions', {
    action: 'saved preferred currency'
  });
};
export const trackSortChanged = (sortKey, order, objectTypeId) => {
  track('indexInteractions', {
    action: 'sort changed',
    property: sortKey,
    subAction: order === 1 ? 'DESCENDING' : order === -1 ? 'ASCENDING' : 'DEFAULT',
    objectTypeId
  });
};
export const trackColumnCollapsed = ({
  collapse,
  emptyColumn,
  objectTypeId,
  objectTypeName
}) => {
  track('indexInteractions', {
    action: `expand/collapse ${emptyColumn ? 'empty column' : 'non empty column'}`,
    subAction: collapse ? 'collapse' : 'expand',
    objectTypeId,
    objectTypeName
  });
};
export const trackSortModalOpened = () => {
  track('indexInteractions', {
    action: 'sort modal opened'
  });
};
export const trackBoardActionsOpened = () => {
  track('indexInteractions', {
    action: 'board actions dropdown opened'
  });
};
export const trackEditCardsClicked = () => {
  track('indexInteractions', {
    action: 'edit cards clicked'
  });
};
export const trackCardPreferenceChange = (action, subAction = '') => {
  track('indexInteractions', {
    action: `card preference - ${action}`,
    subAction
  });
};
export const trackBoardPreferenceChange = (action, subAction = '') => {
  track('indexInteractions', {
    action: `board preference - ${action}`,
    subAction
  });
};
export const trackCreateInvoice = () => {
  track('indexInteractions', {
    action: 'create invoice'
  });
};
export const trackCreateSubscription = () => {
  track('indexInteractions', {
    action: 'create subscription'
  });
};
export const trackCreateProduct = () => {
  track('indexInteractions', {
    action: 'create product'
  });
};

/*
 * numberOfEditedProperties - the number of Product's properties edited
 */
export const trackProductEditAction = ({
  action,
  numberOfEditedProperties
}) => {
  track('indexInteractions', {
    action,
    numberOfEditedProperties: String(numberOfEditedProperties)
  });
};
export const trackViewedEmptyState = ({
  objectTypeName,
  dueToFiltering = false
}) => {
  track('indexInteractions', {
    action: 'viewed empty state',
    subAction: dueToFiltering ? 'due to filtering' : 'due to no results',
    objectTypeName
  });
};
export const trackViewedContactEmptyState = () => {
  track('indexInteractions', {
    action: 'viewed contact empty state with sample contacts'
  });
};
export const trackViewedEmptyStateWelcomeModal = ({
  objectTypeName
}) => {
  track('indexInteractions', {
    action: 'viewed empty state welcome modal',
    objectTypeName
  });
};
export const trackClickedEmptyStateWelcomeModal = ({
  objectTypeName,
  buttonType
}) => {
  track('indexInteractions', {
    action: `clicked welcome modal ${buttonType} setup button`,
    objectTypeName
  });
};
export const trackPipelineSwitcherChanged = args => {
  track('indexInteractions', {
    action: 'pipeline switcher changed',
    type: args.type,
    subScreen: args.pageType,
    screen: args.objectTypeId,
    pipelineId: args.pipelineId
  });
};
export const trackBoardMovePreventedModal = ({
  errorCode
}) => {
  track('indexInteractions', {
    action: 'board move prevented',
    subAction: errorCode
  });
};
export const trackBoardMoveBypassedModal = ({
  errorCode
}) => {
  track('indexInteractions', {
    action: 'board move bypassed',
    subAction: errorCode
  });
};
export const trackBoardRejectedReviveModal = ({
  errorCode
}) => {
  track('indexInteractions', {
    action: 'revive rejected deal',
    subAction: errorCode
  });
};
export const trackConfirmedUnsavedChanges = ({
  objectTypeId
}) => {
  track('inlineEditing', {
    action: 'confirmed unsaved changes modal',
    objectTypeId
  });
};

//ToDo: change trackImmediate tracker
export const trackImmediateNavigatedWithUnsavedChanges = ({
  objectTypeId
}) => {
  trackImmediate('inlineEditing', {
    action: 'navigated away with unsaved changes',
    objectTypeId
  });
};
export const trackBoardCardActions = (action, subAction = '') => {
  track('indexInteractions', {
    action: `board card - ${action}`,
    subAction
  });
};

// ---------  OTHER CASE LOGS (logIndexInteraction, logRecordInteraction, etc)
/*
can find the rest of the other case logs inside the CrmLogger file.
*/