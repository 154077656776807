import { useCallback, useEffect } from 'react';
import { useProps } from '../../props/useProps';
import { useEditBoardSettingsPanelContext } from 'crm-pipeline-board-settings-lib/EditBoardSettingsPanel';
import { getColumnByName, getIsLastActivityDateInactive, isColumnConsideredComplete } from '../utils';
import Raven from 'raven-js';
import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { useSetGetIsCardShaded } from '../../render/options/useSetGetIsCardShaded';
import { useBoardSettings } from 'crm-object-board-core/frameworkAppSettings';
import { usePipeline } from 'crm-object-board-core/pipeline';
export const useIsCardShaded = () => {
  const {
    objectTypeId
  } = useProps();
  const pipeline = usePipeline();
  const selectedCardPreferences = useEditBoardSettingsPanelContext();
  const {
    inactiveCardPreferences: inactiveCardsStateSlice
  } = selectedCardPreferences.settings;
  const boardSettings = useBoardSettings();
  const isTurnedOn = !inactiveCardsStateSlice.isTurnedOff;
  const value = inactiveCardsStateSlice.value;
  const unit = inactiveCardsStateSlice.unit;
  const propertyKey = boardSettings && boardSettings.cardInactivity && boardSettings.cardInactivity.value.property;
  const getIsCardShaded = useCallback((cardData, columnData) => {
    if (!cardData || !columnData || !isTurnedOn || !value || !unit || !propertyKey || !pipeline) {
      return false;
    }
    const columns = pipeline.stages;
    const currentColumn = getColumnByName({
      columns,
      columnKey: 'stageId',
      columnValue: columnData.id
    });
    if (!currentColumn) {
      Raven.captureMessage(`[useGetIsCardShaded]: unable to find column for card`, {
        extra: {
          objectTypeId,
          stageId: columnData.id,
          pipelineId: pipeline.pipelineId
        }
      });
      return false;
    }
    const isInCompletedStage = isColumnConsideredComplete(currentColumn);
    if (isInCompletedStage) {
      return false;
    }
    const cardBoardData = cardData.data;
    const crmObject = cardBoardData.object;
    const lastUpdatedDate = getProperty(crmObject, propertyKey);
    const createDate = getProperty(crmObject, 'createdate');

    // see when the user last interacted with the card - if no interaction ever, use the day it was made
    const lastActivityDate = lastUpdatedDate || createDate;
    if (!lastActivityDate) {
      return false;
    }
    const lastActivityDateEpoch = lastActivityDate ? +lastActivityDate : undefined;
    return getIsLastActivityDateInactive(lastActivityDateEpoch, value, unit);
  }, [isTurnedOn, value, unit, propertyKey, objectTypeId, pipeline]);
  const setter = useSetGetIsCardShaded();
  useEffect(() => {
    setter(getIsCardShaded);
  }, [setter, getIsCardShaded]);
};