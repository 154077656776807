import I18n from 'I18n';
import { useFastDispatch } from '../context/FastContext';
import { columnSlice } from '../render/columns/columnStore';
import { useAutomationFlows } from './useAutomationFlows';
import { useEffect, useMemo } from 'react';
import { usePipeline } from 'crm-object-board-core/pipeline';
import { useProps } from '../props/useProps';
import { getFlowUuid } from './useAutomationFlows';
export const useWorkflowIcon = () => {
  const pipeline = usePipeline();
  const {
    objectTypeId
  } = useProps();
  const pipelineId = pipeline.pipelineId;
  const stageIds = useMemo(() => pipeline.stages.map(stage => stage.stageId), [pipeline]);
  const {
    loading,
    data: flows
  } = useAutomationFlows(pipelineId, stageIds);
  const dispatch = useFastDispatch();
  useEffect(() => {
    if (!loading && flows) {
      const icons = {};
      stageIds.forEach(stageId => {
        const uuid = getFlowUuid(objectTypeId, pipelineId, stageId);
        if (uuid && flows[uuid]) {
          icons[stageId] = {
            name: 'workflows',
            screenReaderText: I18n.text('crmObjectBoard.column.hasWorkflowIconText'),
            tooltip: I18n.text('crmObjectBoard.column.hasWorkflowTooltip')
          };
        }
      });
      dispatch(columnSlice.actions.setIcons(icons));
    }
  }, [dispatch, loading, flows, objectTypeId, pipelineId, stageIds]);
};