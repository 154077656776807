import { useCallback, useEffect } from 'react';
import { useMessageBus } from './useMessageBus';
import { useCrmMessagingContext } from './CrmMessagingContext';
import { isCommandTopic } from './isCommandTopic';
import { throwCommandError } from './internal/throwCommandError';
export function useSendCrmMessageTopic(topic) {
  const messageBus = useMessageBus();
  const messagingContext = useCrmMessagingContext();
  useEffect(() => {
    if (isCommandTopic(topic) && !messageBus) {
      throwCommandError(`MessageBusError: The ${topic} command could not be sent because useSendCrmMessageTopic has no messageBus context.`);
    } else if (isCommandTopic(topic) && messageBus) {
      messageBus.registerCommandSender(topic, messagingContext);
    }
  }, [messageBus, topic, messagingContext]);
  return useCallback((data, config = {}) => {
    if (!messageBus) {
      return;
    }
    let enveloperSourceId;
    if (config.sourceId) {
      enveloperSourceId = config.sourceId;
    } else if (messagingContext && messagingContext.messagingId) {
      enveloperSourceId = messagingContext.messagingId;
    }
    const envelope = {
      sourceId: enveloperSourceId,
      targetId: config.targetId
    };
    const envelopeData = {
      envelope,
      data
    };
    messageBus.publish(topic, envelopeData);
  }, [messageBus, topic, messagingContext]);
}