export const SEARCH_API_CONFIG_TYPES = {
  ENABLED: 'ENABLED'
};
export const validateSearchApiConfig = config => {
  if (config.pollInterval < 1000 * 60 * 2) {
    throw new Error('Poll interval must be at least 2 minutes to prevent DDOSing crm search');
  }
};
export const searchApiDefaultConfig = {
  type: SEARCH_API_CONFIG_TYPES.ENABLED,
  pollInterval: 1000 * 60 * 2 //2 minutes
};