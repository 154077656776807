import { useId } from 'react';

/**
 * Returns a unique id with the given prefix.
 * @param prefix - Prefix to use for the id. This is required to ensure that the id doesn't clash with ids from consuming packages.
 * @see https://hubspot.slack.com/archives/C4ABAB8CR/p1706286518983269
 * @returns A unique id with the given prefix.
 */
export const usePrefixedId = prefix => {
  return `${prefix}-${useId()}`;
};