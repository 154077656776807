import { OWNER } from 'customer-data-objects/property/ExternalOptionTypes';
import PropertyNameToReferenceType from 'customer-data-objects/property/PropertyNameToReferenceType';
import { CALL_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import PropertyHeader from '../table/cells/PropertyHeader';
import LabelHeader from '../table/cells/LabelHeader';
import AssociationHeader from '../table/cells/AssociationHeader';
import TaxCategoryHeader from '../table/cells/TaxCategoryHeader';
import { isAssociationColumnName } from './associationsIdUtils';
import { isContactNameProperty } from '../properties/CONTACT_NAME_PROPERTY';
import { IndexLabelCellCheckWrapper } from '../table/cells/IndexLabelCellCheckWrapper';
import { IndexPropertyCell } from '../table/cells/IndexPropertyCell';
import IndexAssociationCell from '../table/cells/IndexAssociationCell';
import { withFallback } from './withFallback';
import { getCustomCellComponentWithTDWrapper } from './getCustomCellComponentWithTDWrapper';
import { CallsTrackedTermsPropertyHeader } from '../table/cells/CallsTrackedTermsPropertyHeader';

/**
 * a helper function to get the value of a property on an object
 * Instead of doing:
 * ```tsx
 * const x = object[property]
 * ```
 * you do
 * ```tsx
 * const x = getOwnProperty(object, property)
 * ```
 *
 * Why do I use this? try a property named 'constructor' on an object, it will return the constructor function of the object, not the property value
 */
export function getOwnProperty(o, key) {
  return Object.prototype.hasOwnProperty.call(o, key) ? o[key] : undefined;
}
const getPropertyReferenceType = property => {
  if (property) {
    return property.referencedObjectType || PropertyNameToReferenceType[property.name];
  }
  return undefined;
};
const getHeaderContent = ({
  columnIndex,
  objectTypeId,
  column
}) => {
  const isAssociation = isAssociationColumnName(column.name);
  if (columnIndex === 0) {
    return LabelHeader;
  }
  if (isAssociation) {
    return AssociationHeader;
  }
  if (objectTypeId === PRODUCT_TYPE_ID && column.name === 'hs_tax_category') {
    return TaxCategoryHeader;
  }
  if (objectTypeId === CALL_TYPE_ID && column.name === 'hs_call_transcript_tracked_terms') {
    return CallsTrackedTermsPropertyHeader;
  }
  return PropertyHeader;
};
const getCell = (index, isAssociation) => {
  if (index === 0) {
    return IndexLabelCellCheckWrapper;
  }
  if (isAssociation) {
    return IndexAssociationCell;
  }
  return IndexPropertyCell;
};
const getIsColumnSortable = ({
  objectTypeId,
  columnName,
  properties
}) => {
  //contacts have a not real property called 'name' that is sortable
  if (isContactNameProperty({
    objectTypeId,
    propertyName: columnName
  })) {
    return true;
  }
  const isAssociation = isAssociationColumnName(columnName);
  const propertyDefinition = getOwnProperty(properties, columnName);
  const referenceType = propertyDefinition && getPropertyReferenceType(propertyDefinition);
  const isHighlySensitive = (propertyDefinition === null || propertyDefinition === void 0 ? void 0 : propertyDefinition.dataSensitivity) === 'high';
  if (!propertyDefinition) {
    //Im not sure this is a real use case, the header will error, but it seems like a sane fallback
    //and I wanted to make this business logic exhaustive
    return false;
  }
  if (isHighlySensitive) {
    //Highly sensitive columns are never sortable
    return false;
  }
  if (isAssociation) {
    //No association columns are ever sortabls
    return false;
  }
  if (referenceType === undefined) {
    //if the property isnt a reference type, we allow sorting
    return true;
  }
  if (referenceType === OWNER) {
    //HACK: Historically users have been able to sort by an owner reference type,
    //so we allow sorting on these columns. However, the sort is applied by the internal id, not the owner's name.
    return true;
  }
  //this is for all other reference types, which are not sortable
  //an example of this would be the 'Last sequence enrolled', 'hs_latest_sequence_enrolled' property on contacts
  return false;
};
export const mapColumns = ({
  columns,
  objectTypeId,
  properties,
  columnsAreReorderable,
  tableIsSortable,
  tableIsResizable
}) => columns.map((column, index) => {
  const {
    minWidth,
    name,
    width,
    resizable
  } = parseColumnConfigForMisconfigurations(column, tableIsResizable);
  const isAssociation = isAssociationColumnName(name);
  const isColumnSortable = getIsColumnSortable({
    objectTypeId,
    properties,
    columnName: name
  });
  const isSortable = tableIsSortable && isColumnSortable;
  const CellComponent = withFallback({
    Component: getCustomCellComponentWithTDWrapper({
      CustomCell: column.CustomCell
    }),
    Fallback: getCell(index, isAssociation)
  });
  return {
    CellComponent,
    HeaderContentComponent: getHeaderContent({
      column,
      columnIndex: index,
      objectTypeId
    }),
    columnName: name,
    columnNamespace: objectTypeId,
    minimumColumnWidth: minWidth,
    reorderable: columnsAreReorderable && index !== 0,
    resizable,
    sortable: isSortable,
    width
  };
});
function parseColumnConfigForMisconfigurations(column, isTableResizable) {
  const columnWithFallbacks = Object.assign({}, column);
  if (column.resizable && !isTableResizable) {
    console.warn('Bad configuration: table and column resizable configs are in opposition');
    columnWithFallbacks.resizable = isTableResizable && column.resizable;
  }
  if (column.minWidth != null) {
    const {
      minWidth = 0,
      width = 0
    } = column;
    if (width < minWidth) {
      console.warn('Bad column config: width is less than min width');
      columnWithFallbacks.width = Math.max(width, minWidth);
    }
  }
  return columnWithFallbacks;
}