import { useMemo } from 'react';
import { makeGetColumnWidths } from '../slice/columnWidthsSlice';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { useSelector } from 'react-redux';
export const useColumnWidths = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();
  const getColumnWidths = useMemo(() => makeGetColumnWidths(objectTypeId, viewId), [objectTypeId, viewId]);
  return useSelector(getColumnWidths);
};