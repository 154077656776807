import { useEffect, useState } from 'react';
import { getUserSettings } from '../../settings/settingsApi';
// This will be in charge of fetching settings from user customizations stored in DB
export const useCardSectionSettings = ({
  skip,
  pipelineId,
  objectTypeId
}) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null
  });
  useEffect(() => {
    if (skip) {
      setState({
        data: null,
        loading: false,
        error: null
      });
    }
    getUserSettings(pipelineId, objectTypeId).then(({
      data
    }) => {
      setState({
        loading: false,
        data,
        error: null
      });
    }).catch(error => {
      setState({
        loading: false,
        error,
        data: null
      });
    });
  }, [skip, setState, pipelineId, objectTypeId]);
  return state;
};