import { useState, useCallback } from 'react';
import { usePrefixedId } from './usePrefixedId';
/**
 * Unstyled disclosure primitive for controlling the visibility of content.
 * Can be used to build components following the WAIA-ARIA Disclosure pattern.
 * @link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/
 */
export function useDisclosure({
  defaultIsOpen = false,
  disabled = false,
  isOpen: controlledIsOpen,
  onOpen,
  onClose
}) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(defaultIsOpen);
  const isControlled = controlledIsOpen !== undefined;
  const isOpen = isControlled ? controlledIsOpen : uncontrolledIsOpen;
  const triggerElementAriaAttributes = {
    'aria-expanded': isOpen,
    'aria-controls': usePrefixedId('trellis-foundations-disclosure')
  };
  const handleToggle = useCallback(() => {
    const newIsOpen = !isOpen;
    if (!isControlled) {
      setUncontrolledIsOpen(newIsOpen);
    }
    if (newIsOpen) {
      if (onOpen) onOpen();
    } else {
      if (onClose) onClose();
    }
  }, [isOpen, isControlled, onOpen, onClose]);
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      handleToggle();
    }
  }, [isOpen, handleToggle]);
  const handleClose = useCallback(() => {
    if (isOpen) {
      handleToggle();
    }
  }, [isOpen, handleToggle]);
  return {
    isOpen: disabled ? defaultIsOpen : isOpen,
    triggerElementAriaAttributes,
    onToggle: handleToggle,
    onOpen: handleOpen,
    onClose: handleClose
  };
}