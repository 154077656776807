import { useEffect } from 'react';
import { getPerformanceApi } from '../lib/performanceApi';
import { measureBetween } from '../lib/measureBetween';
import { sendToLogger } from '../lib/sendToLogger';
import { useProps } from '../../props/useProps';
const MEASURE_CHECK_INTERVAL_MS = 200;
export const useMeasure = (measurement, {
  once = true
} = {}) => {
  const {
    objectTypeId
  } = useProps();
  useEffect(() => {
    const perfApi = getPerformanceApi();
    if (!perfApi) {
      return;
    }
    const intervalId = setInterval(() => {
      try {
        if (once) {
          const lastMeasurement = perfApi.getEntriesByName(measurement.name, 'measure');
          if (lastMeasurement && lastMeasurement.length > 0) {
            clearInterval(intervalId);
            return;
          }
        }
        const duration = measureBetween(measurement);
        if (duration !== undefined) {
          clearInterval(intervalId);
          sendToLogger(measurement, duration, objectTypeId);
        }
      } catch (err) {
        clearInterval(intervalId);
      }
    }, MEASURE_CHECK_INTERVAL_MS);
    return () => {
      clearInterval(intervalId);
    };
  }, [measurement, objectTypeId, once]);
};