import { useBoardProps } from 'crm-object-board-core/boardProps';
import { useEffect, useState } from 'react';
import { getRegistryForObjectType } from './getRegistryForObjectType';
// This hook calls to getRegistryForObjectType to get the registry and configs async for the object type.
export const useCardSectionRegistry = ({
  skip
}) => {
  const {
    objectTypeId
  } = useBoardProps();
  const [registryMap, setRegistryMap] = useState({
    loading: true,
    data: undefined,
    error: undefined
  });
  useEffect(() => {
    if (skip) {
      setRegistryMap({
        loading: false,
        data: undefined,
        error: undefined
      });
      return;
    }
    async function getRegistryMap() {
      const {
        registryMap: map
      } = await getRegistryForObjectType(objectTypeId);
      setRegistryMap({
        loading: false,
        data: map,
        error: undefined
      });
    }
    getRegistryMap().catch(e => {
      setRegistryMap({
        loading: false,
        data: undefined,
        error: e
      });
    });
  }, [objectTypeId, skip]);
  return registryMap;
};