import getIn from 'transmute/getIn';
import { DEAL, TICKET } from 'customer-data-objects/constants/ObjectTypes';
export let StageType;
(function (StageType) {
  StageType["Won"] = "won";
  StageType["Lost"] = "lost";
  StageType["Closed"] = "closed";
  StageType["Empty"] = "";
})(StageType || (StageType = {}));
var Probability;
(function (Probability) {
  Probability["Lost"] = "0";
  Probability["Won"] = "1";
})(Probability || (Probability = {}));
export const getStageType = (objectType, stage) => {
  if (objectType === DEAL) {
    const probability = getIn(['metadata', 'probability'], stage);
    if (probability === Probability.Lost) {
      return StageType.Lost;
    }
    if (probability === Probability.Won) {
      return StageType.Won;
    }
  }
  if (objectType === TICKET && getIn(['metadata', 'isClosed'], stage) === 'true') {
    return StageType.Closed;
  }
  return StageType.Empty;
};