import isEmpty from 'transmute/isEmpty';
/**
 * Converts from PropertyUpdates (the format the FE typically uses) to PropertyValues (the format the BE typically uses)
 *
 * @param propertyUpdates An object whose keys are property names and values are the value of that property
 * @returns An array of objects with name and value properties created from each entry in the input object
 */
export const mapPropertyUpdatesToPropertyValues = propertyUpdates => Object.entries(propertyUpdates).reduce((acc, [name, value]) => {
  acc.push({
    name,
    value
  });
  return acc;
}, []);

/**
 * Converts from PropertyValues (the format the BE typically uses) to PropertyUpdates (the format the FE typically uses)
 *
 * @param propertyValues An array of objects which have name and value keys corresponding to property names and values
 * @returns An object whose keys are property names and values are the new value of that property
 */
export const mapPropertyValuesToPropertyUpdates = propertyValues => propertyValues.reduce((updates, {
  name,
  value
}) => {
  updates[name] = value;
  return updates;
}, {});
export const mapPendingEditsToObjectUpdates = pendingEdits => Object.entries(pendingEdits).reduce((objectUpdates, [objectId, propertyUpdates]) => {
  if (!propertyUpdates || isEmpty(propertyUpdates)) {
    return objectUpdates;
  }
  objectUpdates.push({
    objectId,
    propertyValues: mapPropertyUpdatesToPropertyValues(propertyUpdates)
  });
  return objectUpdates;
}, []);