import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getLocallyDeletedObjectIdsForCurrentType, getLocallyUpdatedObjectsForCurrentType, getLocallyUpdatedFilterQueryMutationsForCurrentType } from '../../localMutations/selectors/localCrmObjectMutationsSelectors';
import { getLocallyCreatedObjectsForCurrentType } from '../../crmObjects/selectors/crmObjectsSelectors';
import produce from 'immer';
import get from 'transmute/get';
import { mutableUpdateIn } from '../../objectUtils/mutableUpdateIn';
import { useAllHighlySensitivePropertyValues } from 'customer-data-properties/dataSensitivity/useAllHighlySensitivePropertyValues';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
const MULTI_ENUM_DELIMITER = ';';
const APPEND_MULTI_ENUM_DELIMITER = ';';
const REMOVE_MULTI_ENUM_DELIMITER = '?^';
// Multi-enum properties are a set of options split by semicolons.
//
// They can be edited in multiple strategies: "append", "replace", "remove"
// (this append/replace/remove functionality can be found in the bulk edit modal, example property "buying role")
//
// "replace" will discard all values from the previous array (split at semicolon)
// "append" will combine the previous value + new value into a deduped array (split at semicolon)
// "remove" will remove the previous value + new value into a deduped array (split at semicolon)
//
// The backend determines "append", "remove", "replace" by the first character.
// If the first character of the new value is a semicolon, it "appends" the values.
// If the first character of the new value is a '?^', it "removes" the values.
// If the first character of the new value is anything except a semicolon, it "replaces" the values
//
// This function is meant to re-create that functionality on the frontend within our optimistic update code
// Multi-enum properties are a set of options split by semicolons.
//
// They can be edited in multiple strategies: "append", "replace", "remove"
// (this append/replace/remove functionality can be found in the bulk edit modal, example property "buying role")
//
// "replace" will discard all values from the previous array (split at semicolon)
// "append" will combine the previous value + new value into a deduped array (split at semicolon)
// "remove" will remove the previous value + new value into a deduped array (split at semicolon)
//
// The backend determines "append", "remove", "replace" by the first character.
// If the first character of the new value is a semicolon, it "appends" the values.
// If the first character of the new value is a '?^', it "removes" the values.
// If the first character of the new value is anything except a semicolon, it "replaces" the values
//
// This function is meant to re-create that functionality on the frontend within our optimistic update code
function getNewPropertyValue(currentValue, propertyUpdate) {
  propertyUpdate = String(propertyUpdate);

  // If it starts with a semicolon, we should append the values.
  if (propertyUpdate.startsWith(APPEND_MULTI_ENUM_DELIMITER)) {
    const newValues = new Set();

    // Add current values to the set only if they exist, preventing
    // undefined values from being added and resulting in a final value
    // prepended by "undefined", e.g. "undefined;new-appended-value".
    // @see https://issues.hubspotcentral.com/browse/CRM-58161
    currentValue === null || currentValue === void 0 || currentValue.split(MULTI_ENUM_DELIMITER).forEach(val => newValues.add(val));
    propertyUpdate.split(MULTI_ENUM_DELIMITER).filter(Boolean) //when .split ing the first item in the array will be a '' so we filter that out
    //avoid inlinining currentValues.add https://stackoverflow.com/questions/37199019/method-set-prototype-add-called-on-incompatible-receiver-undefined
    .forEach(newValue => newValues.add(newValue));
    return Array.from(newValues).join(MULTI_ENUM_DELIMITER);
  }
  if (String(propertyUpdate).startsWith(REMOVE_MULTI_ENUM_DELIMITER)) {
    //if it starts with '?^' we should remove the values
    const newValues = new Set();

    // Add current values to the set only if they exist, preventing
    // undefined values from being added and resulting in a final value
    // prepended by "undefined", e.g. "undefined;new-appended-value".
    // @see https://issues.hubspotcentral.com/browse/CRM-58161
    currentValue === null || currentValue === void 0 || currentValue.split(MULTI_ENUM_DELIMITER).forEach(val => newValues.add(val));
    propertyUpdate.replace(/^[?^]+/, '') // removes any leading ? or ^ characters from the start of a string
    .split(MULTI_ENUM_DELIMITER).forEach(value => newValues.delete(value));
    return Array.from(newValues).join(MULTI_ENUM_DELIMITER);
  }

  //if it does not start with a semicolon we just replace the values
  return propertyUpdate;
}
export const mergeUpdatesIntoCrmObject = produce((draft, propertyUpdates) => {
  Object.entries(propertyUpdates).forEach(([propertyName, propertyUpdate]) => {
    mutableUpdateIn(['properties', propertyName, 'value'], currentValue => getNewPropertyValue(currentValue, propertyUpdate), draft);
  });
});

/**
 * This hook returns a callback that applies local mutations to a list of crm objects.
 *
 * 1. It filters out any objects that have been deleted.
 * 2. It prepends any objects that have been created, regardless of whether or not they match the current query.
 * 3. It updates property values of any objects that have been mutated (via the sidebar, bulk edit modal, etc).
 *
 * On the board, we do not want to add created objects (instead they should be reconciled into the correct stage)
 * so we can disable that behavior with the "addCreates" option.
 */
export const useApplyLocalMutationsCallback = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const locallyCreatedObjects = useSelector(getLocallyCreatedObjectsForCurrentType);
  const locallyDeletedObjectIds = useSelector(getLocallyDeletedObjectIdsForCurrentType);
  const localObjectPropertyUpdates = useSelector(getLocallyUpdatedObjectsForCurrentType);
  const localFilterQueryMutations = useSelector(getLocallyUpdatedFilterQueryMutationsForCurrentType);
  const highlySensitivePropertyValues = useAllHighlySensitivePropertyValues({
    objectTypeId
  });
  return useCallback(({
    results,
    addCreates = true
  }) => {
    const objectIdsInResults = new Set(results.map(({
      objectId
    }) => objectId));
    const baseResults = addCreates ? locallyCreatedObjects.filter(({
      objectId
    }) => !objectIdsInResults.has(objectId)).concat(results) : results;
    const isEntireQueryDeleted = get('entireFilterQueryIsDeletedMutation', localFilterQueryMutations) || false;
    if (isEntireQueryDeleted) {
      return [];
    }
    return baseResults
    // Filter out deleted objects from the merged list
    .filter(({
      objectId
    }) => !locallyDeletedObjectIds.map(String).includes(String(objectId))
    //parts of the codebase pass in numbers vs strings, this ensures at runtime this accepts both
    //we should migrate this to TS to ensure this is validated at build time as well
    )
    //
    // Apply any property updates we have cached locally to objects in the list
    .map(object => {
      const {
        objectId
      } = object;

      // Properly apply mutations in the following order (last mutation wins):
      // 1. highly sensitive decrypted values
      // 2. mutations due to filtering
      // 3. mutations to object values (local edits)
      // the batch mutations action also applies selected object mutations at the same time
      const highlySensitiveValueMutations = highlySensitivePropertyValues[objectId];
      const filterMutations = get('propertyUpdates', localFilterQueryMutations) || {};
      const byIdMutations = get(objectId, localObjectPropertyUpdates) || {};
      const mergedMutations = Object.assign({}, highlySensitiveValueMutations, filterMutations, byIdMutations);
      return Object.keys(mergedMutations).length > 0 ? mergeUpdatesIntoCrmObject(object, mergedMutations) : object;
    });
  }, [localFilterQueryMutations, localObjectPropertyUpdates, locallyCreatedObjects, locallyDeletedObjectIds, highlySensitivePropertyValues]);
};