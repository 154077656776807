import useConditionalPropertiesPanelInternal from '../v2/hooks/useConditionalPropertiesPanel';
import useGetAdditionalPropertyValueProxy from '../v2/hooks/useGetAdditionalPropertyValueProxy';
const useConditionalPropertiesPanel = args => {
  const {
    objectTypeId,
    property,
    // @ts-expect-error TS confused by union, its OK if this pulls undefined
    getAdditionalPropertyValue,
    // @ts-expect-error TS confused by union, its OK if this pulls undefined
    getSavedValue,
    // @ts-expect-error TS confused by union, its OK if this pulls undefined
    getDraftValue
  } = args;
  const getAdditionalPropertyValueProxy = useGetAdditionalPropertyValueProxy({
    getAdditionalPropertyValue,
    getDraftValue,
    getSavedValue,
    objectTypeId,
    propertyName: property.name,
    componentName: 'useconditionalpropertiespanel'
  });
  return useConditionalPropertiesPanelInternal(Object.assign({}, args, {
    getAdditionalPropertyValue: getAdditionalPropertyValueProxy
  }));
};
export default useConditionalPropertiesPanel;