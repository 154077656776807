export const communicationSummary = action => {
  return {
    data: {
      intent: 'object_associations',
      association_type: 'CONTACT_TO_ENGAGEMENT',
      object: {
        object_type: 'CONTACT',
        object_search: {
          email: action.email
        }
      }
    },
    text: `Show engagement with ${action.email}`
  };
};