import { useCallback } from 'react';
import { pipelineValidationBatch } from './api/stageValidationApi';
import { useProps } from '../../props/useProps';
import { usePipeline } from 'crm-object-board-core/pipeline';
import { useOpenInvalidStageMoveModal } from './useOpenInvalidStageMoveModal';
import { useOpenConfirmBypassStageValidationModal } from './useOpenConfirmBypassStageValidationModal';
import { useHasPipelineApprovalAccess } from 'crm-object-board-core/frameworkAppSettings';
import { getStageValidations, INVALID, VALID, OVERRIDABLE } from 'customer-data-properties/input/pipeline/utils/pipelineValidationUtils';
export const useRunStageTransitionValidation = () => {
  const currentPipeline = usePipeline();
  const {
    objectTypeId
  } = useProps();
  const openInvalidStageMoveModal = useOpenInvalidStageMoveModal();
  const openConfirmBypassStageValidationModal = useOpenConfirmBypassStageValidationModal();
  const hasPipelineApprovalAccess = useHasPipelineApprovalAccess();
  return useCallback(({
    objectId,
    destinationStageId
  }) => {
    if (!(currentPipeline !== null && currentPipeline !== void 0 && currentPipeline.pipelineId)) {
      return Promise.reject(new Error('No pipeline selected'));
    }
    return pipelineValidationBatch([{
      objectId: Number(objectId),
      objectTypeId,
      targetPipelineId: currentPipeline.pipelineId,
      targetStageIds: [destinationStageId]
    }]).then(result => {
      return new Promise(res => {
        const stages = hasPipelineApprovalAccess && currentPipeline !== null && currentPipeline !== void 0 && currentPipeline.stages ? currentPipeline.stages.map(({
          stageId,
          label,
          isApprovalStage
        }) => ({
          stageId,
          label,
          isApprovalStage: isApprovalStage !== null && isApprovalStage !== void 0 ? isApprovalStage : null
        })) : [];
        const validationResult = getStageValidations(result, stages);
        const [validationObject] = validationResult || []; // Assumes only one validation per object
        const {
          validation
        } = validationObject || {};
        switch (validation) {
          case INVALID:
            if ('errorCode' in validationObject) {
              const {
                errorCode
              } = validationObject;
              openInvalidStageMoveModal({
                errorCode,
                objectId,
                objectTypeId
              });
              res(false);
            }
            break;
          case OVERRIDABLE:
            if ('errorCode' in validationObject) {
              const {
                errorCode
              } = validationObject;
              openConfirmBypassStageValidationModal({
                errorCode,
                onConfirm: () => res(true),
                onCancel: () => res(false)
              });
            }
            break;
          case VALID:
            res(true);
            break;
          default:
            res(true);
            break;
        }
      });
    });
  }, [currentPipeline, objectTypeId, openConfirmBypassStageValidationModal, openInvalidStageMoveModal, hasPipelineApprovalAccess]);
};