import devLogger from 'react-utils/devLogger';
export const getPerformanceApi = () => {
  if (window.performance) {
    return window.performance;
  }
  devLogger.warn({
    key: 'crm-object-board::getPerformanceApi',
    message: 'window.performance does not exist'
  });
  return undefined;
};