import I18n from 'I18n';
import { CALL_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, INVOICE_TYPE_ID, MARKETING_EVENT_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, ORDER_TYPE_ID, CART_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
const getTextForObjectType = objectTypeId => {
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.contact');
    case COMPANY_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.company');
    case DEAL_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.deal');
    case TICKET_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.tickets');
    case CALL_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.calls');
    case INVOICE_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.invoices');
    case MARKETING_EVENT_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.marketingEvents');
    case COMMERCE_PAYMENT_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.payments');
    case ORDER_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.orders');
    case CART_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.carts');
    case PRODUCT_TYPE_ID:
      return I18n.text('index.searchBar.placeholders.products');
    default:
      return I18n.text('index.searchBar.placeholders.default');
  }
};
export const useSearchBarPlaceholder = () => {
  const currentObjectTypeDef = useSelectedObjectTypeDef();
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return getTextForObjectType(currentObjectTypeDef.objectTypeId);
};