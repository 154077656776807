import { isCurrency } from 'customer-data-objects/property/PropertyIdentifier';
import { AGG } from '../types/Aggregations';
const ALLOW_AGG = [AGG.SUM, AGG.AVG];
export const isValidAmountAggregationConfig = (properties, config) => {
  const typedConfig = config;
  const validAgg = ALLOW_AGG.indexOf(typedConfig.aggregation) > -1;
  const property = properties.get(config.property);
  //no property
  if (property === undefined) {
    return false;
  }

  //no currency property
  if (!typedConfig.meta || !typedConfig.meta.currencyProperty) {
    return false;
  }
  const currencyProperty = properties.get(typedConfig.meta.currencyProperty);
  //currency property doesnt exist
  if (currencyProperty === undefined) {
    return false;
  }
  /**
   * for DEALs
   * property = amount_in_home_currency
   * currencyProperty = amount
   *
   * amount has currency property defined
   */

  return !!(isCurrency(property) &&
  //property is correct type
  isCurrency(currencyProperty) &&
  //currency property is correct type
  currencyProperty.currencyPropertyName &&
  //currency property has currency code property
  validAgg);
};
export const getAmountDisplayLabel = (properties, config) => {
  if (config.meta.currencyProperty) {
    const property = properties.get(config.meta.currencyProperty);
    if (property) {
      return property.label;
    }
  }
  const property = properties.get(config.property);
  if (property) {
    return property.label;
  }
  return config.property;
};
export const getAmountI18nKey = config => {
  if (config.meta.weighted) {
    return 'WEIGHTED';
  }
  return config.aggregation.toUpperCase();
};
export const getAmountPreviewI18nKey = config => {
  if (config.meta.weighted) {
    return 'WEIGHTED';
  }
  if (config.meta.projected) {
    return 'PROJECTED';
  }
  return config.aggregation.toUpperCase();
};
export const getAmountAggregationId = config => {
  if (config.meta.weighted) {
    return `${config.property}-${config.aggregation}-WEIGHTED`;
  }
  return `${config.property}-${config.aggregation}`;
};
export const isAmountAggregationConfig = config => {
  const aggConfig = config;
  return aggConfig.meta && aggConfig.meta.weighted !== undefined;
};