import { getPerformanceApi } from './performanceApi';
import devLogger from 'react-utils/devLogger';
export const mark = marker => {
  const perfApi = getPerformanceApi();
  if (!perfApi) {
    return;
  }
  try {
    perfApi.mark(marker.name);
  } catch (err) {
    const errAsError = err;
    devLogger.warn({
      key: 'crm-object-board:mark',
      message: errAsError ? errAsError.message : 'Could not mark()'
    });
    console.error(err);
  }
};