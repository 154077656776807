import { useFrameworkAppSettings } from '../context/AppSettingsContext';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getAppSettingForTypeDef, getFieldValue } from '../utils/getFieldValue';
import { CRM_INDEX_PAGE_APP_SETTING } from '../constants/AppSettings';
export const useIndexPageAppSettingFieldValue = fieldName => {
  const appSettings = useFrameworkAppSettings();

  // @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'undefined' to type 'ObjectType... Remove this comment to see the full error message
  const typeDef = useSelectedObjectTypeDef();
  const metadata = getAppSettingForTypeDef(appSettings, typeDef, CRM_INDEX_PAGE_APP_SETTING);
  return getFieldValue(metadata, fieldName);
};