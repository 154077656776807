import { usePreviewAssociatedObjects } from 'customer-data-associations';
import { mapObjectToAssociationAvatarLookup } from './utils/utils';
export const useAssociatedObjectsForDefinition = (objectId, associationDefinition, hasCustomAssociationGetter) => {
  const result = usePreviewAssociatedObjects({
    objectId,
    associationDefinition,
    skip: hasCustomAssociationGetter
  });
  if (!associationDefinition || !result || !result.data) {
    return [];
  }
  return result.data.map(association => {
    return mapObjectToAssociationAvatarLookup(association, associationDefinition.toObjectTypeDefinition);
  });
};