import { getId } from '../../utils/getId';
import { sumByProperty } from '../../utils/sumByProperty';
export const createReconcile = (config, options) => {
  const sumAmountProperty = sumByProperty(config.meta.currencyProperty);
  const sumAmountInHomeCurrencyProperty = sumByProperty(config.property);
  const sumPreferredCurrencyAmounts = (results, preferredCurrencyCode, preferredCurrencyConverter) => {
    const isInPreferredCurrency = result => result.data.properties[options.currencyCodeProperty] && result.data.properties[options.currencyCodeProperty].value === preferredCurrencyCode;
    const [preferredCurrencyResults, nonPreferredCurrenciesResults] = results.reduce(([preferred, other], result) => isInPreferredCurrency(result) ? [preferred.concat(result), other] : [preferred, other.concat(result)], [[], []]);
    const preferredCurrencySum = sumAmountProperty(preferredCurrencyResults);
    const nonPreferredCurrenciesSum = sumAmountInHomeCurrencyProperty(nonPreferredCurrenciesResults);
    return preferredCurrencySum + preferredCurrencyConverter(nonPreferredCurrenciesSum);
  };
  const adjustPreferredCurrencyTotal = (rawResults, results, stageAggregations, preferredCurrencyCode, preferredCurrencyConverter) => {
    const preferredRawSum = sumPreferredCurrencyAmounts(rawResults, preferredCurrencyCode, preferredCurrencyConverter);
    const preferredReconciledSum = sumPreferredCurrencyAmounts(results, preferredCurrencyCode, preferredCurrencyConverter);
    const preferredDelta = preferredReconciledSum - preferredRawSum;
    const preferredBaseAggregation = stageAggregations && stageAggregations.meta && stageAggregations.meta.preferredCurrencyTotal || 0;
    return preferredBaseAggregation + preferredDelta;
  };
  const adjustCompanyCurrencyTotal = (rawResults, results, stageAggregations) => {
    const companyRawSum = sumAmountInHomeCurrencyProperty(rawResults);
    const companyReconciledSum = sumAmountInHomeCurrencyProperty(results);
    const companyDelta = companyReconciledSum - companyRawSum;
    const companyBaseAggregation = stageAggregations && stageAggregations.value || 0;
    return companyBaseAggregation + companyDelta;
  };
  return (aggregations, data, reconciledData) => {
    const amountAggregations = aggregations;
    return reconciledData.reduce((acc, columnDataResult, index) => {
      const columnId = columnDataResult.columnId;
      const results = columnDataResult.data;
      const rawResults = data[index].data;
      const stageAggregation = amountAggregations[columnId];
      acc[columnId] = {
        aggregatorId: getId(config),
        value: adjustCompanyCurrencyTotal(rawResults, results, stageAggregation),
        meta: {
          preferredCurrencyTotal: options.hasPreferredCurrency ? adjustPreferredCurrencyTotal(rawResults, results, stageAggregation, options.preferredCurrencyCode, options.preferredCurrencyConverter) : undefined
        }
      };
      return acc;
    }, {});
  };
};