import get from 'transmute/get';
import PortalIdParser from 'PortalIdParser';
import { getId } from 'customer-data-objects/objects/ObjectAccessors';
import { getProperty } from 'customer-data-objects/objects/ObjectAccessors';
import formatName from 'I18n/utils/formatName';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, QUOTE_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const createLinkFromObjectTypeAndId = (objectTypeId, objectId) => {
  if (!objectTypeId || !objectId) {
    return null;
  }
  const portalId = PortalIdParser.get();

  // copied from crm-legacy-links/links
  switch (objectTypeId) {
    case COMPANY_TYPE_ID:
      return `/contacts/${portalId}/company/${objectId}/`;
    case CONTACT_TYPE_ID:
      return `/contacts/${portalId}/contact/${objectId}/`;
    case DEAL_TYPE_ID:
      return `/contacts/${portalId}/deal/${objectId}/`;
    case QUOTE_TYPE_ID:
      return `/contacts/${portalId}/quote/${objectId}/`;
    case TICKET_TYPE_ID:
      return `/contacts/${portalId}/ticket/${objectId}/`;
    default:
      return `/contacts/${portalId}/record/${objectTypeId}/${objectId}/`;
  }
};
export const createLinkFromObjectAndTypeDefinition = (object, objectTypeDefinition) => {
  const objectTypeId = get('objectTypeId', objectTypeDefinition);
  if (!objectTypeId) {
    return null;
  }
  const id = getId(objectTypeId, object);
  if (!id) {
    return null;
  }
  return createLinkFromObjectTypeAndId(objectTypeId, id);
};
export const getObjectLabel = (object, objectTypeDefinition) => {
  if (objectTypeDefinition.objectTypeId === CONTACT_TYPE_ID) {
    return formatName({
      firstName: getProperty(object, 'firstname'),
      lastName: getProperty(object, 'lastname'),
      email: getProperty(object, 'email')
    });
  }
  return getProperty(object, objectTypeDefinition.primaryDisplayLabelPropertyName);
};
export const mapObjectToAssociationAvatarLookup = (object, objectTypeDefinition) => {
  const objectId = object.objectId;
  const objectTypeId = objectTypeDefinition.objectTypeId;
  return {
    primaryIdentifier: objectId,
    displayName: getObjectLabel(object, objectTypeDefinition),
    href: createLinkFromObjectTypeAndId(objectTypeId, objectId),
    type: objectTypeId === CONTACT_TYPE_ID ? 'vid' : 'companyId'
  };
};