const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import Raven from 'raven-js';
import { parseCrmObject } from 'crm-object-search-query-utilities/crmObjectSearch';
import { useStaleDataWhileLoading } from '../crmSearch/hooks/useStaleDataWhileLoading';
const CRM_OBJECT_TABLE_NEW_OBJECT_FRAGMENT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectTableNewObjectFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObject\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"objectId\"},\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userPermissions\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanEdit\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanDelete\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CrmObjectTableNewObjectFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmObject"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        alias: {
          kind: "Name",
          value: "objectId"
        },
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allProperties"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "value"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "userPermissions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanEdit"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanDelete"
            }
          }]
        }
      }]
    }
  }]
});
const CRM_OBJECT_TABLE_NEW_OBJECTS_QUERY = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectTableNewObjectsQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObjects\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"ids\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectTableNewObjectFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CrmObjectTableNewObjectsQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectIds"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Long"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObjects"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "ids"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectIds"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CrmObjectTableNewObjectFragment"
            }
          }]
        }
      }]
    }
  }].concat(CRM_OBJECT_TABLE_NEW_OBJECT_FRAGMENT.definitions))
});
export function useNewObjects({
  objectIds,
  objectTypeId
}) {
  const {
    data,
    loading,
    error
  } = useQuery(CRM_OBJECT_TABLE_NEW_OBJECTS_QUERY, {
    variables: {
      objectIds,
      objectTypeId
    },
    skip: objectIds.length === 0
  });
  const staleWhileLoadingNewObjectData = useStaleDataWhileLoading({
    data,
    loading
  });
  const crmObjectMap = useMemo(() => {
    const map = new Map();
    for (const crmObject of (_staleWhileLoadingNew = staleWhileLoadingNewObjectData === null || staleWhileLoadingNewObjectData === void 0 ? void 0 : staleWhileLoadingNewObjectData.crmObjects) !== null && _staleWhileLoadingNew !== void 0 ? _staleWhileLoadingNew : []) {
      var _staleWhileLoadingNew;
      map.set(crmObject.objectId, crmObject);
    }
    return map;
  }, [staleWhileLoadingNewObjectData === null || staleWhileLoadingNewObjectData === void 0 ? void 0 : staleWhileLoadingNewObjectData.crmObjects]);

  // NOTE: We can't rely on the order returned by the query so we need to use
  // the original order passed in.
  const descendingOrder = useMemo(() => [...objectIds].reverse(), [objectIds]);
  const sortedNewObjectData = useMemo(() => {
    return descendingOrder.map(objectId => crmObjectMap.get(objectId)).filter(isNotNullable).map(crmObjectFragment => parseCrmObject(Object.assign({}, crmObjectFragment, {
      properties: crmObjectFragment.allProperties
    })));
  }, [crmObjectMap, descendingOrder]);
  return useMemo(() => {
    if (!loading && error) {
      Raven.captureException(error);
      return [];
    }
    return sortedNewObjectData !== null && sortedNewObjectData !== void 0 ? sortedNewObjectData : [];
  }, [error, loading, sortedNewObjectData]);
}
function isNotNullable(val) {
  return Boolean(val);
}