import { useMemo } from 'react';
import { buildCardSectionRegistry } from '../../registry/buildCardSectionRegistry';
import { useCardSectionSettings } from './useCardSectionSettings';
import { CARD_WRAPPER } from '../constants';
import { useHasCardV2 } from './useHasCardV2';
import { useBoardProps } from 'crm-object-board-core/boardProps';

/**
 * card section adapter gets all the settings and decides what should be displayed
 * every section is responsible for its own data
 * @returns two arrays of CardSection cardContentSections and cardWrapperSections to be displayed in card component
 */
const useCardSectionsAdapter = ({
  registryMap,
  skip = false
}) => {
  const hasCardV2 = useHasCardV2();
  const {
    pipelineId,
    objectTypeId
  } = useBoardProps();
  // Settings coming from user customizations
  const {
    data,
    loading
  } = useCardSectionSettings({
    skip: hasCardV2,
    pipelineId,
    objectTypeId
  });
  // Registry by object type or what user has provided
  const registry = buildCardSectionRegistry(hasCardV2 && !loading && !skip ? registryMap : undefined);
  const cardContentSections = useMemo(() => data && registryMap ? data.map(settings => {
    const requiredProperties = registry.getRequiredProperties(settings.sectionKey, settings.settings);
    return {
      sectionKey: settings.sectionKey,
      settings: settings.settings,
      Component: registry.getSectionComponent(settings.sectionKey),
      requiredProperties
    }; //i think we cast here to keep all keys and types in sync
  }).filter(c => c.Component) // filter out any sections that don't have a component
  : [], [data, registry, registryMap]);

  // Return wrapper sections separately, as they are always rendered
  // TODO Maddie - align with BE if we want to return wrapper sections from their side with other settings.
  const cardWrapperSections = useMemo(() => hasCardV2 && registryMap ? registry.getSectionKeys().filter(sectionKey => {
    return registry.getSectionType(sectionKey) === CARD_WRAPPER;
  }).map(sectionKey => {
    const requiredProperties = registry.getRequiredProperties(sectionKey, undefined);
    return {
      sectionKey,
      Component: registry.getSectionComponent(sectionKey),
      requiredProperties
    }; // same as above, cast to keep keys and types in check
  }).filter(c => c.Component) // filter out any sections that don't have a component
  : [], [hasCardV2, registry, registryMap]);
  return {
    cardContentSections,
    cardWrapperSections
  };
};
export default useCardSectionsAdapter;