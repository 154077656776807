import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["hasPerPipeline"];
import { useMemo } from 'react';
import { useQuery, registerQuery } from 'data-fetching-client';
import { useCurrentPipeline } from '../../pipelines/hooks/useCurrentPipeline';
import { useHasAllScopes } from '../../../auth/hooks/useHasAllScopes';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { getBoardSettingsByObject, getBoardSettingsByPipeline } from 'crm-pipeline-board-settings-lib/api/boardSettingsApi';
import { useBoardSettingAppSetting } from '../../../frameworkAppSettings/hooks/useBoardSettingAppSetting';
export const PIPELINE_SETTINGS_NAME = 'pipelineSettings';
export const PIPELINE_SETTINGS_QUERY = registerQuery({
  fieldName: PIPELINE_SETTINGS_NAME,
  args: ['objectTypeId', 'pipelineId'],
  fetcher: _ref => {
    let {
        hasPerPipeline
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return hasPerPipeline ? getBoardSettingsByPipeline(rest) : getBoardSettingsByObject(rest);
  }
});
export const usePipelineSettings = () => {
  const pipeline = useCurrentPipeline();
  const objectTypeId = useSelectedObjectTypeId();
  const hasAllScopes = useHasAllScopes();
  const hasPerPipelineScope = hasAllScopes('crm-board-card-customization-per-pipeline');
  const enabledForObject = useBoardSettingAppSetting('enabled');
  const hasBoardCustomizationAccess = Boolean(enabledForObject);
  const {
    data,
    loading,
    error
  } = useQuery(PIPELINE_SETTINGS_QUERY, {
    skip: !hasBoardCustomizationAccess || !pipeline || !objectTypeId,
    variables: {
      pipelineId: pipeline ? pipeline.pipelineId : '',
      objectTypeId,
      hasPerPipeline: hasPerPipelineScope
    }
  });
  return useMemo(() => {
    if (!loading && !error && data) {
      return data.pipelineSettings;
    }
    return undefined;
  }, [data, loading, error]);
};