// @ts-expect-error Untyped import
import SkeletonText from 'customer-data-reference-ui-components/name/SkeletonText';
import styled from 'styled-components';
import { GYPSUM, KOALA, GREAT_WHITE, BATTLESHIP } from 'HubStyleTokens/colors';
import { keyframes } from 'styled-components';

// shimmer animation adapted from https://git.hubteam.com/HubSpot/conversations-skeleton-state
const DURATION = '1s';
const TIMING_FUNC = 'linear';
const DELAY = '0s';
const ITERATION = 'infinite';
const FILL_MODE = 'forwards';
const POSITION_X = 700;
const SHIMMER_ANIMATION = keyframes(["0%{background-position:-", "px 0}100%{background-position:", "px 0}"], POSITION_X, POSITION_X);
export const ShinySkeletonText = styled(SkeletonText).withConfig({
  displayName: "ShinySkeletonText",
  componentId: "sc-1y2d9f3-0"
})(["animation:", " ", " ", " ", " ", " ", ";background:", ";background-size:1200px;"], SHIMMER_ANIMATION, DURATION, TIMING_FUNC, DELAY, ITERATION, FILL_MODE, props => {
  return props.isInactive ? `linear-gradient(to right, ${GYPSUM}, ${KOALA} 20%, ${GYPSUM} 40%)` : `linear-gradient(to right, ${GREAT_WHITE}, ${BATTLESHIP} 20%, ${GREAT_WHITE} 40%)`;
});