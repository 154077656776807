/**
 * Use this if you want explicit "if loading, no error or data" and "if error, no loading and data" types
 */
export const makeApolloLikeResultType = ({
  data,
  loading,
  error
}) => {
  if (loading) {
    return {
      data: undefined,
      loading: true,
      error: undefined
    };
  }
  if (error) {
    return {
      data: undefined,
      loading: false,
      error
    };
  }
  return {
    data,
    loading: false,
    error: undefined
  };
};