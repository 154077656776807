import { useMemo } from 'react';
import { useLocalBoardMetadata } from './useLocalBoardMetadata';
import { useObjectTypeAppSettings } from 'framework-app-settings-features-lib';
import { useBoardProps } from '../boardProps';
export const useBoardSettings = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const {
    data: boardSettingsMetadata
  } = useObjectTypeAppSettings(objectTypeId, 'crm_board_settings');
  const localMetadata = useLocalBoardMetadata();
  return useMemo(() => {
    return Object.assign({}, boardSettingsMetadata, localMetadata);
  }, [boardSettingsMetadata, localMetadata]);
};