import { useMemo } from 'react';
import { useTrackPreviousSearchResults } from '../../crmSearch/hooks/useTrackPreviousSearchResults';
import { useApplyLocalMutations } from '../../localMutations/hooks/useApplyLocalMutations';
export const useDecorateElasticSearchResultsForTable = ({
  data,
  error,
  loading
}) => {
  const dataToRender = useTrackPreviousSearchResults({
    data,
    loading,
    error
  });
  const dataWithLocalChanges = useApplyLocalMutations(dataToRender);
  return useMemo(() => ({
    data: dataWithLocalChanges,
    error,
    loading
  }), [dataWithLocalChanges, error, loading]);
};