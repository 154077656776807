import { SEARCH_LENGTH_LIMIT, SEARCH_WORD_LIMIT } from 'customer-data-objects/search/ElasticSearchConstants';
export const isTooLong = (query = '') => {
  if (!query || typeof query !== 'string') {
    return false;
  }
  const splitQuery = query.split(' ');
  const hasTooManyCharacters = query.length > SEARCH_LENGTH_LIMIT;
  const hasTooManyWords = splitQuery.length > SEARCH_WORD_LIMIT;
  if (hasTooManyCharacters) {
    return {
      type: 'characters',
      limit: SEARCH_LENGTH_LIMIT
    };
  }
  if (hasTooManyWords) {
    return {
      type: 'words',
      limit: SEARCH_WORD_LIMIT
    };
  }
  return false;
};