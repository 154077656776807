import { getId } from '../../utils/getId';
import { getName } from '../../utils/getName';
import { getSubaggregationName } from '../../utils/getSubaggregationName';
export const createHandleResult = (config, options) => {
  const getStageTotalInPreferredCurrency = (stageTotalInHomeCurrency, currencyAggregations) => {
    // In this function, we get the subaggregation objects that come back
    // from the CRMSearch Reporting endpoint, which are grouped by currency,
    // and total them up to get the stage total in the user's preferred currency.
    // We do this by subtracting the preferred currency deals from the company currency
    // total, converting that sum into the preferred currency, and then adding back
    // in the amount in preferred currency (without conversion).

    const preferredCurrencyAggregation = currencyAggregations.find(aggregation => aggregation.key === options.preferredCurrencyCode);
    const amountInHomeCurrency = preferredCurrencyAggregation ? Number(preferredCurrencyAggregation.metrics[config.property][config.aggregation.toLowerCase()]) : 0;
    const nonPreferredTotalConvertedToPreferredCurrency = options.preferredCurrencyConverter(stageTotalInHomeCurrency - amountInHomeCurrency);
    const amountInPreferredCurrency = preferredCurrencyAggregation && config.meta ? Number(preferredCurrencyAggregation.metrics[config.meta.currencyProperty][config.aggregation.toLowerCase()]) : 0;
    return amountInPreferredCurrency + nonPreferredTotalConvertedToPreferredCurrency;
  };
  return queryResult => {
    const aggregations = queryResult[getName(config)] || [];
    return aggregations.reduce((acc, aggregation) => {
      const sum = aggregation.metrics[getName(config)][config.aggregation.toLowerCase()];
      if (config.meta && aggregation.aggregations && options.hasPreferredCurrency) {
        const currencyCodeAgg = aggregation.aggregations[getSubaggregationName(config, options.currencyCodeProperty)];
        acc[aggregation.key] = {
          aggregatorId: getId(config),
          value: Number(sum),
          meta: {
            preferredCurrencyTotal: getStageTotalInPreferredCurrency(Number(sum), currencyCodeAgg)
          }
        };
      } else {
        acc[aggregation.key] = {
          aggregatorId: getId(config),
          value: Number(sum)
        };
      }
      return acc;
    }, {});
  };
};