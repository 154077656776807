import { trackCardPreferenceChange, trackPreferredCurrencySave } from '../../../tracking/indexPageTracking';
export const trackChanges = cardPreferenceSelection => {
  trackCardPreferenceChange(`card style changed to: ${cardPreferenceSelection.cozyCardPreferences.isCompact ? 'compact' : 'default'}`);
  trackCardPreferenceChange(`display priority & associated records: ${(!cardPreferenceSelection.cozyCardPreferences.isBottomPanelHidden).toString()}`);
  trackCardPreferenceChange(`display tags: ${(!cardPreferenceSelection.cozyCardPreferences.areTagsHidden).toString()}`);
  trackCardPreferenceChange(`inactive cards: ${(!cardPreferenceSelection.inactiveCardPreferences.isTurnedOff).toString()}`);
  trackCardPreferenceChange(`inactive limit updated`, cardPreferenceSelection.inactiveCardPreferences.value.toString());
  trackCardPreferenceChange(
  //removed the deal/ticket prefix
  `activity information: ${cardPreferenceSelection.nextLastActivityPreferences.isTurnedOff}`);
  trackCardPreferenceChange(`currency is abbreviated: ${cardPreferenceSelection.currencyFormatPreferences.isAbbreviated.toString()}`);
  if (cardPreferenceSelection.preferredDisplayCurrencyPreferences.preferredDisplayCurrency !== 'UNINITIALIZED') {
    trackPreferredCurrencySave();
  }
};