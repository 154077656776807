import { SET_RECONCILED_OBJECTS } from './localMutationsActionTypes';
export const setReconciledObjectsAction = ({
  objectTypeId,
  objectIdsToStageIds,
  pipelineStagePropertyName
}) => ({
  type: SET_RECONCILED_OBJECTS,
  payload: {
    objectTypeId,
    objectIdsToStageIds,
    pipelineStagePropertyName
  }
});