import { useCallback, useMemo } from 'react';
import { PROPERTY } from '../constants/RequirementTypes';
import { useObjectRequirements } from '../context/ObjectRequirementsContext';
export const useStageHasRequiredProperties = () => {
  const requirements = useObjectRequirements();
  const stagesWithRequirements = useMemo(() => {
    return requirements.reduce((stages, stageRequirements) => {
      if (stageRequirements.objectBuilderFields.some(stageRequirement => stageRequirement.type === PROPERTY)) {
        return stages.add(stageRequirements.pipelineStageId);
      }
      return stages;
    }, new Set());
  }, [requirements]);
  return useCallback(stageId => {
    return stagesWithRequirements.has(stageId);
  }, [stagesWithRequirements]);
};