import { FAILED, PENDING, SUCCEEDED, UNINITIALIZED } from '../constants/RequestStatus';
export const getCombinedStatusFromStatusArray = statuses => {
  const statusesInSet = new Set(statuses);
  if (statusesInSet.has(FAILED)) {
    return FAILED;
  }
  if (statusesInSet.has(UNINITIALIZED)) {
    return UNINITIALIZED;
  }
  if (statusesInSet.has(PENDING)) {
    return PENDING;
  }
  return SUCCEEDED;
};