import { generatePath } from 'react-router-dom';
import PortalIdParser from 'PortalIdParser';
const portalId = PortalIdParser.get();
export const getPipelineSettingsHref = ({
  objectTypeId,
  pipelineId
}) => generatePath('/pipelines-settings/:portalId/object/:objectTypeId/:pipelineId?', {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  portalId,
  objectTypeId,
  pipelineId
});