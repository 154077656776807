import { useFrameworkAppSettings } from '../context/AppSettingsContext';
import { CRM_BOARD_SETTINGS } from '../constants/AppSettings';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getFieldValue } from '../utils/getFieldValue';
export const useBoardSettingAppSetting = key => {
  const settings = useFrameworkAppSettings();
  // @ts-expect-error ts-migrate(2352) FIXME: Conversion of type 'undefined' to type 'ObjectType... Remove this comment to see the full error message
  const typeDef = useSelectedObjectTypeDef();
  if (!settings) {
    return null;
  }
  const settingsForObjectType = settings[typeDef.name];
  if (!settingsForObjectType) {
    return null;
  }
  const boardSettings = settingsForObjectType[CRM_BOARD_SETTINGS];
  if (boardSettings && boardSettings.metadata) {
    return getFieldValue(boardSettings.metadata, key);
  }
  return null;
};