import { fetchPreferredCurrency } from '../api/preferredCurrencyApi';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import Raven from 'raven-js';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import { UNINITIALIZED } from '../../constants/RequestStatus';
import { useHasPreferredDisplayCurrency } from '../../board/hooks/useHasPreferredDisplayCurrency';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const preferredCurrencyFieldName = 'preferredCurrency';
const fetcher = () => fetchPreferredCurrency().catch(response => {
  if (response.status === 404) {
    // 404 is a valid response, indicating that the preference has not been set yet
    // therefor, we don't want to throw an error for 404s
    // returning undefined here makes Apollo complain about `preferredCurrency` being unset
    return UNINITIALIZED;
  }
  Raven.captureException(response);
  rethrowError(response);
  return null;
});
export const fetchPreferredCurrencyQuery = registerQuery({
  fieldName: preferredCurrencyFieldName,
  fetcher
});
export const useFetchPreferredCurrency = () => {
  const hasPreferredCurrency = useHasPreferredDisplayCurrency();
  const objectTypeId = useSelectedObjectTypeId();
  const {
    data,
    loading,
    error
  } = useQuery(fetchPreferredCurrencyQuery, {
    skip: !hasPreferredCurrency || objectTypeId !== DEAL_TYPE_ID
  });
  const preferredCurrency = data && data[preferredCurrencyFieldName];
  return useMemo(() => ({
    preferredCurrency,
    loading,
    error
  }), [preferredCurrency, loading, error]);
};