import { createMetricsFactory } from 'metrics-js';
export const Metrics = createMetricsFactory('crm-object-table', {
  library: 'crm-object-table'
});
export const CRM_OBJECT_TABLE_METRIC_NAMES = {
  WRAPPER_MOUNTED: 'WRAPPER_MOUNTED',
  LABEL_CELL_MOUNTED: 'LABEL_CELL_MOUNTED',
  //by "relative" we mean from when the table was mounted to when the cell is put on the screen
  LABEL_CELL_RELATIVE_RENDER_TIME: 'LABEL_CELL_RELATIVE_RENDER_TIME',
  //This means there was a catastrophic failure in CrmObjectTable
  FULL_COMPONENT_CRASH: 'FULL_COMPONENT_CRASH',
  //This means there was an error in CrmObjectTable that we were able to handle
  ERROR_STATE_MOUNTED: 'ERROR_STATE_MOUNTED',
  //This means there were invalid sorts provided to CrmObjectTable
  INVALID_SORTS: 'INVALID_SORTS'
};