import { useState, useMemo, useEffect } from 'react';
import { isPaginationPagedControlledConfig } from '../features/pagination';
/**
 * This hook is used to manage pagination state and provide a consistent interface for pagination
 *
 * `query` is used to reset the page when the query changes if using uncontrolled pagination
 */
export const usePagination = ({
  config,
  query
}) => {
  const isPagedControlledConfig = isPaginationPagedControlledConfig(config);

  //the next three 'consts' are looking at the config and if controlled falling back to the pageNumber and callback provided,
  //or if uncontrolled, using a useState call to manage it internally
  const [uncontrolledPage, setUncontrolledPage] = useState(0);
  const pageNumber = isPagedControlledConfig ? config.pageNumber : uncontrolledPage;
  const setPageNumber = isPagedControlledConfig ? config.onPageNumberChange : setUncontrolledPage;

  //the next three 'consts' are looking at the config and if controlled falling back to the pageSize and callback provided,
  //or if uncontrolled, using a useState call to manage it internally
  const [uncontrolledPageSize, setUncontrolledPageSize] = useState(25);
  const pageSize = isPagedControlledConfig ? config.pageSize : uncontrolledPageSize;
  const setPageSize = isPagedControlledConfig ? config.onPageSizeChange : setUncontrolledPageSize;

  //this useEffect is used to reset the page number when the query changes if using uncontrolled pagination
  //if you are using a controlled pagination, you will need to manage this yourself
  useEffect(() => {
    if (!isPagedControlledConfig) {
      setPageNumber(0);
    }
  }, [isPagedControlledConfig, query, setPageNumber]);

  // add a switch case here to support INFINITE_SCROLL in the future
  return useMemo(() => ({
    type: config.type,
    page: pageNumber,
    pageSize,
    setPage: setPageNumber,
    setPageSize
  }), [config.type, pageNumber, pageSize, setPageNumber, setPageSize]);
};