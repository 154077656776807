import { useMemo } from 'react';
import memoizeOne from 'react-utils/memoizeOne';
import { useAuthContext } from './AuthContext';
const memoizedToScopesSet = memoizeOne(scopes => new Set(scopes));
const EMPTY_SCOPES = [];
export function useHasAllScopes() {
  var _data$user$scopes;
  const {
    data,
    error,
    loading
  } = useAuthContext();
  const userInfoScopes = memoizedToScopesSet((_data$user$scopes = data === null || data === void 0 ? void 0 : data.user.scopes) !== null && _data$user$scopes !== void 0 ? _data$user$scopes : EMPTY_SCOPES);
  return useMemo(() => (...scopes) => ({
    data: scopes.every(scope => userInfoScopes.has(scope)),
    error,
    loading
  }), [error, loading, userInfoScopes]);
}