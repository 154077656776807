import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { useMemo } from 'react';
import { useSearchQuery } from './generateSearchQuery/useSearchQuery';
import { useProps } from '../props/useProps';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
export const HYDRATE_CRM_SEARCH_QUERY_NAME = withCrmObjectBoardNamespace('hydrateCrmSearchQuery');
export const HydrateCrmSearchQuery = registerQuery({
  fieldName: HYDRATE_CRM_SEARCH_QUERY_NAME,
  args: ['filterGroups'],
  fetcher: filterGroups => http.post(`sales-views/v2/crm-search/hydrate-filter-groups`, {
    data: filterGroups.filterGroups
  })
});
export const useHydratedSearchQueryOptions = () => {
  const searchQuery = useSearchQuery();
  const {
    pipelineId
  } = useProps();
  const objectTypeDefinition = useObjectTypeDefinition();
  const filterGroups = useMemo(() => {
    const inputFilters = (searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.filterGroups) || [];
    const pipelineFilter = {
      value: pipelineId,
      operator: 'EQ',
      property: (objectTypeDefinition === null || objectTypeDefinition === void 0 ? void 0 : objectTypeDefinition.pipelinePropertyName) || ''
    };
    //if there are no inputFilters, such as [], we need to make a new filter group
    if (inputFilters.length === 0) {
      return [{
        filters: [pipelineFilter]
      }];
    }
    return inputFilters.map(filterGroup => Object.assign({}, filterGroup, {
      filters: [...filterGroup.filters, pipelineFilter]
    }));
  }, [searchQuery, pipelineId, objectTypeDefinition]);
  const skip = !searchQuery;
  const {
    data,
    loading,
    error
  } = useQuery(HydrateCrmSearchQuery, {
    variables: {
      filterGroups
    },
    skip
  });
  return useMemo(() => {
    return {
      data: data && searchQuery && Object.assign({}, searchQuery, {
        filterGroups: data[HYDRATE_CRM_SEARCH_QUERY_NAME]
      }),
      loading,
      error
    };
  }, [data, loading, error, searchQuery]);
};