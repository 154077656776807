import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchPortalCurrencies } from '../api/multicurrencyApi';
import { useMemo } from 'react';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const portalCurrenciesFieldName = 'portalCurrencyData';
export function formatCurrencyData({
  currencyExchangeRates,
  homeCurrency,
  hasMulticurrencyActive
}) {
  const exchangeRates = currencyExchangeRates.filter(exchangeRate => exchangeRate.visible).reduce((acc, exchangeRate) => {
    acc[exchangeRate.fromCurrencyCode] = exchangeRate.conversionMultiplier;
    return acc;
  }, {});
  return {
    exchangeRates,
    portalCurrencyCodes: Object.keys(exchangeRates),
    homeCurrencyCode: homeCurrency.currencyCode,
    hasMulticurrencyActive
  };
}
export const fetchCurrencies = registerQuery({
  fieldName: portalCurrenciesFieldName,
  fetcher: () => fetchPortalCurrencies().then(formatCurrencyData)
});
export function useFetchPortalCurrencies() {
  const objectTypeId = useSelectedObjectTypeId();
  const {
    data,
    loading
  } = useQuery(fetchCurrencies, {
    skip: objectTypeId !== DEAL_TYPE_ID
  });
  return useMemo(() => {
    if (!data || !data[portalCurrenciesFieldName]) {
      return {
        portalCurrencyCodes: [],
        homeCurrencyCode: undefined,
        loading
      };
    }
    const {
      portalCurrencyCodes,
      homeCurrencyCode
    } = data[portalCurrenciesFieldName];
    return {
      portalCurrencyCodes,
      homeCurrencyCode,
      loading
    };
  }, [data, loading]);
}
export function useHasMulticurrencyActive(overrideQueryOptions) {
  const {
    data
  } = useQuery(fetchCurrencies, Object.assign({}, overrideQueryOptions));
  return Boolean(data && data[portalCurrenciesFieldName].hasMulticurrencyActive);
}