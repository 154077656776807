import { useHasAllScopes } from '../../../auth/hooks/useHasAllScopes';
import { useHasMulticurrencyActive } from '../../multiCurrency/hooks/useFetchPortalCurrencies';
const PreferredDisplayCurrencyScope = 'crm-pipelines-preferred-currency-access';
export const useHasPreferredDisplayCurrencyScope = () => {
  const hasAllScopes = useHasAllScopes();
  return hasAllScopes(PreferredDisplayCurrencyScope);
};
export const useHasPreferredDisplayCurrency = () => {
  const hasMulticurrencyActive = useHasMulticurrencyActive();
  const hasScope = useHasPreferredDisplayCurrencyScope();
  return hasMulticurrencyActive && hasScope;
};