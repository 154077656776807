import { usePipeline } from 'crm-object-board-core/pipeline';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { useColumns } from '../columns/useColumns';
import { useMemo } from 'react';
import memoizeLast from 'transmute/memoizeLast';
const getCards = memoizeLast((cards, objectTypeDefinition) => {
  return cards.map(item => {
    return {
      id: item.id,
      data: {
        object: item.data,
        objectTypeDefinition
      }
    };
  });
});
const getColumnData = memoizeLast((name, id, aggregation, collapsed, totalCards, hasMore, cards, icon, isLoadingNewData, isLoadingMoreData, metadata) => {
  return {
    name,
    id,
    aggregation,
    collapsed,
    totalCards,
    hasMore,
    cards,
    metadata,
    icon,
    isLoadingNewData,
    //this is handled by our global loading state, therefore always make it false
    isLoadingMoreData //if loading is true and the board is rendered then we can presume we are loading more
  };
});
export const useMapDataForRender = (data, loading, loadingMore) => {
  const columns = useColumns();
  const pipeline = usePipeline();
  const objectTypeDefinition = useObjectTypeDefinition();
  const dataMap = useMemo(() => new Map(data.map(d => {
    return [d.columnId, d];
  })), [data]);
  return useMemo(() => {
    if (!pipeline || !objectTypeDefinition) {
      return [];
    }
    return pipeline.stages.map(stage => {
      const stageData = dataMap.get(stage.stageId);
      const cards = getCards(stageData ? stageData.data : [], objectTypeDefinition);
      return getColumnData(stage.label, stage.stageId, columns[stage.stageId] ? columns[stage.stageId].aggregations : undefined, columns[stage.stageId] ? columns[stage.stageId].collapse : false, stageData ? stageData.total : 0, stageData ? stageData.hasMore : false, cards, columns[stage.stageId] ? columns[stage.stageId].icon : undefined, loading, loadingMore,
      //if loading is true and the board is rendered then we can presume we are loading more
      stage.metadata);
    });
  }, [dataMap, pipeline, columns, objectTypeDefinition, loading, loadingMore]);
};