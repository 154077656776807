import { useAggregationsForPipeline } from './useAggregationsForPipeline';
import { useAdjustAggregations } from './useAdjustAggregations';
import { useSetAggregations } from '../../render/columns/useSetAggregations';
import { useEffect } from 'react';
import { useAggregationCollection } from '../components/AggregationProvider';
export const useAggregations = (raw, reconciled) => {
  const setAggregations = useSetAggregations();
  const aggregators = useAggregationCollection();
  const {
    aggregations
  } = useAggregationsForPipeline(aggregators);
  const adjustedAggregation = useAdjustAggregations({
    aggregations,
    aggregators,
    data: raw,
    reconciledData: reconciled
  });
  useEffect(() => {
    if (adjustedAggregation) {
      setAggregations(adjustedAggregation);
    }
  }, [adjustedAggregation, setAggregations]);
};