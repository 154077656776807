import { gate } from 'hub-http/gates';
// @ts-expect-error untyped selector
import withGateOverride from 'crm_data/gates/withGateOverride';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, MEETING_EVENT_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const PERMANENTLY_UNGATED_OBJECT_TYPES = [CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, MEETING_EVENT_TYPE_ID];

//we started out the crm-object-table migration object by object, starting with the most used and most "standard" and working our way down (contacts, companies, deals, cobjects)
//We then marked all objects that were out for more than a few months as "permanent" and removed the gates for those
//We then had tickets left as the last large object type and added a gate for it specifically
//Tickets is now out to 100%, and we are going to use a catch all gate CRM:Datasets:CrmObjectTableForAllObjects
//This gate will be used as the final push, and once fully ungated we can cleanup all code related to the legacy table, TableV2
export const useIsUngatedForCrmObjectTable = () => {
  const hasAllGates = useHasAllGates();
  const selectedObjectTypeId = useSelectedObjectTypeId();
  const isUngatedForCrmObjectTableForAllObjects = withGateOverride('CRM:Datasets:CrmObjectTableForAllObjects', hasAllGates(gate('CRM:Datasets:CrmObjectTableForAllObjects')));
  if (isUngatedForCrmObjectTableForAllObjects) {
    return true;
  }
  const isCustomObjectType = isPortalSpecificObjectType(selectedObjectTypeId);
  const isUngatedForCrmObjectTableForTicketObjects = withGateOverride('CRM:Datasets:CrmObjectTableForTicketObjects', hasAllGates(gate('CRM:Datasets:CrmObjectTableForTicketObjects')));
  if (isCustomObjectType || PERMANENTLY_UNGATED_OBJECT_TYPES.includes(selectedObjectTypeId)) {
    return true;
  }
  switch (selectedObjectTypeId) {
    case TICKET_TYPE_ID:
      return isUngatedForCrmObjectTableForTicketObjects;
    default:
      return false;
  }
};