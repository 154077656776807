import { useEffect } from 'react';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentPageType } from '../../views/hooks/useCurrentPageType';
import { BOARD } from '../../views/constants/PageType';
import { SALES_EXECUTION_DEALS, SALES_EXECUTION_DEAL_PIPELINE, triggerWootricSurvey } from '../../utils/triggerWootricSurvey';
export const useMaybeShowSalesExecutionCSAT = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const pageType = useCurrentPageType();
  useEffect(() => {
    if (objectTypeId !== DEAL_TYPE_ID) {
      return;
    }
    const surveyId = pageType === BOARD ? SALES_EXECUTION_DEAL_PIPELINE : SALES_EXECUTION_DEALS;
    triggerWootricSurvey(surveyId);
  }, [objectTypeId, pageType]);
};