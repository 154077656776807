async function loadCardSectionRegistryMap(objectTypeId) {
  try {
    const {
      REGISTRY
    } = await import( /* webpackChunkName: "[request]" */`./${objectTypeId}/cardSectionRegistry`);
    return {
      registryMap: REGISTRY
    };
  } catch (e) {
    const {
      REGISTRY
    } = await import( /* webpackChunkName: "common" */`./common/cardSectionRegistry`);
    return {
      registryMap: REGISTRY
    };
  }
}
export async function getRegistryForObjectType(objectTypeId) {
  const {
    registryMap
  } = await loadCardSectionRegistryMap(objectTypeId);
  return {
    registryMap
  };
}