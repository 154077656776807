import { DEAL_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useProps } from '../props/useProps';
import { fetchByUuids } from './api/automation.api';
import { registerQuery, useQuery } from 'data-fetching-client';
import { usePipelineBoardSettings } from '../pipelineBoardSettings/usePipelineBoardSettings';
import { useMemo } from 'react';
import { useGetHasScope } from 'crm-object-board-core/user';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
//this should come from the pipelines lib for ensuring we can consistently create the UUID.
export const getFlowUuid = (objectTypeId, pipelineId, stageId) => {
  if (!pipelineId || !stageId) {
    return null;
  }
  if (objectTypeId === DEAL_TYPE_ID) {
    return `deal-${pipelineId}-${stageId}`;
  }
  if (objectTypeId === TICKET_TYPE_ID) {
    return `ticket-${pipelineId}-${stageId}`;
  }
  return null;
};
const fetcher = ({
  flowIds
}) => fetchByUuids(flowIds);
export const GET_AUTOMATIONS = withCrmObjectBoardNamespace('GET_AUTOMATIONS');
const getAutomationsQuery = registerQuery({
  fieldName: GET_AUTOMATIONS,
  args: ['flowIds'],
  fetcher
});
export const useAutomationFlows = (pipelineId, stageIds) => {
  const {
    objectTypeId
  } = useProps();
  const settings = usePipelineBoardSettings();
  const {
    loading: loadingScopes,
    hasScope
  } = useGetHasScope();
  const hasWorkflowAccess = useMemo(() => {
    return !loadingScopes && hasScope('workflows-access');
  }, [hasScope, loadingScopes]);
  const flowUuids = stageIds.map(stageId => getFlowUuid(objectTypeId, pipelineId, stageId)).filter(flowUuid => flowUuid !== null);
  const {
    loading,
    data,
    error
  } = useQuery(getAutomationsQuery, {
    variables: {
      flowIds: flowUuids
    },
    skip: !hasWorkflowAccess || !pipelineId || flowUuids.length === 0 || settings !== null && settings.showWorkflowIcon === false
  });
  return useMemo(() => {
    if (!loading && data) {
      return {
        data: data[GET_AUTOMATIONS],
        loading
      };
    }
    //swallow error
    //logging handled by link
    if (!loading && error) {
      return {
        data: {},
        loading
      };
    }
    return {
      data: undefined,
      loading
    };
  }, [data, error, loading]);
};