import enviro from 'enviro';
export const consoleIfNotProd = {
  log: (...args) => {
    if (!enviro.isProd()) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (!enviro.isProd()) {
      console.warn(...args);
    }
  },
  error: (...args) => {
    if (!enviro.isProd()) {
      console.error(...args);
    }
  }
};