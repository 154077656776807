const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql, useQuery } from '@apollo/client';
import { useProps } from '../../props/useProps';
import { useMemo } from 'react';
const CrmObjectBoardAllValuesFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectBoardAllValuesFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObject\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"objectId\"},\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"name\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CrmObjectBoardAllValuesFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmObject"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        alias: {
          kind: "Name",
          value: "objectId"
        },
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "allProperties"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "value"
            }
          }]
        }
      }]
    }
  }]
});
const CrmObjectBoardAllPropertiesWithValueQuery = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectBoardAllPropertiesWithValue\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}},\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectBoardAllValuesFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CrmObjectBoardAllPropertiesWithValue"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectId"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Long"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "id"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CrmObjectBoardAllValuesFragment"
            }
          }]
        }
      }]
    }
  }].concat(CrmObjectBoardAllValuesFragment.definitions))
});
export const useAllPropertiesWithValue = objectId => {
  const {
    objectTypeId
  } = useProps();
  const {
    data,
    loading,
    error
  } = useQuery(CrmObjectBoardAllPropertiesWithValueQuery, {
    variables: {
      objectTypeId,
      objectId: parseInt(objectId, 10)
    },
    fetchPolicy: 'network-only'
  });
  return useMemo(() => {
    if (loading || error || !data) {
      return {
        data: null,
        loading,
        error
      };
    }
    const mappedData = data.crmObject && data.crmObject.allProperties && data.crmObject.allProperties.reduce((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});
    return {
      loading,
      data: mappedData,
      error
    };
  }, [loading, data, error]);
};