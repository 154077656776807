import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchReport } from '../api/stageAggregationAPI';
import { useAggregationQuery } from './useAggregationQuery';
import { BACKGROUND_REFRESH_INTERVAL_TIME } from '../../data/constants';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
const FETCH_REPORT = withCrmObjectBoardNamespace('FETCH_AGGREGATION_REPORT');
const fetchReportQuery = registerQuery({
  fieldName: FETCH_REPORT,
  args: ['query'],
  fetcher: fetchReport
});
export const useAggregationsForPipeline = aggregators => {
  const {
    loading: queryLoading,
    data: query
  } = useAggregationQuery(aggregators);
  const {
    data,
    loading,
    error
  } = useQuery(fetchReportQuery, {
    skip: queryLoading || !aggregators || query === null,
    pollInterval: BACKGROUND_REFRESH_INTERVAL_TIME,
    variables: {
      query: query
    }
  });
  return useMemo(() => {
    if (loading || error || !data) {
      return {
        loading,
        error,
        aggregations: null
      };
    }

    // The data here comes from the crmsearch /report endpoint.
    // Read more: https://product.hubteam.com/docs/crm-search/usage.html#reporting-aggregating
    let aggregations;
    if (aggregators) {
      const report = data[FETCH_REPORT];
      aggregations = aggregators.handleResult(report.aggregations);
    }
    return {
      loading,
      error,
      aggregations
    };
  }, [data, error, aggregators, loading]);
};