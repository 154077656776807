import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import has from 'transmute/has';
import Raven from 'raven-js';
import { fetchAutomationFlowsAction } from '../actions/automationActions';
import { automationStatusSelector, getHasWorkflowsAccessScope } from '../selectors/automationSelectors';
import { UNINITIALIZED } from '../../rewrite/constants/RequestStatus';
import { getCombinedStatusFromStatusArray } from '../../rewrite/utils/getCombinedStatusFromStatusArray';
export const useFetchAutomationFlows = (flowIds = []) => {
  const dispatch = useDispatch();
  const statuses = useSelector(automationStatusSelector);
  const hasWorkflowsAccessScope = useSelector(getHasWorkflowsAccessScope);

  // If a status is not in the map, it has not yet been fetched
  const unfetchedKeys = useMemo(() => hasWorkflowsAccessScope ? flowIds.filter(flowId => !has(flowId, statuses)) : [], [flowIds, statuses, hasWorkflowsAccessScope]);
  useEffect(() => {
    if (unfetchedKeys.length > 0) {
      dispatch(fetchAutomationFlowsAction(unfetchedKeys)).catch(error => Raven.captureException(error));
    }
  }, [dispatch, unfetchedKeys, hasWorkflowsAccessScope]);
  return useMemo(() => getCombinedStatusFromStatusArray(flowIds.map(flowId => statuses[flowId] || UNINITIALIZED)), [flowIds, statuses]);
};