import Raven from 'raven-js';
import { useEffect } from 'react';
/**
 * use this instead of performance.mark to ensure that types are correct for all browsers
 * For safari 13 and lower, performance.mark does not return the mark
 */
const performanceMark = marker => {
  performance.mark(marker);
};

/**
 * Use this as a "polyfill" for performance.mark - it will return the mark via getEntriesByName
 * as performance.mark does not return the mark in Safari 13 and lower
 */
const performanceMarkPolyfill = marker => {
  performanceMark(marker);
  const entries = performance.getEntriesByName(marker, 'mark');
  const mark = entries.length > 0 && entries[entries.length - 1] || undefined;
  if ((mark === null || mark === void 0 ? void 0 : mark.entryType) === 'mark') {
    return mark;
  }
  return undefined;
};
const markOnMount = ({
  once,
  marker,
  onMark
}) => {
  if (once) {
    const lastMeasurement = performance.getEntriesByName(marker, 'mark');
    if (lastMeasurement && lastMeasurement.length > 0) {
      return;
    }
  }
  const mark = performanceMarkPolyfill(marker);
  if (mark && onMark) {
    onMark(mark);
  }
};

/**
 * Marks a performance entry on component mount.
 * ```tsx
 *  useMarkOnMount({
 *    marker: 'my-component',
 *    once: true,
 *    onMark: (performanceMark) => {
 *      MyMetrics.doMyThing(performanceMark.startTime);
 *    }
 *  });
 *  ```
 * ```
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
 */
export const useMarkOnMount = ({
  marker,
  once = true,
  onMark
}) => {
  useEffect(() => {
    try {
      markOnMount({
        marker,
        once,
        onMark
      });
    } catch (e) {
      //if there is an error monitoring performance, it should not bring down the component
      if (e instanceof Error) {
        Raven.captureException(e);
      }
    }
  }, [marker, onMark, once]);
};

/**
 * Marks a performance entry on component mount.
 *
 * If you would like to use this with metrics-js, you can do this
 * ```tsx
 * const MyComponent = () => {
 *    return (
 *      <>
 *        <MarkOnMount
 *          marker="my-component"
 *          onMark={(performanceMark) => {
 *            MyMetrics.doMyThing(performanceMark.startTime);
 *          }}
 *        />
 *        <MyComponent />
 *      </>
 *    );
 *  };
 *  ```
 *
 */
export const MarkOnMount = ({
  marker,
  once,
  onMark
}) => {
  useMarkOnMount({
    marker,
    once,
    onMark
  });
  return null;
};