import { useAddObjectMutation } from 'crm-object-search-query-utilities/localMutations';
import { useProps } from '../props/useProps';
import { useCallback } from 'react';
export const useLocallyUpdateProperties = () => {
  const addObjectMutation = useAddObjectMutation();
  const {
    objectTypeId
  } = useProps();
  return useCallback((objectId, mutation) => {
    addObjectMutation({
      objectId,
      mutation,
      objectTypeId
    });
  }, [addObjectMutation, objectTypeId]);
};