import memoizeOne from 'react-utils/memoizeOne';
import { useCallback } from 'react';
import { useAuthContext } from './AuthContext';
const memoizedToGatesSet = memoizeOne(gates => new Set(gates));
const EMPTY_GATES = [];
export const useHasAllGates = () => {
  var _data$portal$enabled_;
  const {
    data
  } = useAuthContext();
  const userInfoGates = memoizedToGatesSet((_data$portal$enabled_ = data === null || data === void 0 ? void 0 : data.portal.enabled_gates) !== null && _data$portal$enabled_ !== void 0 ? _data$portal$enabled_ : EMPTY_GATES);
  return useCallback((...gates) => gates.every(gate => userInfoGates.has(gate)), [userInfoGates]);
};