import { usePreferredDisplayCurrency } from './usePreferredDisplayCurrency';
import { fetchCurrencies } from './useFetchPortalCurrencies';
import { useQuery } from 'data-fetching-client';
const EMPTY_OBJECT = {};
function useFetchPortalExchangeRates() {
  // the exchange rates are always [currencyCode --> company/"home" currency]
  // be aware if you are using this to convert *to* currencyCode, you need to do 1/exchangeRate
  const {
    data
  } = useQuery(fetchCurrencies);
  if (!data) {
    return EMPTY_OBJECT;
  }
  return data.portalCurrencyData.exchangeRates;
}
function useExchangeRate(currencyCode) {
  const exchangeRates = useFetchPortalExchangeRates();
  return currencyCode && exchangeRates[currencyCode] || NaN;
}
export function usePreferredCurrencyExchangeRate() {
  const preferredCurrency = usePreferredDisplayCurrency();
  return useExchangeRate(preferredCurrency);
}