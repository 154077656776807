import { useCallback, useEffect, useRef } from 'react';
import { useMarkAndMeasureOnMount } from '../../performance/hooks/useMarkAndMeasureOnMount';
import { BOARD_CARD_MOUNTED_MEASURE } from '../../performance/measurements';
import { useProps } from '../../props/useProps';
import { useElementResized } from './useElementResized';
import { useGetRenderComponent } from '../../render/renderComponents/useRenderComponents';
export const useCardPerformance = () => {
  const {
    appMetrics
  } = useProps();

  // Perf measurements and metrics
  useMarkAndMeasureOnMount(BOARD_CARD_MOUNTED_MEASURE);
  const startTimeMs = useRef(performance.now());
  useEffect(() => {
    if (appMetrics) {
      appMetrics.timer('BOARD_CARD_RENDER').update(performance.now() - startTimeMs.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cardRef = useRef(null);
  const handleCardResized = useCallback(() => {
    // measure card dimensions
    if (cardRef.current) {
      const {
        width,
        height
      } = cardRef.current.getBoundingClientRect();
      if (appMetrics && width > 0 && height > 0) {
        appMetrics.histogram('BOARD_CARD_WIDTH').update(width);
        appMetrics.histogram('BOARD_CARD_HEIGHT').update(height);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useElementResized({
    callback: handleCardResized,
    componentRef: cardRef,
    // debounce the callback to only send the final size of the card
    debounced: true,
    delay: 5000
  });
  const renderCardLoadedMarker = useGetRenderComponent('renderCardLoadedMarker');
  return {
    cardRef,
    renderCardLoadedMarker
  };
};