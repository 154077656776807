import devLogger from 'react-utils/devLogger';
import { Metrics, CRM_OBJECT_TABLE_METRIC_NAMES } from '../../metrics';
import { parseObjectTypeIdForMetrics } from '../../metrics/utils/parseObjectTypeIdForMetrics';
export function getValidatedSorts({
  objectTypeId,
  sorts
}) {
  if (sorts == null) return undefined;
  return sorts.reduce((validSorts, currSort, currIndex) => {
    const {
      property,
      order
    } = currSort;
    if (property == null || order == null) {
      devLogger.warn({
        key: 'crm-object-table-sorting-validation',
        message: "Warning, you passed an invalid sort with null property or order! We've removed it at runtime in crm-object-table."
      });
      Metrics.counter(CRM_OBJECT_TABLE_METRIC_NAMES.INVALID_SORTS, {
        index: currIndex.toString(),
        objectTypeId: parseObjectTypeIdForMetrics(objectTypeId)
      }).increment();
      return validSorts;
    }
    validSorts.push(currSort);
    return validSorts;
  }, []);
}