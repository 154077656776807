import PropTypes from 'prop-types';
export const CRM = 'CRM';
export const HS_EVERYWHERE_UI = 'HS_EVERYWHERE_UI';
export const SEQUENCESUI = 'SEQUENCES_UI';
export const SALES_ACTIVITIES = 'SALES_ACTIVITIES';

// https://git.hubteam.com/HubSpot/SidekickEmailModal/blob/master/static/js/constants/Platforms.js
export const GMAIL = 'GMAIL';
export const OUTLOOK = 'OUTLOOK';
export const OUTLOOK_365 = 'OUTLOOK_365';
export const PlatformPropType = PropTypes.oneOf([CRM, HS_EVERYWHERE_UI, SEQUENCESUI, SALES_ACTIVITIES, GMAIL, OUTLOOK, OUTLOOK_365]);