import { useEffect, useState } from 'react';
import get from 'transmute/get';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
const getDataToRender = ({
  data,
  previousData,
  loading
}) => {
  // If the current query is loading and we have previous data tracked in state,
  // we should return that previous data. If loading and we do not have previous
  // data tracked in state, we should return undefined.
  if (loading) {
    return previousData || undefined;
  }

  // Otherwise we are not loading and so should be able to use the current data
  return data;
};
export const useTrackPreviousSearchResults = ({
  data,
  loading,
  error: __error
}) => {
  const objectTypeId = useSelectedObjectTypeId();

  // We track the previous set of data here so that we can show that previous dataset
  // under a loading spinner while the next dataset is loading.
  const [previousDataMap, setPreviousData] = useState({});
  const previousData = get(objectTypeId, previousDataMap);
  useEffect(() => {
    if (!loading && data) {
      setPreviousData(dataMap => Object.assign({}, dataMap, {
        [objectTypeId]: data
      }));
    }
  }, [data, loading, objectTypeId]);
  return getDataToRender({
    data,
    previousData,
    loading
  });
};