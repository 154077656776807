// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { setFrom } from 'crm_data/settings/LocalSettings';
import { setLastAccessedPage } from '../../../crm_ui/grid/utils/gridStateLocalStorage';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { changePage, changePageSize, changeBoardPageSize } from '../slice/paginationSlice';
import { usePaginationState } from './usePaginationState';
import { DEFAULT_BOARD_PAGE_SIZE, MAX_BOARD_PAGE_SIZE } from '../constants/DefaultPageSizes';
import CrmLogger from '../../../loggers/crmLogger';
import { denormalizeTypeId } from '../../utils/denormalizeTypeId';
export const usePaginationActions = () => {
  const dispatch = useDispatch();
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();

  // TODO: onPageChange can be deprecated after moving to crm-object-table
  const onPageChange = useCallback(page => {
    dispatch(changePage(page));

    // TODO: When fully moved over to IKEA, we can just store the current page
    setLastAccessedPage({
      objectType: objectTypeId,
      value: {
        objectType: objectTypeId,
        viewId,
        currentPage: page
      }
    });
  }, [dispatch, objectTypeId, viewId]);
  const onPageSizeChange = useCallback(pageSize => {
    dispatch(changePageSize(pageSize));
    setFrom(localStorage, 'gridPageSize', pageSize);
  }, [dispatch]);
  const {
    boardPageSize
  } = usePaginationState();
  const onBoardLoadMore = useCallback(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    CrmLogger.logIndexInteraction(denormalizeTypeId(objectTypeId), {
      action: 'use board column show more button',
      subAction: `loaded page ${(boardPageSize + DEFAULT_BOARD_PAGE_SIZE) / DEFAULT_BOARD_PAGE_SIZE}`
    });
    dispatch(changeBoardPageSize(Math.min(boardPageSize + DEFAULT_BOARD_PAGE_SIZE, MAX_BOARD_PAGE_SIZE)));
  }, [objectTypeId, boardPageSize, dispatch]);
  return {
    onPageChange,
    onPageSizeChange,
    onBoardLoadMore
  };
};