const CLOSED_LOST = '0.0';
const CLOSED_WON = '1.0';
export const getWeightedTotalMessage = probability => {
  switch (probability) {
    case CLOSED_LOST:
      return 'indexPage.board.deals.weightedTotal.closedLost';
    case CLOSED_WON:
      return 'indexPage.board.deals.weightedTotal.closedWon';
    default:
      return 'indexPage.board.deals.weightedTotal.default';
  }
};
export const getWeightedTotalTooltipMessage = probability => {
  switch (probability) {
    case CLOSED_LOST:
      return 'indexPage.board.deals.weightedTotal.closedLostLabel';
    case CLOSED_WON:
      return 'indexPage.board.deals.weightedTotal.closedWonLabel';
    default:
      return 'indexPage.board.deals.weightedTotal.defaultLabel';
  }
};
export const getProjectedTotalMessage = probability => {
  switch (probability) {
    case CLOSED_LOST:
      return 'indexPage.board.deals.projectedTotal.closedLost';
    case CLOSED_WON:
      return 'indexPage.board.deals.projectedTotal.closedWon';
    default:
      return 'indexPage.board.deals.projectedTotal.default';
  }
};
export const getProjectedTotalTooltipMessage = probability => {
  switch (probability) {
    case CLOSED_LOST:
      return 'indexPage.board.deals.projectedTotal.closedLostLabel';
    case CLOSED_WON:
      return 'indexPage.board.deals.projectedTotal.closedWonLabel';
    default:
      return 'indexPage.board.deals.projectedTotal.defaultLabel';
  }
};