import { useCallback } from 'react';
import { useHandler } from '../../handlers/useHandler';
import { useUpdateStageSettings } from './useUpdateStageSetting';
import { useLogger } from '../../props/useLogger';
import { useLoadStageSettings } from './useLoadStageSettings';
export const useHandleColumnCollapseChange = () => {
  //load stage settings to get initial values for collapse
  useLoadStageSettings();
  const {
    updateStageSettings
  } = useUpdateStageSettings();
  const logger = useLogger();
  const onColCollapse = useCallback(options => {
    updateStageSettings(options.stageId, {
      collapse: options.collapsed
    });
    logger.log({
      key: 'columnCollapseChange',
      metadata: {
        totalCards: options.columnData.totalCards || 0,
        collapsed: options.collapsed
      }
    });
  }, [logger, updateStageSettings]);
  useHandler('onColumnCollapseChange', onColCollapse);
};