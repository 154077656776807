import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useIndexPageAppSettingFieldValue } from '../../frameworkAppSettings/hooks/useIndexPageAppSettingFieldValue';
import { getIsPortalSpecific } from '../methods/getIsPortalSpecific';
export const useHasCreateReportButton = () => {
  const typeDef = useSelectedObjectTypeDef();
  const hasCreateReportButton = Boolean(useIndexPageAppSettingFieldValue('hasReportCreation'));

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  if (getIsPortalSpecific(typeDef)) {
    return false;
  }
  return hasCreateReportButton;
};