import { usePipeline } from 'crm-object-board-core/pipeline';
import { withCrmObjectBoardNamespace } from 'crm-object-board-core/utils';
import { useQuery, registerQuery } from 'data-fetching-client';
import { getStageSettings } from '../api/stageSettingsApi';
import { useSetCollapse } from '../../render/columns/useSetCollapse';
import { useEffect } from 'react';
export const STAGE_SETTINGS_FIELD_NAME = withCrmObjectBoardNamespace('stageSettings');
export const STAGE_SETTINGS_QUERY = registerQuery({
  fieldName: STAGE_SETTINGS_FIELD_NAME,
  args: ['pipelineId', 'stageIds'],
  fetcher: getStageSettings
});
const EMPTY_SETTINGS = {};
export const useLoadStageSettings = () => {
  const pipeline = usePipeline();
  const setCollapse = useSetCollapse();
  const {
    data,
    loading,
    error
  } = useQuery(STAGE_SETTINGS_QUERY, {
    skip: !pipeline,
    variables: {
      pipelineId: pipeline ? pipeline.pipelineId : '',
      stageIds: pipeline ? pipeline.stages.map(({
        stageId
      }) => stageId) : []
    }
  });
  useEffect(() => {
    if (data && !loading && !error) {
      const settings = data[STAGE_SETTINGS_FIELD_NAME];
      const settingsToSave = {};
      Object.entries(settings).forEach(([stageId, stageSettings]) => {
        settingsToSave[stageId] = stageSettings.collapse;
      });
      setCollapse(settingsToSave);
    }
    //handle error here
    //we shouldnt break if we don't get settings
    if (loading || error) {
      setCollapse(EMPTY_SETTINGS);
    }
  }, [data, loading, setCollapse, error]);
};