import { COLUMN_REORDERING_CONFIG_TYPES } from './columnReordering/config';
import { COLUMN_RESIZING_CONFIG_TYPES } from './columnResizing/config';
import { EDIT_COLUMNS_CONFIG_TYPES } from './editColumns/config';
import { EMPTY_STATE_CONFIG_TYPE } from './emptyState/config';
import { EXPORT_CONFIG_TYPES } from './export/config';
import { PAGINATION_CONFIG_TYPES } from './pagination/config';
import { SEARCH_CONFIG_TYPES } from './search/config';
import { SORTING_CONFIG_TYPES } from './sorting/config';
import { CREATE_REPORT_CONFIG_TYPES } from './createReport/config';
import { SEARCH_API_CONFIG_TYPES } from './searchApi/config';
import { SUCCESS_MARKER_CONFIG_TYPES } from './successMarker/config';
import { QUICK_FETCH_CONFIG_TYPES } from './quickFetch/config';
import { NESTED_ASSOCIATION_TABLE_CONFIG_TYPES } from './nestedAssociationTable/config';
import { CONTEXT_MENU_CONFIG_TYPES } from './contextMenu/config';
export const FEATURE_CONFIG = {
  COLUMN_REORDERING: COLUMN_REORDERING_CONFIG_TYPES,
  COLUMN_RESIZING: COLUMN_RESIZING_CONFIG_TYPES,
  CONTEXT_MENU: CONTEXT_MENU_CONFIG_TYPES,
  EDIT_COLUMNS: EDIT_COLUMNS_CONFIG_TYPES,
  EMPTY_STATE: EMPTY_STATE_CONFIG_TYPE,
  EXPORT: EXPORT_CONFIG_TYPES,
  PAGINATION: PAGINATION_CONFIG_TYPES,
  SEARCH: SEARCH_CONFIG_TYPES,
  SORTING: SORTING_CONFIG_TYPES,
  CREATE_REPORT: CREATE_REPORT_CONFIG_TYPES,
  SEARCH_API: SEARCH_API_CONFIG_TYPES,
  SUCCESS_MARKER: SUCCESS_MARKER_CONFIG_TYPES,
  QUICK_FETCH: QUICK_FETCH_CONFIG_TYPES,
  NESTED_ASSOCIATION_TABLE: NESTED_ASSOCIATION_TABLE_CONFIG_TYPES
};