import { useMemo } from 'react';
import { useFastStore } from '../context/FastContext';
export const useHandlers = () => {
  const handlers = useFastStore(state => state.handlers);
  return useMemo(() => {
    const result = {
      onColumnCollapseChange: (stageId, collapsed, columnData) => {
        handlers.onColumnCollapseChange.forEach(handler => {
          handler({
            stageId,
            collapsed,
            columnData
          });
        });
      },
      onLoadMore() {
        handlers.onLoadMore.forEach(handler => {
          handler();
        });
      },
      onCardClick(cardData) {
        handlers.onCardClick.forEach(handler => handler({
          cardData
        }));
      },
      onColumnChange(source, destination, onFinishUpdateCallback, itemId) {
        handlers.onColumnChange.forEach(handler => handler({
          source,
          destination,
          onFinishUpdateCallback,
          itemId
        }));
      }
    };
    return result;
  }, [handlers]);
};