import I18n from 'I18n';
import { CALL_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, INVOICE_TYPE_ID, MARKETING_EVENT_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, ORDER_TYPE_ID, CART_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const getSearchBarPlaceholder = objectTypeId => {
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.contact');
    case COMPANY_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.company');
    case DEAL_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.deal');
    case TICKET_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.tickets');
    case CALL_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.calls');
    case INVOICE_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.invoices');
    case MARKETING_EVENT_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.marketingEvents');
    case COMMERCE_PAYMENT_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.payments');
    case ORDER_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.orders');
    case CART_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.carts');
    case PRODUCT_TYPE_ID:
      return I18n.text('crmObjectTable.searchBar.placeholders.products');
    default:
      return I18n.text('crmObjectTable.searchBar.placeholders.default');
  }
};