import { useMemo } from 'react';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { denormalizeTypeId } from '../../utils/denormalizeTypeId';
import { useCurrentPipeline } from '../../pipelines/hooks/useCurrentPipeline';
import { trackChanges } from '../../../board/editCardsPanel/utils/trackChanges';
import { trackBoardCardActions, trackBoardMoveBypassedModal, trackBoardMovePreventedModal, trackBoardPrefsClick, trackColumnCollapsed, trackIndexInteractionsEvent, trackMovedBoardCardEvent, trackOpenPreviewSidebar, trackBoardRejectedReviveModal } from '../../../tracking/indexPageTracking';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useCrmObjectsActions } from '../../crmObjects/hooks/useCrmObjectsActions';
import CrmLogger from '../../../loggers/crmLogger';
import { DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { getStageType } from '../utils/getStageType';
import { CLOSED } from 'customer-data-objects/ticket/TicketStageStatusOptions';
import { DEALBOARD_SURVEY_ID, triggerWootricSurvey } from '../../../utils/triggerWootricSurvey';
export const useCreateCrmObjectBoardLogger = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const objectType = denormalizeTypeId(objectTypeId);
  const pipeline = useCurrentPipeline();
  const {
    getCrmObjects
  } = useCrmObjectsActions();
  return useMemo(() => ({
    log(e) {
      if (e.key === 'saveBoardPreferences') {
        trackChanges(e.metadata.settings);
      } else if (e.key === 'openBoardPreferences') {
        //opened from the footer
        trackBoardPrefsClick({
          source: 'footer tooltip'
        });
      } else if (e.key === 'columnCollapseChange') {
        trackColumnCollapsed({
          collapse: e.metadata.collapsed,
          emptyColumn: e.metadata.totalCards === 0,
          objectTypeId,
          objectTypeName: objectType
        });
      } else if (e.key === 'columnChanged' && pipeline) {
        const nextStage = pipeline.stages.find(stage => stage.stageId === e.metadata.nextStageId);
        //taken from ConditionPropertiesPanelWrapper
        if (e.metadata.conditionalProperties && nextStage) {
          if (objectTypeId === DEAL_TYPE_ID) {
            getCrmObjects({
              objectIds: [e.metadata.objectId]
            }).then(objects => {
              if (!objects[e.metadata.objectId]) {
                return;
              }
              const object = objects[e.metadata.objectId];
              const stageProbability = Number(nextStage.metadata && nextStage.metadata.probability);
              const amount = object.properties['amount'].value;
              if (![0, 1].includes(stageProbability) || !amount) {
                return;
              }

              //# ********** PUBLIC EVENT **********
              //# Public Events help teams across HubSpot automate work and customize experiences based on user actions.
              //# Speak with #product-insight and your PM before shipping any changes to this event
              CrmLogger.log('recordUsage', {
                action: `close deal ${stageProbability === 1 ? 'won' : 'lost'}`,
                what_value: parseInt(amount, 10),
                type: DEAL
              });
            })
            //swallowing this error for linting.
            //it should really be handled at the api layer
            .catch(() => {});
          } else {
            if (nextStage.metadata.ticketState === CLOSED) {
              CrmLogger.log('ticketsActivation');
            }
          }
        }

        //normal logging
        trackMovedBoardCardEvent({
          objectType,
          objectTypeId,
          stageType: getStageType(objectType, nextStage),
          stageName: nextStage ? nextStage.label : ''
        });
      } else if (e.key === 'boardMoveByPass') {
        trackBoardMoveBypassedModal({
          errorCode: e.metadata.statusCode
        });
      } else if (e.key === 'boardMoveRejectedRevive') {
        trackBoardRejectedReviveModal({
          errorCode: e.metadata.statusCode
        });
      } else if (e.key === 'boardMovePrevented') {
        trackBoardMovePreventedModal({
          errorCode: e.metadata.statusCode
        });
      } else if (e.key === 'navigationToRecordsPage') {
        const trackingProperties = {
          action: 'navigate to record from card',
          objectId: e.metadata.objectId,
          objectTypeId,
          isInactive: e.metadata.isInactive.toString()
        };
        if (e.metadata.dealPriority) {
          trackingProperties['dealPriority'] = e.metadata.dealPriority;
        }
        trackIndexInteractionsEvent(trackingProperties);
      } else if (e.key === 'openedApprovalCardPopover') {
        trackBoardCardActions(e.metadata.action, e.metadata.subAction);
      } else if (e.key === 'previewSideBarTriggered') {
        if (e.metadata.objectId) {
          trackOpenPreviewSidebar({
            objectId: e.metadata.objectId,
            objectTypeId: e.metadata.objectTypeId
          });
        }
        if (e.metadata.objectTypeId === DEAL_TYPE_ID) {
          triggerWootricSurvey(DEALBOARD_SURVEY_ID);
        }
      }
    }
  }), [objectType, objectTypeId, getCrmObjects, pipeline]);
};